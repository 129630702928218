import { useState, useEffect, Suspense } from "react";
import { CSerothekPatients, emptySerothekPatients, CUserRightsAppl, serothekPatientsDialog } from "../../../types";
import ProgressBar from "../../Misc/ProgressBar";
import { Grid, GridCellProps, GridColumn, GridSortChangeEvent } from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { mui, DialogTitleBar } from "../../../Helper";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import UserSession from "../../UserSession";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import React from "react";
import { exportPDF } from "@progress/kendo-drawing";
import { Loader } from "@progress/kendo-react-indicators";
import SeroRackList from "./SeroRackList";

const initialSort: Array<SortDescriptor> = [

];

const SerothekPatientsDialog = (props: serothekPatientsDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'SerothekPatients' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [selPID, setSelPID] = useState<string>(props.pid);
    const [selPIDDesc, setSelPIDDesc] = useState<string>(props.pidDesc);
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false);
    const [sort, setSort] = useState(initialSort);
        
    const [serothekPatientsData, setSerothekPatientsData] = useState<CSerothekPatients[]>([emptySerothekPatients]);

    interface seroRackListData {   
        reqid: string;
        rackid: string;
        showDialog: boolean;
        callbackDialogClosed(): any;        
    };

    const dlgEmptySeroRackListData: seroRackListData = {    
        reqid: '',
        rackid:'',
        showDialog: false,
        callbackDialogClosed: () => { }
    };
    const [seroRackListData, setSeroRackListData] = useState<seroRackListData>(dlgEmptySeroRackListData);

    const GetData = async (pid: string) => {
        setIsLoading(true);
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'SerothekPatients/getSerothekPatientsData',
                JSON.stringify({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, pid: pid
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );            
            if (response?.data !== null) {
                setSerothekPatientsData(response?.data);               
            };
            setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    };

    async function validatePID() {
        if (selPID === '') return true;
        var bok: boolean = false;
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'General/validatePID',
                JSON.stringify({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, pid: selPID
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            if (response?.data !== null) {
                if (response?.data !== '') {
                    setSelPIDDesc(response?.data);
                    bok = true
                };
            };            
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        };
        return bok;
    };

    const handlePIDChange = (e: any) => {
        setSelPID(e.target.value);
    };

    const PIDLostFocus = async () => {
        if (selPID === '') {
            setSelPIDDesc("");
        } else {
            if (await validatePID() === false) {
                alert("Invalid PID");
                setSelPID("");
                setSelPIDDesc("");
                setSerothekPatientsData([]);
            } else {
                GetData(selPID);
            };           
        };
         
    };
      
    const CancelClick = async () => {
        props.callbackDialogClosed();
        setVisible(false);
    };

    const handleEditClick = (objSP: CSerothekPatients) => {
        setSeroRackListData({ reqid: objSP.sReqID, rackid: objSP.sRackID, showDialog: true, callbackDialogClosed: () => { } })
    };

    const CustomCellEdit = (props: GridCellProps) => {
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="pencil"
                    onClick={() => {
                        handleEditClick(props.dataItem);
                    }}
                >
                </Button>
            </td>
        );
    };

    const callbackSeroRackListDialogClosed = () => {
        setSeroRackListData(dlgEmptySeroRackListData);
    };

    useEffect(() => {
        setSeroRackListData(dlgEmptySeroRackListData);
        setSelPID(props.pid);
        setSelPIDDesc(props.pidDesc);
        GetData(props.pid);
        setVisible(props.showDialog);
    }, [props.showDialog]); 
        
    let excelExport = React.useRef<ExcelExport | null>(null);
    const exportExcel = () => {         
        if (excelExport.current !== null) {
            excelExport.current.save();
        };        
    };

    let gridPDFExport: GridPDFExport | null;
    const exportPDF = () => {        
        if (gridPDFExport !== null) {
            gridPDFExport.save();
        }
    };

    var rootHeight = window.innerHeight - 100;
    var rootWidth = window.innerWidth - 100;
    var gridHeight: number = rootHeight-190;

    return (
        <>
            <div>
                {visible && (
                    <Dialog style={{ overflowX: 'hidden' }} title={<DialogTitleBar title={mui(userRights, "SerothekPatients", "textTitle", "Serothek Patients")} />} onClose={CancelClick} height={rootHeight} width={rootWidth} >
                        <div className="container-fluid" style={{ overflowX: "hidden", width: '98%' }}>
                            <div className="row" style={{ width: rootWidth - 80, border: '1px solid lightblue', margin: 5, marginBottom:5, backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                                <div className="row" >
                                    <div className="row" style={{ marginTop: 5, marginBottom: 5 }}>
                                        <div className="col-1">
                                            <Label editorId={'lblmandantid'}>{mui(userRights, "common", "lblMandantID", "Mandant")}</Label>
                                        </div>
                                        <div className="col-2">
                                            <Label>{UserSession.getLoggedInUser().CurrentMandant + ' : ' + UserSession.getLoggedInUser().CurrentMandantDesc}</Label>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="row" style={{ marginTop: 5, marginBottom: 5 }}>
                                        <div className="col-1">
                                            <Label style={{ verticalAlign:'middle'}} editorId={'lblpid'}>{mui(userRights, "common", "lblPID", "PID")}</Label>
                                        </div>
                                        <div className="col">
                                            <Input autoComplete="off" id="pid" name="pid" type='text' style={{ width: '110px' }} value={selPID} onChange={handlePIDChange} onBlur={PIDLostFocus} />
                                            <Label style={{ marginLeft: 10, verticalAlign: 'middle' }} editorId={'lblpiddesc'}>{selPIDDesc}</Label>
                                        </div>                                            
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom:5}}>
                                    <div className="col-12">
                                        <div className="button-right">
                                            <Button disabled={serothekPatientsData.length>0?false:true} type={"button"} style={{ width: '150px', marginRight: 5 }} themeColor={'primary'} onClick={() => {
                                                exportPDF()
                                            }}>{mui(userRights, "common", "cmdExportPDF", "Export-PDF")}</Button>
                                            <Button disabled={serothekPatientsData.length > 0 ? false : true} type={"button"} style={{ width: '150px', marginRight:5 }} themeColor={'primary'} onClick={() => {
                                                exportExcel()
                                            }}>{mui(userRights, "common", "cmdExportExcel", "Export-Excel")}</Button>
                                            <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                                CancelClick()
                                            }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {isLoading ? <ProgressBar message=""></ProgressBar> :
                                        <div>
                                            <ExcelExport
                                                data={serothekPatientsData}
                                                ref={excelExport}
                                            >
                                                <Grid
                                                    style={{
                                                        height: gridHeight, width: '99%'
                                                    }}
                                                    data={orderBy(serothekPatientsData, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e: GridSortChangeEvent) => {
                                                        setSort(e.sort);
                                                    }}
                                                    selectable={{
                                                        enabled: true,
                                                        drag: false,
                                                        cell: false,
                                                        mode: "single",
                                                    }}
                                                    selectedField={'selected'}
                                                    dataItemKey={'sKey'}
                                                >
                                                    <GridColumn field="" title={''} width="50px" cell={CustomCellEdit} />
                                                    <GridColumn field="sReqID" title={mui(userRights, "SerothekPatients", "col_ReqID", "ReqID")} width="110px" filter={"numeric"} sortable={true} />
                                                    <GridColumn field="sPID" title={mui(userRights, "SerothekPatients", "col_PID", "PID")} width="110px" filter={"numeric"} />
                                                    <GridColumn field="sPatName" title={mui(userRights, "SerothekPatients", "col_PatName", "PatName")} width="400px" />
                                                    <GridColumn field="sBirthDate" title={mui(userRights, "SerothekPatients", "col_BirthDate", "BirthDate")} width="100px" />
                                                    <GridColumn field="sGender" title={mui(userRights, "SerothekPatients", "col_Gender", "Gender")} width="90px" />
                                                    <GridColumn field="sRackName" title={mui(userRights, "SerothekPatients", "col_RackName", "RackName")} width="300px" />
                                                    <GridColumn field="sMaterialID" title={mui(userRights, "SerothekPatients", "col_Material", "Material")} width="100px" />
                                                    <GridColumn field="sPosXY" title={mui(userRights, "SerothekPatients", "col_Position", "Position")} width="100px" />
                                                    <GridColumn field="sExtDateTime" title={mui(userRights, "SerothekPatients", "col_ExtDateTime", "ExtDateTime")} width="150px" />
                                                    );
                                                </Grid>
                                            </ExcelExport>
                                            <GridPDFExport
                                                ref={(pdfExport) => (gridPDFExport = pdfExport)}
                                                margin="1cm"
                                            >
                                                <Grid
                                                    style={{
                                                        height: gridHeight, width: '99%'
                                                    }}
                                                    data={orderBy(serothekPatientsData, sort)}
                                                    sortable={true}
                                                    sort={sort}
                                                    onSortChange={(e: GridSortChangeEvent) => {
                                                        setSort(e.sort);
                                                    }}
                                                    selectable={{
                                                        enabled: true,
                                                        drag: false,
                                                        cell: false,
                                                        mode: "single",
                                                    }}
                                                    selectedField={'selected'}
                                                    dataItemKey={'sKey'}
                                                >
                                                    <GridColumn field="sReqID" title={mui(userRights, "SerothekPatients", "col_ReqID", "ReqID")} width="110px" filter={"numeric"} sortable={true} />
                                                    <GridColumn field="sPID" title={mui(userRights, "SerothekPatients", "col_PID", "PID")} width="110px" filter={"numeric"} />
                                                    <GridColumn field="sPatName" title={mui(userRights, "SerothekPatients", "col_PatName", "PatName")} width="400px" />
                                                    <GridColumn field="sBirthDate" title={mui(userRights, "SerothekPatients", "col_BirthDate", "BirthDate")} width="100px" />
                                                    <GridColumn field="sGender" title={mui(userRights, "SerothekPatients", "col_Gender", "Gender")} width="90px" />
                                                    <GridColumn field="sRackName" title={mui(userRights, "SerothekPatients", "col_RackName", "RackName")} width="300px" />
                                                    <GridColumn field="sMaterialID" title={mui(userRights, "SerothekPatients", "col_Material", "Material")} width="100px" />
                                                    <GridColumn field="sPosXY" title={mui(userRights, "SerothekPatients", "col_Position", "Position")} width="100px" />
                                                    <GridColumn field="sExtDateTime" title={mui(userRights, "SerothekPatients", "col_ExtDateTime", "ExtDateTime")} width="150px" />
                                                    );
                                                </Grid>
                                            </GridPDFExport>
                                        </div>                                      
                                    }
                                </div>
                            </div>
                        </div>                        
                    </Dialog>
                )} 
            </div>   
            <Suspense fallback={<Loader />}>
                <SeroRackList reqid={seroRackListData.reqid} rackid={seroRackListData.rackid} showDialog={seroRackListData.showDialog} callbackDialogClosed={callbackSeroRackListDialogClosed} ></SeroRackList>
            </Suspense>
        </>
    );
}
export default SerothekPatientsDialog;