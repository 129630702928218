/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-no-undef */
import { ChangeEvent, Suspense, useEffect, useState } from "react";
import { formatDate, formatTime, mui } from "../../Helper";
import { CResultEntry, ctDataType, CUserRightsAppl, selCodeDataType, emptyREData, MULTI_SELECT_TABLES, CResultEntryOutput, emptyREO, resultSelDialog, MULTI_SELECT_SEP } from "../../types";
import axios from 'axios'
import { SelectCode } from "../SelectCode/SelectCode";
import { v4 as uuidv4 } from 'uuid';
import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

import MultiSelectList, { multiSelectProps } from "../ModalForms/MultiSelect/MultiSelectList";
import UserSession from "../UserSession";
import ResultSelDialog from "../ResultAndValidation/ResultSelDialog";
import React from "react";
interface resSelDialog {
    obj: CResultEntryOutput;
    objRE?: CResultEntry;
    selType: string;
}
const CustomResultEntryIH = (props: resSelDialog) => {

    const dlgEmptyState: resultSelDialog = {
        selType: "", objRE: emptyREData, selqualresult: false, showDialog: false, CodeSelected: () => { }
    }
    const [resultSelDialogData, setResultSelDialogData] = useState<resultSelDialog>(dlgEmptyState);

    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'resseldialog' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [resultData, setResultData] = useState<CResultEntry>(emptyREData);

    const [resDate, setResDate] = useState(formatDate(new Date().toDateString()));
    const [resTime, setResTime] = useState(formatTime(new Date().toLocaleString(), true));
    const [selCode, setSelCode] = useState<string | "">("");
    const [selDesc, setSelDesc] = useState<string | "">("");

    const [selDefCode, setSelDefCode] = useState<string | "">("");

    // new ms
    const autoTab = (e: any) => {
        console.log("autoTab 1", `${e.target.value.length}`, e.target.getAttribute("maxlength"))
        if (`${e.target.value.length}` === e.target.getAttribute("maxlength")) {
            console.log("autoTab 2")
            const inputs = document.getElementsByClassName("autotab") as HTMLCollectionOf<HTMLInputElement>;
            console.log("autoTab 3", inputs)
            for (let i = 0; i < inputs.length; i++) {
                if (e.target === inputs[i]) {
                    i++;
                    if (i < inputs.length) {
                        const next: HTMLInputElement = inputs[i];
                        next.focus();
                    }
                }
            }
        }
    };

    const dlgMSEmptyState: multiSelectProps = {
        multiSelectTable: "",
        selectedValues: "",
        showDialog: false,
        MultiSelectSaved: (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => { }
    }

    const MultiSelectSaved = (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => {

        ////////if (cancel !== true) {
        ////////    //console.log("callbackMultiSel", savedCodes)

        ////////    if (objRE.ExistingResult.length > 0) {
        ////////        objRE.sRecStatus = "CHG"
        ////////    }
        ////////    else {
        ////////        objRE.sRecStatus = "UNS"
        ////////    }
        ////////    objRE.sRawResult = "AI"
        ////////    objRE.sResultText = savedCodes
        ////////    setSelCode(savedCodes);
        ////////    setSelDesc(savedCodes);
        ////////}

        setMultiSelectData(dlgMSEmptyState);
    }

    const openMS = (objRE: CResultEntry, multiSelectTable: MULTI_SELECT_TABLES) => {
        var selectedValues: string = "";
        selectedValues = objRE.sResultText;
        //console.log("openMS", objRE.sResultText)
        setMultiSelectData({
            multiSelectTable: multiSelectTable, showDialog: true,
            selectedValues: selectedValues, MultiSelectSaved: MultiSelectSaved
        });
    };

    const [multiSelectData, setMultiSelectData] = useState<multiSelectProps>(dlgMSEmptyState);

    //new ms

    const handleResChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        //////////console.log('event.value', value);
        ////////objRE.sRawResult = value
        ////////if (objRE.ExistingResult.length > 0) {
        ////////    objRE.sRecStatus = "CHG"
        ////////}
        ////////else {
        ////////    objRE.sRecStatus = "UNS"
        ////////}

        ////////setResDate(value)
        ////////setSelCode(value);
        ////////setSelDesc("");
    };

    const handleResChangeTime = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        //////////console.log('event.value', value);
        ////////if (objRE.ExistingResult.length > 0) {
        ////////    objRE.sRecStatus = "CHG"
        ////////}
        ////////else {
        ////////    objRE.sRecStatus = "UNS"
        ////////}
        ////////objRE.sRawResult = value
        ////////setResTime(value)
        ////////setSelCode(value);
        ////////setSelDesc("");

    };

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string) => {
        ////console.log("callbackCTSelected", objCT)
        //if (objRE.ExistingResult.length > 0) {
        //    objRE.sRecStatus = "CHG"
        //}
        //else {
        //    objRE.sRecStatus = "UNS"
        //}
        //if (objCT.sCode !== undefined && objCT.sCode !== null) {
        //    setSelCode(objCT.sCode);
        //    setSelDesc(objCT.sDescDevelopment);
        //} else {
        //    setSelCode("");
        //    setSelDesc("");
        //}
    };

    const callbackResultSel = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {
        //console.log("callbackResultSel", obj)
        //if (objRE.ExistingResult.length > 0) {
        //    objRE.sRecStatus = "CHG"
        //}
        //else {
        //    objRE.sRecStatus = "UNS"
        //}
        //if (obj.code !== undefined && obj.code !== null) {
        //    setSelCode(obj.code);
        //    setSelDesc(obj.desc);

        //} else {
        //    setSelCode("");
        //    setSelDesc("");
        //}
    };

    useEffect(() => {
        //setResultData(objRE);
        setSelCode("");
        setSelDesc("");
        //setSelDefCode(objRE.sRawResult);

    }, [])

    const InputKeyUp = (e: any) => {
        console.log("InputKeyUp", e)
        //autoTab(e);
        //console.log("autoTab 1", `${e.target.value.length}`, e.target.getAttribute("maxlength"))
        //if (`${e.target.value.length}` === e.target.getAttribute("maxlength")) {
        //    console.log("autoTab 2")
        //    const inputs = document.getElementsByClassName("autotab") as HTMLCollectionOf<HTMLInputElement>;
        //    console.log("autoTab 3", inputs)
        //    for (let i = 0; i < inputs.length; i++) {
        //        if (e.target === inputs[i]) {
        //            i++;
        //            if (i < inputs.length) {
        //                const next: HTMLInputElement = inputs[i];
        //                next.focus();
        //            }
        //        }
        //    }
        //}
    }

    const InputKeyDown = (e: any, objRE: CResultEntry) => {
        console.log("InputKeyDown", e, objRE)
        if (e.nativeEvent.code === "F2") {

            if (props.selType === "RESULT") {
                if (objRE.sResSelTableName === "ResultCode" || objRE.sResSelTableName === "PhenoType" || objRE.sCodeTableID !== "") {
                    onResSelClick(objRE, "open")
                }
                else {
                    openMS(objRE, "Antibody")
                }
            }

            else {
                onResCommSelClick(objRE, "open")
            }

        }
        else {
            //console.log("InputKeyDown", e)
            var splitted: string[] = objRE.sAllowResults.split(MULTI_SELECT_SEP); 
            var i: number = -1
            
            switch (e.nativeEvent.keyCode) {
                case 48: case 96: {
                    i = 0;
                    //console.log("InputKeyDown 0" )
                    break;
                }
                case 49: case 97: {
                    //console.log("InputKeyDown 1")
                    i = 1; 
                    break;
                }
                case 50: case 98: {
                    //console.log("InputKeyDown 2")
                    i = 2;
                    break;
                }
                case 51: case 99: {
                    //console.log("InputKeyDown 3")
                    i = 3;
                    break;
                }
                case 52: case 100: {
                    //console.log("InputKeyDown 4")
                    i = 4; 
                    break;
                }
                case 53: case 101: {
                    //console.log("InputKeyDown 5")
                    i = 5; 
                    break;
                }
                case 54: case 102: {
                    //console.log("InputKeyDown 6")
                    i = 6; 
                    break;
                }
                case 55: case 103: {
                    //console.log("InputKeyDown 7")
                    i = 7; 
                    break;
                }
                case 56: case 104: {
                    //console.log("InputKeyDown 8")
                    i = 8; 
                    break;
                }
                case 57: case 105: {
                    //console.log("InputKeyDown 9")
                    i = 9; 
                    break;
                }
                default: {
                    //statements; 
                    break;
                }
            } 
            //console.log("InputKeyDown", i, objRE.sAllowResults)
            if ((i !== -1) && objRE.sAllowResults !== '') {
                //console.log("ikd value", splitted[i])

                objRE.sRawResult = splitted[i];
                objRE.sRecStatus = "CHG"            
                setSelDefCode(objRE.sRawResult);
                setSelCode(objRE.sRawResult);

                //e.nativeEvent.keyCode = 9
                    e.preventDefault();
                return false;
               
            }

        }
    }
    const onResCommSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                //console.log('objRE', objRE);
                objRE.sResultComm = Code;
                if (Code.toUpperCase() === "FT") {
                    objRE.sCommText = Desc;
                } else {
                    objRE.sCommText = "";
                };
                /*//ValidateResult(objRE, false);*/
            }
            setResultSelDialogData(dlgEmptyState);

            //document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Tab' })); 
        };
        setResultSelDialogData({ selType: "ResultComm", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const onResSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            console.log('CodeSel', Code, Desc)
            if (Code !== "") {
                //console.log('objRE', objRE);
                objRE.sRawResult = Code;
                if (Code.toUpperCase() === "FT") {
                    objRE.sResultText = Desc;
                } else {
                    objRE.sResultText = "";
                };
                /*//ValidateResult(objRE, false);*/
            }
            setResultSelDialogData(dlgEmptyState);

            //document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Tab' })); 
        }
        
        setResultSelDialogData({ selType: "Result", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const onBlur = async (e: any, objRE: CResultEntry) => {

        var resultMainData: CResultEntryOutput = emptyREO;
        var obj: CResultEntry = emptyREData;
        var Msg: string = "";

        if (props.selType === "RESULT") {

            //console.log("onBlur CRE  - ", e.target.value)
            setSelDefCode(e.target.value);

            if (objRE !== undefined) {
                obj = objRE
                obj.sRawResult = e.target.value

                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
            }
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
                JSON.stringify({
                    objREO: resultMainData, objResultEntry: obj, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: false
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            if (response?.data !== null) {
                console.log("onBlur CRE response - ", response?.data)
                var objRO = response?.data

                Msg = objRO.objREO.sErrorMsg;
                if (Msg === undefined) Msg = '';
                if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                if (objRO.objResultEntry.bInvalidCode === true) {
                    console.log("onBlur CRE InvalidCode - ")
                    alert(Msg);
                    setSelCode("");
                    setSelDesc("");
                    objRE.sRawResult = ""


                }
                else {
                    console.log("onBlur CRE validCode 1 - ")
                    if (objRE.sParamID === objRO.objResultEntry.sParamID && objRE.bDisableResultEntry === false) {
                        console.log("onBlur CRE validCode 2 - ", objRO.objResultEntry.sInterpretationCode)
                        setResultData(JSON.parse(JSON.stringify(objRO.objResultEntry)));
                        //setSelDefCode(objRO.objResultEntry.sRawResult);
                        setSelCode(objRO.objResultEntry.sInterpretationCode);
                        setSelDesc(objRO.objResultEntry.sInterpretationDesc);
                        objRE.sRawResult = objRO.objResultEntry.sInterpretationCode

                    };
                }


            };


        }
        else {

            //console.log("onBlur CRE  - ", e.target.value)
            setSelDefCode(e.target.value);

            if (objRE !== undefined) {
                obj = objRE
                obj.sResultComm = e.target.value;

                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
            }
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/validateresultcomm',
                JSON.stringify({
                    mandantid: obj.sMandantID, resultcomm: obj.sResultComm, desc: "", binvalidcode: false
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            if (response?.data !== null) {
                console.log("onBlur ResultComm CRE response - ", response?.data)
                var objRO = response?.data

                if (Msg === undefined) Msg = '';
                if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                if (objRO.binvalidcode === true) {
                    console.log("onBlur CRE InvalidCode - ")
                    alert(Msg);
                    setSelCode("");
                    setSelDesc("");
                    objRE.sResultComm = ""


                }
                else {
                    console.log("onBlur CRE validCode 1 - ")
                    console.log("onBlur CRE validCode 2 - ", objRO.resultcomm)
                    //setResultData(JSON.parse(JSON.stringify(objRO.objResultEntry)));
                    //setSelDefCode(objRO.objResultEntry.sRawResult);
                    setSelCode(objRO.resultcomm);
                    setSelDesc(objRO.desc);
                    objRE.sResultComm = objRO.resultcomm
                }


            };

        }


    };

    const handleChange = (e: any, objRE: CResultEntry) => {
        setSelDefCode(e.target.value);
        setSelCode(e.target.value);
        props.selType === "RESULT" ? objRE.sRawResult = e.target.value : objRE.sResultComm = e.target.value
    }

    const handleResChangeTimeSecond = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        //////////console.log('event.value', value);
        ////////if (objRE.ExistingResult.length > 0) {
        ////////    objRE.sRecStatus = "CHG"
        ////////}
        ////////else {
        ////////    objRE.sRecStatus = "UNS"
        ////////}
        ////////objRE.sCompType = value
        ////////setResTime(value)
        ////////setSelCode(value);
        ////////setSelDesc("");

    };

    const onBlurSecond = async (e: any, objRE: CResultEntry) => {

        var resultMainData: CResultEntryOutput = emptyREO;
        var obj: CResultEntry = emptyREData;
        var Msg: string = "";

        if (props.selType === "RESULT") {

            //console.log("onBlur CRE  - ", e.target.value)
            setSelDefCode(e.target.value);

            if (objRE !== undefined) {
                obj = objRE
                obj.sCompType = e.target.value

                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
            }
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
                JSON.stringify({
                    objREO: resultMainData, objResultEntry: obj, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: false
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            if (response?.data !== null) {
                console.log("onBlur CRE response - ", response?.data)
                var objRO = response?.data

                Msg = objRO.objREO.sErrorMsg;
                if (Msg === undefined) Msg = '';
                if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                if (objRO.objResultEntry.bInvalidCode === true) {
                    console.log("onBlur CRE InvalidCode - ")
                    alert(Msg);
                    setSelCode("");
                    setSelDesc("");
                    objRE.sCompType = ""


                }
                else {
                    console.log("onBlur CRE validCode 1 - ")
                    if (objRE.sParamID === objRO.objResultEntry.sParamID && objRE.bDisableResultEntry === false) {
                        console.log("onBlur CRE validCode 2 - ", objRO.objResultEntry.sInterpretationCode)
                        setResultData(JSON.parse(JSON.stringify(objRO.objResultEntry)));
                        //setSelDefCode(objRO.objResultEntry.sRawResult);
                        setSelCode(objRO.objResultEntry.sInterpretationCode);
                        setSelDesc(objRO.objResultEntry.sInterpretationDesc);
                        objRE.sCompType = objRO.objResultEntry.sInterpretationCode

                    };
                }


            };


        }

    };

    const handleChangeSecond = (e: any, objRE: CResultEntry) => {
        setSelDefCode(e.target.value);
        setSelCode(e.target.value);
        objRE.sCompType = e.target.value
    }
    const elemRefs = [];
    const ref = React.createRef();
    elemRefs.push(ref);
    return (
        <>
            {
                props.obj.listResEntry !== null && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                    props.obj.inEdit === true ?
                        <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: 150 }}>
                            {

                                props.selType === "RESULT" ?

                                    ((objRE.sResSelTableName === "Date") && (objRE.sResultType !== "RD")) ?
                                        (<CustomDate objRE={objRE} dateChanged={handleResChangeDate} onlyTextBox={true} width="100%" defaulValue={objRE.sRawResult} id={'txtReqDate'} source={'selDefCode'} label="" supplParam1={objRE.sParamID} supplParam2=""
                                            required={false} disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}></CustomDate>)
                                        :
                                        objRE.sResSelTableName === "Time" ?
                                            (<CustomTime objRE={objRE} allowSecond={true} supplParam1={objRE.sParamID} supplParam2="" timeChanged={handleResChangeTime} width="100%" defaulValue={objRE.sRawResult} id={'ResTime'} source={'selDefCode'} label="" required={false} disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}></CustomTime>)
                                            :
                                            objRE.sCodeTableID !== "" || objRE.sResSelTableName === "ResultCode" || objRE.sResSelTableName === "PhenoType" ?
                                                <Input
                                                    disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}
                                                    value={objRE.sRawResult}
                                                    onChange={(e) => {
                                                        handleChange(e, objRE);
                                                    }}
                                                    onBlurCapture={(e) => {
                                                        onBlur(e, objRE);
                                                    }}
                                                    style={{ textAlign: 'center' }}
                                                    width="100%"
                                                    onKeyDown={(e) => {
                                                        InputKeyDown(e, objRE);
                                                    }}

                                                    onKeyUp={InputKeyUp}
                                                    className="block"
                                                    ref={ref}
                                                    data-index={index}
                                                />
                                                :
                                                (objRE.sResSelTableName === "Antibodies") ?
                                                    <Input
                                                        disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}
                                                        onKeyDown={(e) => {
                                                            InputKeyDown(e, objRE);
                                                        }}
                                                        onKeyUp={InputKeyUp}
                                                        title={objRE.sResultText}
                                                        readOnly
                                                        defaultValue={objRE.sResultText} value={objRE.sResultText}
                                                        width="100%"
                                                        className="block"
                                                        ref={ref}
                                                        data-index={index}
                                                    />
                                                    :
                                                    (objRE.sResultType === "BC") ?
                                                        <>

                                                            <div className="row">

                                                                <div className="col-8">
                                                                    <Input
                                                                        disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}
                                                                        onBlurCapture={(e) => {
                                                                            onBlur(e, objRE);
                                                                        }}
                                                                        onChange={(e) => {
                                                                            handleChange(e, objRE);
                                                                        }} defaultValue={objRE.sRawResult} value={objRE.sRawResult}
                                                                        width="100%"
                                                                        className="block"
                                                                        ref={ref}
                                                                        data-index={index}
                                                                        onKeyUp={InputKeyUp}
                                                                    />
                                                                </div>
                                                                <div className="col-4">
                                                                    <Input
                                                                        disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}
                                                                        onBlurCapture={(e) => {
                                                                            onBlurSecond(e, objRE);
                                                                        }}
                                                                        onChange={(e) => {
                                                                            handleChangeSecond(e, objRE);
                                                                        }} defaultValue={objRE.sCompType} value={objRE.sCompType}
                                                                        width="100%"
                                                                        className="block"
                                                                        ref={ref}
                                                                        data-index={index}
                                                                        onKeyUp={InputKeyUp}
                                                                    />
                                                                </div>
                                                            </div>


                                                        </>
                                                        : (objRE.sResultType === "RD") ?
                                                            <>
                                                                <div className="row">

                                                                    <div className="col-8">
                                                                        <CustomDate objRE={objRE} dateChanged={handleResChangeDate} onlyTextBox={true} width="100%" defaulValue={objRE.sRawResult} id={'txtReqDate'} source={'selDefCode'} label="" supplParam1={objRE.sParamID} supplParam2=""
                                                                            required={false} disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}></CustomDate>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <CustomTime objRE={objRE} allowSecond={false} supplParam1={objRE.sParamID} supplParam2="2" timeChanged={handleResChangeTimeSecond} width="100%" defaulValue={objRE.sCompType} id={'ResTime'} source={'selDefCode'} label="" required={false} disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}></CustomTime>
                                                                    </div>
                                                                </div>


                                                            </>
                                                            :
                                                            <Input
                                                                disabled={(objRE.sParamStatus === '1' || objRE.sParamStatus === '2') ? false : true}
                                                                onBlurCapture={(e) => {
                                                                    onBlur(e, objRE);
                                                                }}
                                                                onChange={(e) => {
                                                                    handleChange(e, objRE);
                                                                }} defaultValue={objRE.sRawResult} value={objRE.sRawResult}
                                                                style={{ textAlign: 'center' }}
                                                                width="100%"
                                                                onKeyUp={InputKeyUp}
                                                                onKeyDown={(e) => {
                                                                    InputKeyDown(e, objRE);
                                                                }}
                                                                className="block"
                                                                ref={ref}
                                                                data-index={index}
                                                            />
                                    :

                                    <Input
                                        value={objRE.sResultComm}
                                        onChange={(e) => {
                                            handleChange(e, objRE);
                                        }}
                                        onBlurCapture={(e) => {
                                            onBlur(e, objRE);
                                        }}
                                        onKeyDown={(e) => {
                                            InputKeyDown(e, objRE);
                                        }}
                                        onKeyUp={InputKeyUp}
                                        style={{ textAlign: 'center' }}
                                        width="100%"
                                    />


                            }


                        </td>
                        :
                        <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: 150 }}>
                            {objRE.sInterpretationDesc === "" ? <div>&nbsp;</div> : objRE.sInterpretationDesc}
                        </td>

                ))

            }
            <div className="container-fluid" style={{ overflowX: 'hidden', width: '90%' }}>
                <div className="row">

                    <div className="col-12">
                        

                    </div>

                </div>
            </div>
            <Suspense fallback={<Loader />}>
                <ResultSelDialog selType={resultSelDialogData.selType} objRE={resultSelDialogData.objRE} selqualresult={resultSelDialogData.selqualresult} showDialog={resultSelDialogData.showDialog}
                    CodeSelected={resultSelDialogData.CodeSelected}></ResultSelDialog>

                <MultiSelectList multiSelectTable={multiSelectData.multiSelectTable} selectedValues={multiSelectData.selectedValues}
                    showDialog={multiSelectData.showDialog} MultiSelectSaved={multiSelectData.MultiSelectSaved}></MultiSelectList>

            </Suspense>
        </>
    );
}

export default CustomResultEntryIH;