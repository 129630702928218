import { CConfirmationMsg, confirmationDialog, CResultEntryOutput, CUserRightsAppl, CVerifyValidationData, emptyVVD, ProfileDropdownData, resultEntryDialog, TabEntry } from "../../types";
import IHProfileBlock from "./IHProfileBlock";
import axios from 'axios';
import UserSession from "../UserSession";
import { Suspense, useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { extendedLog, mui, TabTitle } from "../../Helper";
import RequestInfoBar from "../ReqInfoBar/RequestInfoBar";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { v4 as uuidv4 } from 'uuid';
import ProgressBar from "../Misc/ProgressBar";

const IHValidationComponent = (props: resultEntryDialog) => {
    //extendedLog('IH ValidationComponent page called');
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'IHValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const ref_divReqInfoBar = useRef<HTMLDivElement>(null);

    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [ihData, setIHData] = useState<CResultEntryOutput[]>([]);
    const [bcData, setBCData] = useState<CResultEntryOutput[]>([]);
    const [tabvalue, setTabValue] = useState(0);
    //const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);
    const [disableValidationButton, setDisableValidationButton] = useState<boolean | false>(true);

    const dlgEmptyConfirmationDialogState: confirmationDialog = {
        obj: emptyVVD, showDialog: false, SelectedOptions: () => { }
    };
    const [confirmationDialogData, setConfirmationDialogData] = useState<confirmationDialog>(dlgEmptyConfirmationDialogState);

    let tabs: TabEntry[] = [];
    const [tablist, setTabList] = useState<TabEntry[]>(tabs);

    const GeIHDataToValidate = async () => {
        var enableValButton: boolean = false;
        var obj: ProfileDropdownData = { code: "", desc: "", paramtype: "", resultype: "", avgresultyn: "", selected: false, color: "" };
        //console.log("GeIHDataToValidate", props)
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/getihdatatovalidate',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime, reqprovider: props.reqprovider,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid, obj: obj
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddataIH: CResultEntryOutput[] = [];
        var binddataBC: CResultEntryOutput[] = [];
        if (response?.data !== null) {
            if (response?.data !== null)
                response?.data.forEach((o: CResultEntryOutput) => {
                    if (enableValButton === false) {
                        if (o.bProfileValidated === false && o.bDisableProfileSel === false) {
                            enableValButton = true;
                            setDisableValidationButton(false);
                        };
                    };
                    binddataIH.push({ ...o });
                    //if (o.sProfileResultType.toUpperCase() === "BC") {
                    //    binddataBC.push({ ...o });
                    //} else {
                    //    binddataIH.push({ ...o });
                    //};
                });
            setIHData(binddataIH);
            setBCData(binddataBC);

            var initialState: TabEntry[] = [];
            if (binddataIH.length > 0) initialState.push({ key: "1", id: 1, header: mui(userRights, "IHValidation", "TabIHProfileTitle", "IH Profiles") });
            if (binddataBC.length > 0) initialState.push({ key: "2", id: 2, header: mui(userRights, "IHValidation", "TabBCProfileTitle", "BC Profiles") });
            setTabList(initialState);
            setTabValue(0);
        };
        setIsLoading(false);
    };

    const ValidateClick = async (objVVD: CVerifyValidationData) => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateihprofiles',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, valtype: props.valtype, ihData: ihData, bcData: bcData,
                objVVD: objVVD, sUserID: UserSession.getLoggedInUser().UserID, sErrorMsg: ""
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var maindata;
        var validatedProfileCount: number = 0;
        var retIHData: CResultEntryOutput[] = [];
        var retBCData: CResultEntryOutput[] = [];

        if (response?.data !== null)
            maindata = response?.data;
        if (maindata !== undefined) {
            //console.log(maindata)
            var Msg: string = '';
            if (Msg === undefined) Msg = '';

            if (maindata.ihData !== undefined) {
                maindata.ihData.forEach((o: CResultEntryOutput) => {
                    if (o.bProfileValidated === true) validatedProfileCount += 1;
                    if (o.sErrorMsg !== '') Msg += ' ' + o.sErrorMsg
                    retIHData.push({ ...o });
                });
            };
            if (maindata.bcData !== undefined) {
                maindata.bcData.forEach((o: CResultEntryOutput) => {
                    if (o.bProfileValidated === true) validatedProfileCount += 1;
                    if (o.sErrorMsg !== '') Msg += ' ' + o.sErrorMsg
                    retBCData.push({ ...o });
                });
            };

            if (Msg !== "") {
                alert(Msg);
                setIsLoading(false);
            }
            else {

                setIHData(retIHData);
                setBCData(retBCData);
                setIsLoading(false);

                if (retIHData.length + retBCData.length === validatedProfileCount) {
                    props.ResultSaved(true, props.reqid, props.valtype);
                    if (props.dayWorkSpace === false ) setVisible(false);
                };
            }
        };

    };

    async function VerifyData() {
        var bok: boolean = false;
        var obj: CResultEntryOutput;
        setIsLoading(true);

        for (obj of ihData) {
            if (obj.bProfileSel === true && obj.bProfileValidated === false) {
                bok = true;
                break;
            }
        };
        if (bok === false) {
            for (obj of bcData) {
                if (obj.bProfileSel === true && obj.bProfileValidated === false) {
                    bok = true;
                    break;
                }
            };
        };
        if (bok === false) {
            setIsLoading(false);
            alert(mui(userRights, "IHValidation", "msgProfileNotSel", "Select at least single profile!"));
        } else {
            var objMainVVD: CVerifyValidationData = emptyVVD;
            objMainVVD.sMode = "V";
            objMainVVD.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
            objMainVVD.ConfirmationMsg = []
            console.log("initialise ", objMainVVD)
            var objVVD: CVerifyValidationData = emptyVVD;
            objVVD.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
            objVVD.ConfirmationMsg = []
            objVVD.sMode = "V";
            for (obj of ihData) {
                if (obj.bProfileSel === true && obj.bProfileValidated === false) {
                    objVVD.objREO = obj;
                    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/verifydata',
                        JSON.stringify({
                            sMandantID: objVVD.sMandantID, objREO: objVVD.objREO, sMode: objVVD.sMode,
                            bValidateIncompatibleComp: objVVD.bValidateIncompatibleComp, bAddBldGrpRhesusToPatient: objVVD.bAddBldGrpRhesusToPatient,
                            bAddPhenoTypeToPatient: objVVD.bAddPhenoTypeToPatient, bSetCompFreeAndProceedWithValidation: objVVD.bSetCompFreeAndProceedWithValidation,
                            bResultEnteredRDParam: objVVD.bResultEnteredRDParam, bResultEnteredBCParam: objVVD.bResultEnteredBCParam,
                            bBloodComponentExists: objVVD.bBloodComponentExists, bDataChanged: objVVD.bDataChanged, nMsgAntigenDoesNotMatch: objVVD.nMsgAntigenDoesNotMatch,
                            nMsgBldGrpRhsOfPatAndCompDoesNotMatch: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch, nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1,
                            nMsgBldGrpOfResultAndComponentDoesNotMatch: objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch, nMsgRhesusOfResultAndPatientDoesNotMatch: objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch,
                            nMsgRhesusOfResultAndComponentDoesNotMatch: objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch, nMsgAcceptBldGrpAndRhesus: objVVD.nMsgAcceptBldGrpAndRhesus,
                            nMsgAcceptPhenoTypeYN: objVVD.nMsgAcceptPhenoTypeYN, nGetReactionReason: objVVD.nGetReactionReason,
                            sReactionReason: objVVD.sReactionReason, sConfirmationMsg: objVVD.sConfirmationMsg
                        }),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                                'Content-Type': 'application/json'
                            },
                            withCredentials: false
                        }
                    );
                    if (response?.data !== null) {
                        objVVD = response?.data;
                        console.log("loop ", objMainVVD, objVVD)
                        if (objVVD.ConfirmationMsg.length > 0) {
                            var objCM: CConfirmationMsg;
                            //for (objCM of objMainVVD.ConfirmationMsg) {
                            //    objMainVVD.ConfirmationMsg.pop();
                            //};
                            for (objCM of objVVD.ConfirmationMsg) {
                                objMainVVD.ConfirmationMsg.push(objCM);
                            };
                        };
                        if (objVVD.bValidateIncompatibleComp === true) objMainVVD.bValidateIncompatibleComp = true;
                        if (objVVD.bAddBldGrpRhesusToPatient === true) objMainVVD.bAddBldGrpRhesusToPatient = true;
                        if (objVVD.bAddPhenoTypeToPatient === true) objMainVVD.bAddPhenoTypeToPatient = true;
                        if (objVVD.bSetCompFreeAndProceedWithValidation === true) objMainVVD.bSetCompFreeAndProceedWithValidation = true;
                        if (objVVD.bResultEnteredRDParam === true) objMainVVD.bResultEnteredRDParam = true;
                        if (objVVD.bResultEnteredBCParam === true) objMainVVD.bResultEnteredBCParam = true;
                        if (objVVD.bBloodComponentExists === true) objMainVVD.bBloodComponentExists = true;
                        if (objVVD.bDataChanged === true) objMainVVD.bDataChanged = true;
                        if (objVVD.nGetReactionReason === 1) objMainVVD.nGetReactionReason = 1;
                    };
                };
            };
            for (obj of bcData) {
                console.log ("bcData")
                if (obj.bProfileSel === true && obj.bProfileValidated === false) {
                    objVVD.objREO = obj;
                    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/verifydata',
                        JSON.stringify({
                            sMandantID: objVVD.sMandantID, objREO: objVVD.objREO, sMode: objVVD.sMode,
                            bValidateIncompatibleComp: objVVD.bValidateIncompatibleComp, bAddBldGrpRhesusToPatient: objVVD.bAddBldGrpRhesusToPatient,
                            bAddPhenoTypeToPatient: objVVD.bAddPhenoTypeToPatient, bSetCompFreeAndProceedWithValidation: objVVD.bSetCompFreeAndProceedWithValidation,
                            bResultEnteredRDParam: objVVD.bResultEnteredRDParam, bResultEnteredBCParam: objVVD.bResultEnteredBCParam,
                            bBloodComponentExists: objVVD.bBloodComponentExists, bDataChanged: objVVD.bDataChanged, nMsgAntigenDoesNotMatch: objVVD.nMsgAntigenDoesNotMatch,
                            nMsgBldGrpRhsOfPatAndCompDoesNotMatch: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch, nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1,
                            nMsgBldGrpOfResultAndComponentDoesNotMatch: objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch, nMsgRhesusOfResultAndPatientDoesNotMatch: objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch,
                            nMsgRhesusOfResultAndComponentDoesNotMatch: objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch, nMsgAcceptBldGrpAndRhesus: objVVD.nMsgAcceptBldGrpAndRhesus,
                            nMsgAcceptPhenoTypeYN: objVVD.nMsgAcceptPhenoTypeYN, nGetReactionReason: objVVD.nGetReactionReason,
                            sReactionReason: objVVD.sReactionReason, sConfirmationMsg: objVVD.sConfirmationMsg
                        }),
                        {
                            headers: {
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                                'Content-Type': 'application/json'
                            },
                            withCredentials: false
                        }
                    );
                    if (response?.data !== null) {
                        objVVD = response?.data;
                        console.log("bcdata ", objMainVVD, objVVD)
                        if (objVVD.ConfirmationMsg.length > 0) {
                            var objCM1: CConfirmationMsg;
                            //for (objCM1 of objMainVVD.ConfirmationMsg) {
                            //    objMainVVD.ConfirmationMsg.pop();
                            //};

                            for (objCM1 of objVVD.ConfirmationMsg) {
                                objMainVVD.ConfirmationMsg.push(objCM1);
                            };
                        };
                        if (objVVD.bValidateIncompatibleComp === true) objMainVVD.bValidateIncompatibleComp = true;
                        if (objVVD.bAddBldGrpRhesusToPatient === true) objMainVVD.bAddBldGrpRhesusToPatient = true;
                        if (objVVD.bAddPhenoTypeToPatient === true) objMainVVD.bAddPhenoTypeToPatient = true;
                        if (objVVD.bSetCompFreeAndProceedWithValidation === true) objMainVVD.bSetCompFreeAndProceedWithValidation = true;
                        if (objVVD.bResultEnteredRDParam === true) objMainVVD.bResultEnteredRDParam = true;
                        if (objVVD.bResultEnteredBCParam === true) objMainVVD.bResultEnteredBCParam = true;
                        if (objVVD.bBloodComponentExists === true) objMainVVD.bBloodComponentExists = true;
                        if (objVVD.bDataChanged === true) objMainVVD.bDataChanged = true;
                        if (objVVD.nGetReactionReason === 1) objMainVVD.nGetReactionReason = 1;
                    };
                };
            };
            bok = true;
            console.log("after verify", objMainVVD, objVVD)
            if (objMainVVD.ConfirmationMsg.length > 0 || objMainVVD.nGetReactionReason === 1) {
                const SelectedOpts = (obj: CVerifyValidationData) => {
                    console.log("SelectedOpts", obj)
                    if (obj !== undefined) {
                        var objCM: CConfirmationMsg;
                        for (objCM of obj.ConfirmationMsg) {
                            if (objCM.msgid === "MsgAntigenDoesNotMatch") {
                                console.log("SelectedOpts ", obj)
                                obj.nMsgAntigenDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch = Number(objCM.action);
                                console.log("SelectedOpts nMsgBldGrpRhsOfPatAndCompDoesNotMatch", obj, objVVD)
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch1") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1 = Number(objCM.action);
                                console.log("SelectedOpts nMsgBldGrpRhsOfPatAndCompDoesNotMatch1", obj, objVVD)
                            } else if (objCM.msgid === "MsgBldGrpOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch = Number(objCM.action);
                                console.log("SelectedOpts nMsgBldGrpOfResultAndComponentDoesNotMatch", obj, objVVD)
                            } else if (objCM.msgid === "MsgRhesusOfResultAndPatientDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch = Number(objCM.action);
                                console.log("SelectedOpts nMsgRhesusOfResultAndPatientDoesNotMatch", obj, objVVD)

                            } else if (objCM.msgid === "MsgRhesusOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch = Number(objCM.action);
                                console.log("SelectedOpts nMsgRhesusOfResultAndComponentDoesNotMatch", obj, objVVD)

                            } else if (objCM.msgid === "MsgAcceptBldGrpAndRhesus") {
                                objVVD.nMsgAcceptBldGrpAndRhesus = Number(objCM.action);
                                console.log("SelectedOpts MsgAcceptBldGrpAndRhesus", obj, objVVD)
                            } else if (objCM.msgid === "MsgAcceptPhenoTypeYN") {
                                objVVD.nMsgAcceptPhenoTypeYN = Number(objCM.action);
                                console.log("SelectedOpts MsgAcceptPhenoTypeYN", obj, objVVD)
                            };
                            if (objCM.action === "7") {
                                if ((objCM.msgid === "MsgAcceptPhenoTypeYN") || (objCM.msgid === "MsgAcceptBldGrpAndRhesus")) {

                                }
                                else {
                                    bok = false;
                                }

                            };
                        };
                        console.log("SelectedOpts ok ", bok)
                    };
                    setIsLoading(true);
                    if (bok === true) {
                        console.log("ok true", objMainVVD);
                        if (objMainVVD.bCancelClicked === true) {
                            console.log("4  cancel clicked");
                            objMainVVD = emptyVVD
                            objVVD = emptyVVD
                        }
                        else {
                            console.log("5  ok clicked");
                            ValidateClick(objVVD);
                        }



                    }
                    else {
                        setIsLoading(false);
                    }
                    setConfirmationDialogData(dlgEmptyConfirmationDialogState);
                };
                setIsLoading(false);
                setConfirmationDialogData({
                    obj: objMainVVD, showDialog: true, SelectedOptions: SelectedOpts
                });
            } else {
                if (bok === true) {
                    if (objMainVVD.bCancelClicked === true) {
                        console.log("else 4  cancel clicked");
                        objMainVVD = emptyVVD
                        objVVD = emptyVVD
                    }
                    else {
                        console.log("else 5  ok clicked");
                        ValidateClick(objMainVVD);
                    }
                    
                };
            };
        };
        //return bok;
    };

    const CancelClick = async () => {
        var validatedProfileCount: number = 0;
        var bok: boolean = false;
        ihData.forEach((o: CResultEntryOutput) => {
            if (o.bProfileValidated === true) validatedProfileCount += 1;
        });
        bcData.forEach((o: CResultEntryOutput) => {
            if (o.bProfileValidated === true) validatedProfileCount += 1;
        });
        if (ihData.length + bcData.length === validatedProfileCount) bok = true;
        props.ResultSaved(bok, props.reqid, props.valtype);
        setVisible(false);
    };

    // All textbox and checkboxes related to main address data
    const handleChangeMainData = (e: CheckboxChangeEvent, sProfileID: string, sResultType: string) => {
        if (sResultType.toUpperCase() === 'BC') {
            const binddata: CResultEntryOutput[] = [...bcData];
            binddata.forEach((obj: CResultEntryOutput) => {
                if (sProfileID === obj.sProfileID) {
                    obj.bProfileSel = e.value;
                };
            });
            setBCData(binddata);

        } else {
            const binddata: CResultEntryOutput[] = [...ihData];
            binddata.forEach((obj: CResultEntryOutput) => {
                if (sProfileID === obj.sProfileID) {
                    obj.bProfileSel = e.value;
                };
            });
            setIHData(binddata);
        };
    };

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setTabValue(e.selected);
    };

    //const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
    //    setReqInfo(objReqInfo);
    //};

    const RefreshIHData = (obj: CResultEntryOutput, action: string) => {
        if (action === '1') {
 
            RefreshData(obj, "ih")
            SetValButtonState(ihData)
        }
        else if (action === '2') {
            setIHData(
                ihData.map((item) =>
                    item.sProfileID === obj.sProfileID
                        ? {
                            ...item,
                            inEdit: true, disableEdit: false
                        }
                        : { ...item, inEdit: false, disableEdit: true }
                )
            );
            setDisableValidationButton(true)
        }
        else if (action === '3') {
            setIHData(
                ihData.map((item) =>
                    item.sProfileID === obj.sProfileID
                        ? {
                            ...item
                        }
                        : { ...item }
                )
            );
            SetValButtonState(ihData)
        }
        else {
            setIHData(
                ihData.map((item) =>
                    item.sProfileID === obj.sProfileID
                        ? {
                            ...item,
                            inEdit: false, disableEdit: false
                        }
                        : { ...item, inEdit: false, disableEdit: false }
                )
            );
            SetValButtonState(ihData)
            
        }
    };

    const SetValButtonState = (obj: CResultEntryOutput[]) => {
        var enableValButton: boolean = false;
        obj.forEach((o: CResultEntryOutput) => {
            if (enableValButton === false) {
                if (o.bProfileValidated === false && o.bDisableProfileSel === false) {
                    enableValButton = true;
                    setDisableValidationButton(false);
                };
            };
        });
    }

    const RefreshBCData = (obj: CResultEntryOutput, action: string) => {
        if (action === '1') {

            RefreshData(obj, "bc")
        }
        else if (action === '2') {
            setBCData(
                bcData.map((item) =>
                    item.sProfileID === obj.sProfileID
                        ? {
                            ...item,
                            inEdit: true, disableEdit: false
                        }
                        : { ...item, inEdit: false, disableEdit: true }
                )
            );
        }
        else if (action === '3') {
            setBCData(
                bcData.map((item) =>
                    item.sProfileID === obj.sProfileID
                        ? {
                            ...item
                        }
                        : { ...item }
                )
            );
        }
        else {
            setBCData(
                bcData.map((item) =>
                    item.sProfileID === obj.sProfileID
                        ? {
                            ...item,
                            inEdit: false, disableEdit: false
                        }
                        : { ...item, inEdit: false, disableEdit: false }
                )
            );


        }
    };

    const RefreshData = async (objREO: CResultEntryOutput, Type: string) => {
        //console.log('RefreshData', objREO);
        var obj: ProfileDropdownData = { code: objREO.sProfileID, desc: objREO.sProfileDesc, paramtype: "10001", resultype: objREO.sProfileResultType, avgresultyn: "", selected: false, color: "" };
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/getihdatatovalidate',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime, reqprovider: props.reqprovider,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid, obj: obj
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        //var binddata: CResultEntryOutput[] = [];
        var tempbinddata: CResultEntryOutput[] = [];
        if (Type === "ih") {
            tempbinddata = ihData;
        } else {
            tempbinddata = bcData;
        };

        let binddata = tempbinddata.map((item, index) => {
            if (item.inEdit === true) {

                item.inEdit = false;
                item.disableEdit = false;
            }
            else {
                item.inEdit = false;
                item.disableEdit = false;
            }
            return item;
        });

        if (response?.data !== null) {
            if (response?.data !== null) {
                var returnobj: CResultEntryOutput[];
                returnobj = response?.data;
                try {
                    //  Code with error
                    let index = binddata.findIndex((findobj) => findobj.sProfileID === returnobj[0].sProfileID);
                    binddata[index] = returnobj[0];
                } catch {
                    // handle the error
                } finally {
                    // execute the code before the program termination
                }
               
                if (Type === "ih") {
                   
                    setIHData(binddata);
                } else {
                    setBCData(binddata);
                };
                var enableValButton: boolean = false;
                response?.data.forEach((o: CResultEntryOutput) => {
                    if (enableValButton === false) {
                        if (o.bProfileValidated === false && o.bDisableProfileSel === false) {
                            enableValButton = true;
                            setDisableValidationButton(false);
                        };
                    };
                });

            };
        };
        setIsLoading(false);
    };

    useEffect(() => {
        setConfirmationDialogData(dlgEmptyConfirmationDialogState);
        setDisableValidationButton(true);
        GeIHDataToValidate();
        setVisible(props.showDialog);
    }, [props.reqid])

    var dlgHeight: number = window.innerHeight - 100;
    var dlgWidth: number = window.innerHeight - 100;
    var adjustHeight: number = 0;
    var reqInfoBarHeight = 0;
    var tabStripHt = 0;

    if (ref_divReqInfoBar !== undefined) {
        reqInfoBarHeight = Number(ref_divReqInfoBar.current?.clientHeight);
        adjustHeight = 100;
    } else {
        reqInfoBarHeight = 125;
        adjustHeight = 0;
    };
        
    if (isNaN(reqInfoBarHeight)) {
        reqInfoBarHeight = 125;
        adjustHeight = 0;
    };
        
    tabStripHt = ((dlgHeight - (reqInfoBarHeight + adjustHeight)) * .75) 
    //dlgWidth = (dlgWidth * 90) / 100
    // container style part - , border: '1px solid red', backgroundColor: "#e0f0ff"
    // inner container style part
    console.log('comp h, w', tabStripHt, dlgWidth);
    return (
        <>

            {visible && (
                <>{isLoading ? <ProgressBar message=""></ProgressBar> :
                    <>
                    <div className="row">
                            <div className="col-12" style={{ textAlign: "right" }}>
                            <Button disabled={disableValidationButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                VerifyData()
                            }}>{mui(userRights, "common", "cmdValidate", "Validate")}</Button>&nbsp;
                            {props.dayWorkSpace === false ?
                                <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    CancelClick()
                                }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                :
                               ""
                                }
                           
                        </div>
                    </div>
                    <div className="row" style={{ height: 1 }}>
                            <div className="col-12"></div>
                    </div>
                    
                    <div className="row">
                            <div className="col-12">
                            <div ref={ref_divReqInfoBar} className="row">
                                    <div className="col-12">
                                    <RequestInfoBar reqid={props.reqid} valtype={props.valtype} showmatdata={true} setSelectedReqInfo={() => { }}></RequestInfoBar>
                                </div>
                            </div>
                        </div>
                        </div>
                      
                        <div className="container-fluid" style={{ overflow: "hidden", height: tabStripHt, width: '98%', marginTop: 5, marginBottom: 5, marginLeft: 2, marginRight: 1, borderRadius: 0 }}>
                            <div className="row">
                            <div className="col-12">
                                               
                                {
                                    tablist.map((tab: TabEntry, index) => (

                                        <div style={{ height: tabStripHt - 10, maxWidth: '100%', marginTop: 5, marginBottom: 5, overflow: "auto", border: "1px solid black" }}>
                                            <Suspense fallback={<Loader />}>
                                                <div className="row">
                                                    {
                                                        tab.key === "1" && ihData !== null && ihData.map((obj: CResultEntryOutput, ind) => (
                                                            <div className="col-12" key={uuidv4()}>
                                                                <IHProfileBlock obj={obj} callbackhandleGroupCheckbox={handleChangeMainData} callbackRefreshData={RefreshIHData} ></IHProfileBlock>&nbsp;
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        tab.key === "2" && bcData !== null && bcData.map((obj: CResultEntryOutput, ind) => (
                                                            <div className="col-12" key={uuidv4()}>
                                                                <IHProfileBlock obj={obj} callbackhandleGroupCheckbox={handleChangeMainData} callbackRefreshData={RefreshBCData} ></IHProfileBlock>&nbsp;
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            </Suspense>
                                        </div>
                                
                                    ))
                                    } 
                                
                        </div>
                            </div>
                        </div>
                        </>
                    }
                   
                </>
                
            )}
            <Suspense fallback={<Loader />}>
                <ConfirmationDialog obj={confirmationDialogData.obj} showDialog={confirmationDialogData.showDialog} SelectedOptions={confirmationDialogData.SelectedOptions}></ConfirmationDialog>
            </Suspense>
        </>
    );
}

export default IHValidationComponent;