
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Suspense, useEffect, useRef, useState } from "react";
import { mui, useIntersection } from "../../Helper";
import { CRequestResult, CResultEntry, CResultEntryOutput, CUserRightsAppl, emptyREData, ReqInfoBar, resultEntryDialog, resultSelDialog, selCodeDataType, requestDialogData, COneRequest } from "../../types";
import axios from 'axios';
import UserSession from "../UserSession";
import { Button } from "@progress/kendo-react-buttons";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import React from "react";
import RequestInfoBar from "../ReqInfoBar/RequestInfoBar";
import ResultSelDialog from "./ResultSelDialog";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import RequestDialog from "../RequestEntry/RequestDialog";
import ProgressBar from "../Misc/ProgressBar";

var mainResultData: CResultEntry[] = ([]);

const GridContext = React.createContext({});
const allInEdit = mainResultData.map((item) =>
    Object.assign({ inEdit: true }, item)
);
const GridDataCellNumResult = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CRequestResult>();;

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        var obj: CRequestResult = e.value
        setComboResultValue(e.value);
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === obj.sParamId) {
                    item.sSerIDToValidate = obj.sSerID;
                    item.sResultComm = obj.sResultComm;
                    item.sCommText = obj.sCommText;
                    item.sResultText = obj.sResultText;
                    item.sResultFlag = obj.sResultFlag;
                    item.sInterpretationDesc = obj.sResult;
                }
                console.log('item', item);
                return item;
            })
        );
    };

    if (cellProps.dataItem.ExistingResult.length > 1 && (cellProps.dataItem.sParamStatus === '3' || cellProps.dataItem.sParamStatus === '63')) {
        return (
            <td>
                <DropDownList
                    data={cellProps.dataItem.ExistingResult}
                    textField="sRawResult"
                    dataItemKey="sSerID"
                    value={comboResultValue}
                    onChange={handleResultComboChange}
                    style={{ width: "100%" }}
                />
            </td>
        );
    } else if (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') {
        var bdisabled: boolean = false;
        if (Number(cellProps.dataItem.sParamStatus) > 2) bdisabled = true;
        if (cellProps.dataItem.sParamType === "3") bdisabled = true;
        return (
            <td>
                <Input
                    {...cellProps}
                    disabled={bdisabled}
                    value={cellProps.dataItem.sRawResult}
                    onChange={GridDataCellChange}
                    onBlurCapture={() => ValidateNumResult(cellProps.dataItem, resultData, setResultData, resultMainData)}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    } else {
        return (
            <td>
                <Input
                    {...cellProps}
                    disabled={true}
                    value={cellProps.dataItem.sRawResult}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    };

};

const ValidateNumResult = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    console.log(binddata);
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/validateresult',
        JSON.stringify({
            objResultEntry: obj, collRatioValLevel: resultMainData.collRatioValLevel
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objREO = response?.data
        if (objREO.bInvalidCode === true) alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        for (let i = 0; i < binddata.length; i++) {
            if (binddata[i].sParamID === objREO.sParamID) {
                binddata[i] = JSON.parse(JSON.stringify(objREO));
                break;
            };
        };
        setResultData(binddata);
    };
};

const CustomCellEditQualResult = (cellProps: any) => {

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    return (
        <td>
            <Input
                {...cellProps}
                value={cellProps.dataItem.sQualitativeResult}
                onChange={GridDataCellChange}
                onBlurCapture={() => ValidateQualResult(cellProps.dataItem, resultData, setResultData, resultMainData)}
                style={{ textAlign: 'right' }}
                width="100%"
                disabled={(cellProps.dataItem.sShowQualitativeYN === "1" && (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2')) ? false : true}
            />
        </td>
    );
};

const ValidateQualResult = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/validateresult',
        JSON.stringify({
            objResultEntry: obj, collRatioValLevel: resultMainData.collRatioValLevel, validatequalresult: true
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objREO = response?.data
        if (objREO.bInvalidCode === true) alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        for (let i = 0; i < binddata.length; i++) {
            if (binddata[i].sParamID === objREO.sParamID) {
                binddata[i] = JSON.parse(JSON.stringify(objREO));
                break;
            };
        };
        setResultData(binddata);
    };
};

const GridDataCellResultComm = (cellProps: any) => {

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);
    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    var sResultComm: string = "";
    if ((cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2')) {
        sResultComm = cellProps.dataItem.sResultComm;
    } else if (cellProps.dataItem.sParamStatus === '3' && cellProps.dataItem.ExistingResult.length === 1) {
        sResultComm = cellProps.dataItem.sResultComm;
    } else if (cellProps.dataItem.ExistingResult.length === 1) {
        sResultComm = cellProps.dataItem.ExistingResult[0].sResultComm;
    } else if (cellProps.dataItem.ExistingResult.length > 1) {
        sResultComm = cellProps.dataItem.sResultComm;
    };
    return (
        <td>
            <Input
                {...cellProps}
                disabled={false}
                value={sResultComm}
                onChange={GridDataCellChange}
                onBlurCapture={() => ValidateResultComm(cellProps.dataItem, resultData, setResultData, resultMainData)}
                width="100%"
            />
        </td>
    );
};

const ValidateResultComm = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    console.log(binddata);
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/validateresultcomm',
        JSON.stringify({
            mandantid: obj.sMandantID, resultcomm: obj.sResultComm, desc: "", binvalidcode: false
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objRCO = response?.data
        if (objRCO.binvalidcode === true) {
            alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        } else {
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === obj.sParamID) {
                    binddata[i].sResultComm = objRCO.resultcomm;
                    break;
                };
            };
            setResultData(binddata);
        };
    };
};


const ChemieResAndValComponent = (props: resultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'ChemieResultEntry' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const dlgEmptyState: resultSelDialog = {
        selType: "", objRE: emptyREData, selqualresult: false, showDialog: false, CodeSelected: () => { }
    }
    const [resultSelDialogData, setResultSelDialogData] = useState<resultSelDialog>(dlgEmptyState);
    const [isLoading, setIsLoading] = useState(false)    
    const [resultMainData, setResultMainData] = useState<CResultEntryOutput>();
    const [resultData, setResultData] = useState<CResultEntry[]>([]);
    const [resDate, setResDate] = React.useState<Date | null>(null);
    const [resTime, setResTime] = React.useState<Date | null>(null);
    const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean | false>(true);
    const [disableValidationButton, setDisableValidationButton] = useState<boolean | false>(true);
    const [resultSaved, setResultSaved] = useState<boolean | false>(false);
    const [resultValidated, setresultValidated] = useState<boolean | false>(false);

    // for request dialog
    const ref = useRef<HTMLDivElement>(null);
    const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    const [requestDialogData, setRequestDialogData] = useState<requestDialogData>({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });

    const ref_divButtons = useRef<HTMLDivElement>(null);
    const ref_divReqInfoBar = useRef<HTMLDivElement>(null);
    const ref_divGrid = useRef<HTMLDivElement>(null);

    useEffect(() => {
        var reqTemp = { ...requestDialogData, inViewport: inViewport }
        setRequestDialogData(reqTemp);
    }, [inViewport]);
   
    const callbackRequestDialogClosed = (objRequest: COneRequest | null) => {
        setRequestDialogData({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });
        GetResultData();
    };

    const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
        setReqInfo(objReqInfo);
    };
    const MatQualityClick = async () => {
        setIsLoading(true);
        if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/checkparammatquality',
            JSON.stringify({
                listResEntry: resultData, sQCMaxTimeWarningParams: resultMainData?.sQCMaxTimeWarningParams, sParamsInGrid: resultMainData?.sParamsInGrid,
                collPrevalResult: resultMainData?.collPrevalResult, collResultCodeComments: resultMainData?.collResultCodeComments,
                collRatioValLevel: resultMainData?.collRatioValLevel, collParamUnderProfile: resultMainData?.collParamUnderProfile,
                bQualResultColVisible: resultMainData?.bQualResultColVisible, collValidatedParams: resultMainData?.collValidatedParams,
                sUserID: UserSession.getLoggedInUser().UserID, sErrorMsg: ""
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        var maindata: CResultEntryOutput | undefined;
        var binddata: CResultEntry[] = [];
        if (response?.data !== null) maindata = response?.data;
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                alert(maindata.sErrorMsg);
            } else {
                setDisableSaveButton(true);
                setDisableValidationButton(true);

                maindata.listResEntry.forEach((o: CResultEntry) => {
                    if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    if (o.sParamStatus === "3") setDisableValidationButton(false);
                    binddata.push({ ...o });
                });

                setResultData(binddata);
                setResDate(null);
                setResTime(null);
                setResultSaved(true);

                if (resultMainData !== undefined) {
                    var newResultMainData = resultMainData;
                    newResultMainData.listResEntry = binddata;
                    setResultMainData(newResultMainData);
                };
            };
        };
        setIsLoading(false);
    };
    const ValidateClick = async () => {
        var bCloseForm: boolean = false;
        if (VerifyData() === true) {
            setIsLoading(true);
            if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/validaterequestresultdata',
                JSON.stringify({
                    listResEntry: resultData, sQCMaxTimeWarningParams: resultMainData?.sQCMaxTimeWarningParams, sParamsInGrid: resultMainData?.sParamsInGrid,
                    collPrevalResult: resultMainData?.collPrevalResult, collResultCodeComments: resultMainData?.collResultCodeComments,
                    collRatioValLevel: resultMainData?.collRatioValLevel, collParamUnderProfile: resultMainData?.collParamUnderProfile,
                    bQualResultColVisible: resultMainData?.bQualResultColVisible, collValidatedParams: resultMainData?.collValidatedParams,
                    sProfileID: "", sUserID: UserSession.getLoggedInUser().UserID, sValType: resultMainData?.sValType, bRefreshGrid: false, sErrorMsg: ""
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            var maindata: CResultEntryOutput | undefined;
            var binddata: CResultEntry[] = [];
            if (response?.data !== null) maindata = response?.data;
            if (maindata !== undefined) {
                if (maindata.sErrorMsg !== "") {
                    alert(maindata.sErrorMsg);
                } else {
                    setDisableSaveButton(true);
                    setDisableValidationButton(true);

                    maindata.listResEntry.forEach((o: CResultEntry) => {
                        if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                        if (o.sParamStatus === "3") setDisableValidationButton(false);
                        binddata.push({ ...o });
                    });

                    if (binddata.length === 0) bCloseForm = true;
                    setResultData(binddata);
                    setResDate(null);
                    setResTime(null);
                    setresultValidated(true);

                    if (bCloseForm === true) {
                        CancelClick();
                    } else {
                        if (resultMainData !== undefined) {
                            var newResultMainData = resultMainData;
                            newResultMainData.listResEntry = binddata;
                            setResultMainData(newResultMainData);
                        };
                    };
                };
            };

            setIsLoading(false);
        };
    };

    function VerifyData() {
        var bok: boolean = true;
        //var obj: CResultEntry;
        //for (obj of resultData) {

        //}
        return bok;
    };
    const SaveClick = async () => {
        if (VerifyData() === true) {
            setIsLoading(true);
            if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/saverequestresultdata',
                JSON.stringify({
                    listResEntry: resultData, sQCMaxTimeWarningParams: resultMainData?.sQCMaxTimeWarningParams, sParamsInGrid: resultMainData?.sParamsInGrid,
                    collPrevalResult: resultMainData?.collPrevalResult, collResultCodeComments: resultMainData?.collResultCodeComments,
                    collRatioValLevel: resultMainData?.collRatioValLevel, collParamUnderProfile: resultMainData?.collParamUnderProfile,
                    bQualResultColVisible: resultMainData?.bQualResultColVisible, collValidatedParams: resultMainData?.collValidatedParams,
                    sUserID: UserSession.getLoggedInUser().UserID, sErrorMsg: ""
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            var maindata: CResultEntryOutput | undefined;
            var binddata: CResultEntry[] = [];
            if (response?.data !== null) maindata = response?.data;
            if (maindata !== undefined) {
                if (maindata.sErrorMsg !== "") {
                    alert(maindata.sErrorMsg);
                } else {
                    setDisableSaveButton(true);
                    setDisableValidationButton(true);

                    maindata.listResEntry.forEach((o: CResultEntry) => {
                        if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                        if (o.sParamStatus === "3") setDisableValidationButton(false);
                        binddata.push({ ...o });
                    });

                    setResultData(binddata);
                    setResDate(null);
                    setResTime(null);
                    setResultSaved(true);

                    if (resultMainData !== undefined) {
                        var newResultMainData = resultMainData;
                        newResultMainData.listResEntry = binddata;
                        setResultMainData(newResultMainData);
                    };
                };
            };
            setIsLoading(false);
        };
    };

    const CancelClick = async () => {
        var bok: boolean = false;
        console.log('resultSaved', resultSaved);
        console.log('bResultsValidated', resultValidated);
        if (resultSaved === true || resultValidated === true) bok = true;
        props.ResultSaved(bok, props.reqid, props.valtype);        
    };

    const GetResultData = async () => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/getrequestresultdata',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime,
                reqprovider: props.reqprovider, valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        // console.log(response?.data);        
        var binddata: CResultEntry[] = [];
        if (response?.data !== null) {
            setResultMainData(response?.data);
            response?.data.listResEntry.forEach((o: CResultEntry) => {
                if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                if (o.sParamStatus === "3") setDisableValidationButton(false);
                binddata.push({ ...o });
            });
            setResultData(binddata);
            setResDate(null);
            setResTime(null);
            //mainResultData = binddata;        
        };
        setResultSaved(false);
        setresultValidated(false);
        setIsLoading(false);

    };

    const callbackQualResultSel = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {
        const binddata: CResultEntry[] = [...resultData];
        var objRE: CResultEntry;
        for (objRE of binddata) {
            if (objRE.sParamID === supplParam1) {
                objRE.sQualitativeResult = obj.code;
                break;
            }
        }
        setResultData(binddata);
    };

    const CustomCellEditResultSel = (propsG: GridCellProps) => {
        const status = propsG.dataItem['sParamStatus'];
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResSelClick(propsG.dataItem, 'open') }}
                    disabled={(status === '1' || status === '2') ? false : true}
                >
                    ...
                </Button>

            </td>
        );
    };

    const onResSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                console.log('objRE', objRE);
                objRE.sRawResult = Code;
                if (Code.toUpperCase() === "FT") {
                    objRE.sResultText = Desc;
                } else {
                    objRE.sResultText = "";
                };
                ValidateResult(objRE, false);
            }
            setResultSelDialogData(dlgEmptyState);
        }

        setResultSelDialogData({ selType: "Result", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const onResCommSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                const binddata: CResultEntry[] = [...resultData];
                var obj: CResultEntry;
                for (obj of binddata) {
                    if (obj.sParamID === objRE.sParamID) {
                        obj.sResultComm = Code;
                        if (Code.toUpperCase() === "FT") {
                            objRE.sCommText = Desc;
                        } else {
                            objRE.sCommText = "";
                        };
                        break;
                    }
                }
                setResultData(binddata);
            }
            setResultSelDialogData(dlgEmptyState);
        }

        setResultSelDialogData({ selType: "ResultComm", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const ValidateResult = async (objRE: CResultEntry, bQualResult: boolean) => {
        const binddata: CResultEntry[] = [...resultData];

        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/validateresult',
            JSON.stringify({
                objResultEntry: objRE, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: bQualResult
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objREO = response?.data
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === objREO.sParamID) {
                    binddata[i] = JSON.parse(JSON.stringify(objREO));
                    break;
                };
            };
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    const CustomCellEditQualResultSel = (propsG: GridCellProps) => {
        const qualyn = propsG.dataItem['sShowQualitativeYN'];
        const status = propsG.dataItem['sParamStatus'];
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onQualResSelClick(propsG.dataItem, 'open') }}
                    disabled={(qualyn === "1" && (status === '1' || status === '2')) ? false : true}
                >
                    ...
                </Button>

            </td>
        );
    };

    const onQualResSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                objRE.sQualitativeResult = Code;
                ValidateResult(objRE, true);
            }
            setResultSelDialogData(dlgEmptyState);
        }

        setResultSelDialogData({ selType: "Result", objRE: objRE, selqualresult: true, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const CustomCellEditResultComm = (props: GridCellProps) => {
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResCommSelClick(props.dataItem, 'open') }}
                    disabled={false}
                >
                    ...
                </Button>

            </td>
        );
    };
    const CustomCellEditTel = (props: GridCellProps) => {
        const checkvalue = props.dataItem['sTelephonedYN'];
        return (
            <td >
                <Checkbox disabled={true} checked={checkvalue === "1" ? true : false}></Checkbox>
            </td>
        );
    };

    const CustomCellEditAddnParam = (props: GridCellProps) => {
        const checkvalue = props.dataItem['sAddnParamYN'];
        return (
            <td >
                <Checkbox disabled={true} checked={checkvalue === "1" ? true : false}></Checkbox>
            </td>
        );
    };

    const CustomCellPreVal1 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal1'];
        const tttext = props.dataItem['sPreVal1Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellPreVal2 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal2'];
        const tttext = props.dataItem['sPreVal2Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellPreVal3 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal3'];
        const tttext = props.dataItem['sPreVal3Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellEditReRun = (props: GridCellProps) => {
        const objRE: CResultEntry = props.dataItem;
        var disabled: boolean = true;
        if (Number(objRE.sParamStatus) > 2) disabled = false;
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button disabled={disabled} title={mui(userRights, "common", "ttRerunParam", "Rerun Param")} className="buttons-container-button" icon="refresh"
                    onClick={() => {
                        objRE.sReRunParamYN = "1";
                        ReRunParam(objRE);
                    }}
                >
                </Button>
            </td>
        );
    };

    const ReRunParam = async (objRE: CResultEntry) => {
        const binddata: CResultEntry[] = [...resultData];

        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'chemie/rerunparam',
            JSON.stringify({
                objResultEntry: objRE, sUserID: props.userid
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objREO = response?.data
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === objREO.objResultEntry.sParamID) {
                    binddata[i] = JSON.parse(JSON.stringify(objREO.objResultEntry));
                    break;
                };
            };
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    const CustomCellEditParam = (props: GridCellProps) => {
        const paramid = props.dataItem['sParamID'];
        const paramdesc = props.dataItem['sParamDesc'];

        return (
            <td title={paramid}>
                {paramdesc}
            </td>
        );
    };

    const CustomCellEditInterp = (props: GridCellProps) => {
        const desc = props.dataItem['sInterpretationDesc'];
        const color = props.dataItem['sResultColColor'];
        return (
            <td style={{ backgroundColor: color }} title={desc}>
                {desc}
            </td>
        );
    };

    useEffect(() => {
        if (props.showDialog) GetResultData();        
    }, [props.showDialog])

    var rootHeight = window.innerHeight; /*900*/
    var buttonHeight = 0;
    var reqInfoBarHeight = 0;
    var gridHt = 0;

    if (ref_divButtons !== undefined) buttonHeight = Number(ref_divButtons.current?.clientHeight);
    if (ref_divReqInfoBar !== undefined) reqInfoBarHeight = Number(ref_divReqInfoBar.current?.clientHeight);
    gridHt = rootHeight - (buttonHeight + reqInfoBarHeight + 150);

    return (
        <>
            <div className="container-fluid" style={{ overflowX: 'hidden', width: '97%', marginLeft: 10, marginRight: 1 }}>
                    <div ref={ref_divButtons} className="row">
                       
                        <div className="col" style={{ textAlign: "right" }}>                            
                            <Button disabled={disableValidationButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                ValidateClick()
                            }}>{mui(userRights, "common", "cmdValidate", "Validate")}</Button>&nbsp;
                            <Button disabled={disableSaveButton} style={{ width: '150px' }} type={"button"} themeColor={'primary'} onClick={() => {
                                MatQualityClick()
                            }}>{mui(userRights, "common", "cmdMatQuality", "Mat Quality")}</Button>&nbsp;
                            <Button disabled={disableSaveButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                SaveClick()
                            }}>{mui(userRights, "common", "cmdSave", "Save")}</Button>&nbsp;
                            {props.dayWorkSpace === false ?
                                <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    CancelClick()
                                }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                :
                                ""
                            }
                            
                        </div>
                    </div>
                    <div className="row" style={{ height: 1 }}></div>
                    <div ref={ref_divReqInfoBar} className="row">
                        <RequestInfoBar reqid={props.reqid} valtype={props.valtype} showmatdata={true} setSelectedReqInfo={callbackReqInfo}></RequestInfoBar>
                    </div>
                    <div ref={ref_divGrid} className="row">
                        <div className="col">
                            {isLoading ? <ProgressBar message=""></ProgressBar> : 
                            <GridContext.Provider
                                value={{ setResultData, resultData, resultMainData }}
                            >
                                <Grid
                                    style={{
                                        height: gridHt, width: '100%', marginLeft: 2, marginRight: 0
                                    }}
                                    data={resultData}
                                    dataItemKey={"sParamID"}
                                    editField="inEdit"
                                >
                                    <GridColumn field="" title={mui(userRights, "ChemieResultEntry", "col_btnReRun", "")} width="50px" cell={CustomCellEditReRun} />
                                    <GridColumn field="sParamDesc" title={mui(userRights, "ChemieResultEntry", "col_ParamDesc", "Param")} width="120px" cell={CustomCellEditParam} />
                                    <GridColumn field="sPreVal1" title={mui(userRights, "ChemieResultEntry", "col_PreVal1", "PreVal1")} cell={CustomCellPreVal1} />
                                    <GridColumn field="sPreVal2" title={mui(userRights, "ChemieResultEntry", "col_PreVal2", "PreVal2")} cell={CustomCellPreVal2} />
                                    <GridColumn field="sPreVal3" title={mui(userRights, "ChemieResultEntry", "col_PreVal3", "PreVal3")} cell={CustomCellPreVal3} />
                                    <GridColumn field="sRawResult" title={mui(userRights, "ChemieResultEntry", "col_Result", "Result")} width="125px" cell={GridDataCellNumResult} />
                                    <GridColumn field="" title={mui(userRights, "ChemieResultEntry", "col_ResultSel", " ")} width="50px" cell={CustomCellEditResultSel} />
                                    <GridColumn field="sQualitativeResult" title={mui(userRights, "ChemieResultEntry", "col_QResult", "QualResult")} width={resultMainData !== undefined && resultMainData.bQualResultColVisible === true ? "100px" : 0} cell={CustomCellEditQualResult} />
                                    <GridColumn field="" title={mui(userRights, "ChemieResultEntry", "col_QResultSel", " ")} width={resultMainData !== undefined && resultMainData.bQualResultColVisible === true ? "50px" : 0} cell={CustomCellEditQualResultSel} />
                                    <GridColumn field="sInterpretationDesc" title={mui(userRights, "ChemieResultEntry", "col_Interpretation", "Interpretation")} width="200px" cell={CustomCellEditInterp} />
                                    {/*<GridColumn field="sResultColColor" title={mui(userRights, "ChemieResultEntry", "col_ResColor", " ")} width="50px" cell={CustomCellEditResultColor} />*/}
                                    <GridColumn field="sResultComm" title={mui(userRights, "ChemieResultEntry", "col_ResultComm", "Result Comm")} width="100px" cell={GridDataCellResultComm} />
                                    <GridColumn field="" title={mui(userRights, "ChemieResultEntry", "col_ResultCommSel", " ")} width="50px" cell={CustomCellEditResultComm} />
                                    <GridColumn field="sDilution" title={mui(userRights, "ChemieResultEntry", "col_Dilution", "Dilution")} />
                                    <GridColumn field="sRefValue" title={mui(userRights, "ChemieResultEntry", "col_RefValue", "Ref Value")} width="90px" />
                                    <GridColumn field="sUnit" title={mui(userRights, "ChemieResultEntry", "col_Unit", "Unit")} />
                                    <GridColumn field="sTelephonedYN" title={mui(userRights, "ChemieResultEntry", "col_TelephonedYN", "TelephonedYN")} cell={CustomCellEditTel} />
                                    <GridColumn field="sResultFlag" title={mui(userRights, "ChemieResultEntry", "col_ResultFlag", "Result Flag")} />
                                    {/*<GridColumn field="sValLevel" title={mui(userRights, "ChemieResultEntry", "col13_ValLevel", "ValLevel")} />*/}
                                    {/*<GridColumn field="sLowRefVal" title={mui(userRights, "ChemieResultEntry", "col14_LowRefVal", "LowRefVal")} />*/}
                                    {/*<GridColumn field="sHighRefVal" title={mui(userRights, "ChemieResultEntry", "col15_HighRefVal", "HighRefVal")} />*/}
                                    <GridColumn field="sValidate" title={mui(userRights, "ChemieResultEntry", "col_Validate", "Validate")} />
                                    <GridColumn field="sAddnParamYN" title={mui(userRights, "ChemieResultEntry", "col_AddnParamYN", "AddnParamYN")} cell={CustomCellEditAddnParam} />
                                </Grid>
                                </GridContext.Provider>
                            }
                        </div>
                    </div>
                </div>

            <Suspense fallback={<Loader />}>
                <ResultSelDialog selType={resultSelDialogData.selType} objRE={resultSelDialogData.objRE} selqualresult={resultSelDialogData.selqualresult} showDialog={resultSelDialogData.showDialog}
                    CodeSelected={resultSelDialogData.CodeSelected}></ResultSelDialog>
                <RequestDialog key={'R'} data={requestDialogData} callbackRequestDialogClosed={callbackRequestDialogClosed}></RequestDialog>
            </Suspense>
        </>
    );
}
export default ChemieResAndValComponent;