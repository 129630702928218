import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { RadioButton, RadioButtonChangeEvent, TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { CConfirmationMsg, confirmationDialog, CUserRightsAppl, CVerifyValidationData} from "../../types";

export const ConfirmationDialog = (props: confirmationDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'ConfirmationDialog' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [visible, setVisible] = useState<boolean>(props.showDialog);    
    const [confirmationMsg, setConfirmationMsg] = useState<CConfirmationMsg[]>([]);
    const [reactionReason, setReactionReason] = useState<string | "">("");    

    const CustomCellMsg = (props: GridCellProps) => {
        const msg = props.dataItem['msg'];       
        return (
            <td>{msg}</td>
        );
    };
    const CustomCellActionYes = (props: GridCellProps) => {
        const msgid = props.dataItem['msgid'];
        const action = props.dataItem['action'];
        return (
            <td>
                <RadioButton
                    name=""
                    value="6"
                    checked={action === "6"}
                    label={mui(userRights, "common", "optYes", "Yes")}
                    onChange={(event: RadioButtonChangeEvent) => handleRBChange(event,msgid)}
                />
            </td>
        );
    };
    const CustomCellActionNo = (props: GridCellProps) => {
        const msgid = props.dataItem['msgid'];
        const action = props.dataItem['action'];
        return (
            <td>
                <RadioButton
                    name=""
                    value="7"
                    checked={action === "7"}
                    label={mui(userRights, "common", "optNo", "No")}
                    onChange={(event: RadioButtonChangeEvent) => handleRBChange(event, msgid)}
                />
            </td>
        );
    };

    const handleRBChange = (e: RadioButtonChangeEvent, msgid:string) => {
        const binddata: CConfirmationMsg[] = [...confirmationMsg];
        var obj: CConfirmationMsg;
        for (obj of binddata) {
            if (obj.msgid === msgid) {
                obj.action = e.value;
                break;
            }
        }
        setConfirmationMsg(binddata);
    };

    const CancelClick = async () => {
        var objVVD: CVerifyValidationData = props.obj;
        objVVD.bCancelClicked = true
        props.SelectedOptions(objVVD);
        setVisible(false);
    }

    const OkClick = async () => {
        var bClose: boolean = true;
        var obj: CConfirmationMsg;
        for (obj of confirmationMsg) {
            if (obj.action === "") {
                alert(mui(userRights, "ConfirmationDialog", "msgSelOptionForAllMsgs", "Pls select option for all messages"));
                bClose = false;
                break;
            }
        };
        if (props.obj.nGetReactionReason === 1 && reactionReason === "") {
            alert(mui(userRights, "ConfirmationDialog", "msgEnterReactionReason", "Pls enter reaction reason"));
            bClose = false;
        };
        if (bClose === true) {
            var objVVD: CVerifyValidationData = props.obj;
            objVVD.ConfirmationMsg = confirmationMsg;
            objVVD.sReactionReason = reactionReason;
            console.log("OkClick", objVVD)
            props.SelectedOptions(objVVD);
            setVisible(false);
        };
    };

    const reactionReasonChange = (e: any) => {
        setReactionReason(e.target.value);
    };

    useEffect(() => {
        setReactionReason("");
        setConfirmationMsg(props.obj.ConfirmationMsg);
 
        setVisible(props.showDialog);
    }, [props.showDialog])

    return (
        <>
            <div>
                {visible && (
                    <Dialog title={<DialogTitleBar title={mui(userRights, "ConfirmationDialog", "title", "Confirmation Dialog")} />} width={1000} >
                        <div className="container-fluid" style={{ overflowX: 'hidden', width: '94%' }}>
                            {
                                props.obj.ConfirmationMsg.length === 0 ?
                                    ""
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <Grid
                                                    style={{
                                                        height: '100%', width: '100%', marginLeft: 2, marginRight: 0
                                                    }}

                                                    data={confirmationMsg}
                                                    dataItemKey={"msgid"}
                                                    editField="inEdit"
                                                >
                                                    <GridColumn field="msg" title={mui(userRights, "ConfirmationDialog", "col_msg", "Message")} width="700px" cell={CustomCellMsg} />
                                                    <GridColumn field="actionY" title={mui(userRights, "ConfirmationDialog", "col_action_yes", "Yes")} width="100px" cell={CustomCellActionYes} />
                                                    <GridColumn field="action" title={mui(userRights, "ConfirmationDialog", "col_action_no", "No")} width="100px" cell={CustomCellActionNo} />
                                                    );
                                                </Grid>
                                            </div>
                                        </div>

                                        <div className="row">&nbsp;</div>
                                    </>
                            }
                            {
                                props.obj.nGetReactionReason === 1 ?
                                    <>
                                        <div className="row">
                                            <div className="col">
                                                <b><div><Label editorId={'lblReactionReason'}>{mui(userRights, "ConfirmationDialog", "StaticTextReactionReasonMsg", "Reaction Reason")}</Label></div></b>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                            <div className="col">
                                                <div>
                                                    <TextArea disabled={props.obj.nGetReactionReason === 1 ? false : true} autoComplete="off" id="it" name={"inputText"} style={{ height: '50px', width: '100%' }}
                                                        onChange={reactionReasonChange} value={reactionReason} maxLength={255} />
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                :
                                ""
                            }
                          
                            <div className="row">
                                <div className="col">
                                    <div className="button-right">
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                            OkClick()
                                        }}>{mui(userRights, "common", "cmdOk", "Ok")}</Button>&nbsp;    
                                        <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                            CancelClick()
                                        }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>&nbsp;       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Dialog>
                )}
            </div>
        </>
    )
}