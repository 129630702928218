import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import { Label } from "@progress/kendo-react-labels";
import React, { Suspense,  useEffect } from "react";
import { useRef, useState } from "react";
import { mui, useIntersection } from "../../Helper";
import { CRequestResult, CResultEntry, CResultEntryOutput, CUserRightsAppl, emptyREData, ProfileDropdownData, ReqInfoBar, resultEntryDialog, resultSelDialog, requestDialogData, COneRequest } from "../../types";
import RequestInfoBar from "../ReqInfoBar/RequestInfoBar";
import ResultSelDialog from "./ResultSelDialog";
import axios from 'axios';
import UserSession from "../UserSession";
import { Input } from "@progress/kendo-react-inputs";
import RequestDialog from "../RequestEntry/RequestDialog";
import ProgressBar from "../Misc/ProgressBar";

var mainResultData: CResultEntry[] = ([]);

const GridContext = React.createContext({});
const allInEdit = mainResultData.map((item) =>
    Object.assign({ inEdit: true }, item)
);

const GridDataCellNumResult = (cellProps: any) => {
    const [comboResultValue, setComboResultValue] = useState<CRequestResult>();;

    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);

    if (cellProps.dataItem.bDisableResultEntry === true) {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    const handleResultComboChange = (e: DropDownListChangeEvent) => {
        var obj: CRequestResult = e.value
        setComboResultValue(e.value);
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === obj.sParamId) {
                    item.sSerIDToValidate = obj.sSerID;
                    item.sResultComm = obj.sResultComm;
                    item.sCommText = obj.sCommText;
                    item.sResultText = obj.sResultText;
                    item.sResultFlag = obj.sResultFlag;
                    item.sInterpretationDesc = obj.sResult;
                }
                console.log('item', item);
                return item;
            })
        );
    };

    if (cellProps.dataItem.ExistingResult.length > 1 && (cellProps.dataItem.sParamStatus === '3' || cellProps.dataItem.sParamStatus === '63')) {
        return (
            <td>
                <DropDownList
                    data={cellProps.dataItem.ExistingResult}
                    textField="sRawResult"
                    dataItemKey="sSerID"
                    value={comboResultValue}
                    onChange={handleResultComboChange}
                    style={{ width: "100%" }}
                />
            </td>
        );
    } else if (cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') {
        return (
            <td>
                <Input
                    {...cellProps}
                    disabled={(cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2') ? false : true}
                    value={cellProps.dataItem.sRawResult}
                    onChange={GridDataCellChange}
                    onBlurCapture={() => ValidateNumResult(cellProps.dataItem, resultData, setResultData, resultMainData)}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    } else {
        return (
            <td>
                <Input
                    {...cellProps}
                    disabled={true}
                    value={cellProps.dataItem.sRawResult}
                    style={{ textAlign: 'right' }}
                    width="100%"
                />
            </td>
        );
    };

};

const ValidateNumResult = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    console.log(binddata);
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'urinesticks/validateresult',
        JSON.stringify({
            objResultEntry: obj, collRatioValLevel: resultMainData.collRatioValLevel
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objREO = response?.data
        if (objREO.bInvalidCode === true) alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        for (let i = 0; i < binddata.length; i++) {
            if (binddata[i].sParamID === objREO.sParamID && binddata[i].bDisableResultEntry === false) {
                binddata[i] = JSON.parse(JSON.stringify(objREO));
                break;
            };
        };
        setResultData(binddata);
    };
};

const GridDataCellResultComm = (cellProps: any) => {
    const { resultMainData }: any = React.useContext(GridContext);
    const { resultData }: any = React.useContext(GridContext);
    const { setResultData }: any = React.useContext(GridContext);

    if (cellProps.dataItem.bDisableResultEntry === true) {
        return (
            <td></td>
        );
    };

    const GridDataCellChange = (e: any) => {
        const dataItem = e.dataItem ?? e.target.props.dataItem;
        const field = e.field ?? e.target.props.field;
        setResultData((oldGridData: any) =>
            oldGridData.map((item: any) => {
                if (item.sParamID === dataItem.sParamID) {
                    item[field || ''] = e.value;
                }
                return item;
            })
        );
    };

    var sResultComm: string = "";
    var bdisable: boolean = true;
    if ((cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2')) bdisable = false;
    if ((cellProps.dataItem.sParamStatus === '1' || cellProps.dataItem.sParamStatus === '2')) {
        sResultComm = cellProps.dataItem.sResultComm;
    } else if (cellProps.dataItem.sParamStatus === '3' && cellProps.dataItem.ExistingResult.length === 1) {
        sResultComm = cellProps.dataItem.sResultComm;
    } else if (cellProps.dataItem.ExistingResult.length === 1) {
        sResultComm = cellProps.dataItem.ExistingResult[0].sResultComm;
    } else if (cellProps.dataItem.ExistingResult.length > 1) {
        sResultComm = cellProps.dataItem.sResultComm;
    };
    return (
        <td>
            <Input
                {...cellProps}
                disabled={bdisable}
                value={sResultComm}
                onChange={GridDataCellChange}
                onBlurCapture={() => ValidateResultComm(cellProps.dataItem, resultData, setResultData, resultMainData)}
                width="100%"
            />
        </td>
    );
};

const ValidateResultComm = async (cellProps: any, mainResultData: CResultEntry[], setResultData: any, resultMainData: any) => {
    var code: string = "";
    var binvalidcode: boolean;
    const obj: CResultEntry = cellProps;
    const binddata: CResultEntry[] = [...mainResultData];
    console.log(binddata);
    const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/validateresultcomm',
        JSON.stringify({
            mandantid: obj.sMandantID, resultcomm: obj.sResultComm, desc: "", binvalidcode: false
        }),
        {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                'Content-Type': 'application/json'
            },
            withCredentials: false
        }
    );
    if (response?.data !== null) {
        var objRCO = response?.data
        if (objRCO.binvalidcode === true) {
            alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
        } else {
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === obj.sParamID) {
                    binddata[i].sResultComm = objRCO.resultcomm;
                    break;
                };
            };
            setResultData(binddata);
        };
    };
};

const UrineSticksResAndValComponent = (props: resultEntryDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'UrineSticksResultAndValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const ref_divButtons = useRef<HTMLDivElement>(null);
    const ref_divReqInfoBar = useRef<HTMLDivElement>(null);
    const ref_divGrid = useRef<HTMLDivElement>(null);

    const dlgEmptyState: resultSelDialog = {
        selType: "", objRE: emptyREData, selqualresult: false, showDialog: false, CodeSelected: () => { }
    }
    const [resultSelDialogData, setResultSelDialogData] = useState<resultSelDialog>(dlgEmptyState);

    const [resultMainData, setResultMainData] = useState<CResultEntryOutput>();
    const [resultData, setResultData] = useState<CResultEntry[]>([]);
    const [profileData, setProfileData] = useState<ProfileDropdownData[]>([]);
    const [selProfile, setSelProfile] = useState<ProfileDropdownData>();

    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false)
    const [reqInfo, setReqInfo] = useState<ReqInfoBar | null>(null);

    const [disableSaveButton, setDisableSaveButton] = useState<boolean | false>(true);
    const [disableValidationButton, setDisableValidationButton] = useState<boolean | false>(true);

    const [resultSaved, setResultSaved] = useState<boolean | false>(false);
    var bResultsValidated: boolean = false;

    // for request dialog
    const ref = useRef<HTMLDivElement>(null);
    const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    const [requestDialogData, setRequestDialogData] = useState<requestDialogData>({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });

    useEffect(() => {
        var reqTemp = { ...requestDialogData, inViewport: inViewport }
        setRequestDialogData(reqTemp);
    }, [inViewport]);

    const callbackRequestDialogClosed = (objRequest: COneRequest | null) => {
        setRequestDialogData({ reqid: '', pid: '', fid: '', bloodProduct: false, showDialog: false, inViewport: inViewport, extcall: false });
        if (selProfile !== undefined) GetResultData(selProfile);
    };

    const callbackReqInfo = async (objReqInfo: ReqInfoBar) => {
        setReqInfo(objReqInfo);
    };

    const adjustGridSize = () => {
        var rootHeight = 900;
        var buttonHeight = 0;
        var reqInfoBarHeight = 0;
        var gridHt = 0;

        /*console.log('refs ', ref_divButtons, ref_divReqInfoBar, ref_divGrid);*/

        if (ref_divButtons !== undefined) buttonHeight = Number(ref_divButtons.current?.clientHeight);
        if (ref_divReqInfoBar !== undefined) reqInfoBarHeight = Number(ref_divReqInfoBar.current?.clientHeight);
        //console.log('buttonHeight', buttonHeight);
        //console.log('reqInfoBarHeight', reqInfoBarHeight);
        gridHt = rootHeight - (buttonHeight + reqInfoBarHeight + 140);
        /*console.log('gridHeight new old ', gridHt, ref_divGrid.current?.style.height);*/
        if (ref_divGrid !== null && ref_divGrid.current)
            ref_divGrid.current.style.height = gridHt.toString() + 'px';
    };

    useEffect(() => {
        if (props.showDialog) adjustGridSize();
    }, [resultData]);

    const ValidateClick = async () => {
        var bRefreshGrid: boolean = false;
        if (VerifyData() === true) {
            setIsLoading(true);
            if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'urinesticks/validaterequestresultdata',
                JSON.stringify({
                    listResEntry: resultData, sQCMaxTimeWarningParams: resultMainData?.sQCMaxTimeWarningParams, sParamsInGrid: resultMainData?.sParamsInGrid,
                    collPrevalResult: resultMainData?.collPrevalResult, collResultCodeComments: resultMainData?.collResultCodeComments,
                    collRatioValLevel: resultMainData?.collRatioValLevel, collParamUnderProfile: resultMainData?.collParamUnderProfile,
                    bQualResultColVisible: resultMainData?.bQualResultColVisible, collValidatedParams: resultMainData?.collValidatedParams,
                    sProfileID: selProfile?.code, sUserID: UserSession.getLoggedInUser().UserID, sValType: resultMainData?.sValType, bRefreshGrid: false, sErrorMsg: ""
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            // console.log(response?.data);
            var maindata: CResultEntryOutput | undefined;
            var binddata: CResultEntry[] = [];
            if (response?.data !== null) maindata = response?.data;
            if (maindata !== undefined) {
                if (maindata.sErrorMsg !== "") {
                    alert(maindata.sErrorMsg);
                } else {
                    setDisableSaveButton(true);
                    setDisableValidationButton(true);

                    bRefreshGrid = maindata.bRefreshGrid;
                    maindata.listResEntry.forEach((o: CResultEntry) => {
                        if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                        if (o.sParamStatus === "3") setDisableValidationButton(false);
                        binddata.push({ ...o });
                    });
                    setResultData(binddata);
                    bResultsValidated = true;
                    //mainResultData = binddata;
                };
            };

            setIsLoading(false);
        };

        if (bRefreshGrid === true) {
            CancelClick();
        } else {
            GetProfiles();
        };
    };

    function VerifyData() {
        var bok: boolean = true;
        //var obj: CResultEntry;
        //for (obj of resultData) {

        //}
        return bok;
    };

    const SaveClick = async (sMode: string) => {
        const binddata: CResultEntry[] = [...resultData];
        var obj: CResultEntry;
        var sAddnURL: string;
        if (sMode === 'S') {
            sAddnURL = 'urinesticks/saverequestresultdata';
        } else {
            sAddnURL = 'urinesticks/rerunparam';
        };
        for (obj of binddata) {
            if (obj.sRawResult === "" && obj.bDisableResultEntry === false) {
                alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                return;
            };
        };
        setIsLoading(true);
        if (resultMainData !== undefined) resultMainData.listResEntry = resultData;
        const response = await axios.post(UserSession.getSystemSettings().API_URL + sAddnURL,
            JSON.stringify({
                listResEntry: resultData, sQCMaxTimeWarningParams: resultMainData?.sQCMaxTimeWarningParams, sParamsInGrid: resultMainData?.sParamsInGrid,
                collPrevalResult: resultMainData?.collPrevalResult, collResultCodeComments: resultMainData?.collResultCodeComments,
                collRatioValLevel: resultMainData?.collRatioValLevel, collParamUnderProfile: resultMainData?.collParamUnderProfile,
                bQualResultColVisible: resultMainData?.bQualResultColVisible, collValidatedParams: resultMainData?.collValidatedParams,
                sProfileID: selProfile?.code, sUserID: UserSession.getLoggedInUser().UserID, sErrorMsg: ""
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var maindata: CResultEntryOutput | undefined;
        if (response?.data !== null) maindata = response?.data;
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                alert(maindata.sErrorMsg);
            } else {
                setDisableSaveButton(true);
                setDisableValidationButton(true);
                binddata.length = 0;
                maindata.listResEntry.forEach((o: CResultEntry) => {
                    if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    if (o.sParamStatus === "3") setDisableValidationButton(false);
                    binddata.push({ ...o });
                });
                setResultData(binddata);
            };
        };

        setIsLoading(false);
    };

    const CancelClick = async () => {
        var bok: boolean = false;
        console.log('resultSaved', resultSaved);
        console.log('bResultsValidated', bResultsValidated);
        if (resultSaved === true || bResultsValidated === true) bok = true;
        props.ResultSaved(bok, props.reqid, props.valtype);
        setVisible(false);
    };

    const SetSelProfile = (event: DropDownListChangeEvent) => {
        setSelProfile(event.value);
        GetResultData(event.value);
    };

    const ProfileClick = (code: string) => {
        if (code !== selProfile?.code) {
            var arr: CResultEntry[] = []
            setResultData(arr);
            profileData.forEach((obj: ProfileDropdownData) => {
                if (obj.code === code) {
                    setSelProfile(obj);
                    GetResultData(obj);
                    obj.color = "inverse"
                } else obj.color = "primary";
            });
        };
    };

    const CustomCellEditReRun = (props: GridCellProps) => {
        const objRE: CResultEntry = props.dataItem;
        if (objRE.bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        var disabled: boolean = true;
        if (Number(objRE.sParamStatus) > 2) disabled = false;
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button disabled={disabled} title={mui(userRights, "common", "ttRerunParam", "Rerun Param")} className="buttons-container-button" icon="refresh"
                    onClick={() => {
                        SaveClick("RR")
                    }}
                >
                </Button>
            </td>
        );
    };

    const CustomCellEditParam = (props: GridCellProps) => {
        const paramid = props.dataItem['sParamID'];
        const paramdesc = props.dataItem['sParamDesc'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
        return (
            <td style={{ fontWeight: bDisableResultEntry === true ? 'bold' : '' }} title={paramid}>
                {paramdesc}
            </td>
        );
    };

    const CustomCellPreVal1 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal1'];
        const tttext = props.dataItem['sPreVal1Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellPreVal2 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal2'];
        const tttext = props.dataItem['sPreVal2Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellPreVal3 = (props: GridCellProps) => {
        const value = props.dataItem['sPreVal3'];
        const tttext = props.dataItem['sPreVal3Tooltip'];
        return (
            <td title={tttext}>
                {value}
            </td>
        );
    };

    const CustomCellEditResultSel = (propsG: GridCellProps) => {
        const status = propsG.dataItem['sParamStatus'];
        const bDisableResultEntry = propsG.dataItem['bDisableResultEntry'];
        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };
        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResSelClick(propsG.dataItem, 'open') }}
                    disabled={(status === '1' || status === '2') ? false : true}
                >
                    ...
                </Button>

            </td>
        );
    };

    const onResSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                console.log('objRE', objRE);
                objRE.sRawResult = Code;
                if (Code.toUpperCase() === "FT") {
                    objRE.sResultText = Desc;
                } else {
                    objRE.sResultText = "";
                };
                ValidateResult(objRE, false);
            }
            setResultSelDialogData(dlgEmptyState);
        }
        //if (action === 'open')
        //    setBackdrop('');
        //else
        //    setBackdrop('none');
        setResultSelDialogData({ selType: "Result", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const ValidateResult = async (objRE: CResultEntry, bQualResult: boolean) => {
        const binddata: CResultEntry[] = [...resultData];

        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'urinesticks/validateresult',
            JSON.stringify({
                objResultEntry: objRE, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: bQualResult
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var objREO = response?.data
            if (objREO.bInvalidCode === true) alert(mui(userRights, "common", "msgInvalidCode", "Invalid Code!"));
            for (let i = 0; i < binddata.length; i++) {
                if (binddata[i].sParamID === objREO.sParamID && binddata[i].bDisableResultEntry === false) {
                    binddata[i] = JSON.parse(JSON.stringify(objREO));
                    break;
                };
            };
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    const CustomCellEditInterp = (props: GridCellProps) => {
        const desc = props.dataItem['sInterpretationDesc'];
        const color = props.dataItem['sResultColColor'];
        return (
            <td style={{ backgroundColor: color }} title={desc}>
                {desc}
            </td>
        );
    };

    const CustomCellEditResultComm = (props: GridCellProps) => {
        const status = props.dataItem['sParamStatus'];
        const bDisableResultEntry = props.dataItem['bDisableResultEntry'];
        if (bDisableResultEntry === true) {
            return (
                <td></td>
            );
        };

        return (
            <td>
                <Button
                    type={"button"}
                    style={{ textAlign: 'center', width: '25px' }}
                    themeColor={'primary'}
                    onClick={() => { onResCommSelClick(props.dataItem, 'open') }}
                    disabled={(status === '1' || status === '2') ? false : true}
                >
                    ...
                </Button>

            </td>
        );
    };

    const onResCommSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                const binddata: CResultEntry[] = [...resultData];
                var obj: CResultEntry;
                for (obj of binddata) {
                    if (obj.sParamID === objRE.sParamID && obj.bDisableResultEntry === false) {
                        obj.sResultComm = Code;
                        if (Code.toUpperCase() === "FT") {
                            objRE.sCommText = Desc;
                        } else {
                            objRE.sCommText = "";
                        };
                        break;
                    }
                }
                setResultData(binddata);
            }
            setResultSelDialogData(dlgEmptyState);
        };
        setResultSelDialogData({ selType: "ResultComm", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const GetProfiles = async () => {
        //setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/getprofiles',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var binddata: ProfileDropdownData[] = response?.data;
            var obj: ProfileDropdownData;
            setProfileData(binddata);
            for (obj of binddata) {
                if (obj.selected === true) {
                    obj.color = "inverse";
                    setSelProfile(obj);
                    GetResultData(obj);
                };
            };
        };
        //setIsLoading(false);
    };

    const GetResultData = async (obj: ProfileDropdownData) => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'urinesticks/geturinsticksdata',
            JSON.stringify({
                mandantid: props.mandantid, reqid: props.reqid, pid: props.pid, fid: props.fid, reqdatetime: props.reqdatetime, reqprovider: props.reqprovider,
                valtype: props.valtype, addedparams: props.addedparams, pregnancyweek: props.pregnancyweek, mbyn: props.mbyn, userid: props.userid, obj: obj
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: CResultEntry[] = [];
        setDisableSaveButton(true);
        setDisableValidationButton(true);
        if (response?.data !== null) {
            setResultMainData(response?.data);
            response?.data.listResEntry.forEach((o: CResultEntry) => {
                if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                if (o.sParamStatus === "3") setDisableValidationButton(false);
                binddata.push({ ...o });
            });
            console.log('binddata', binddata);
            setResultData(binddata);
        };
        setIsLoading(false);
    };

    useEffect(() => {
        GetProfiles();
        setVisible(props.showDialog);
    }, [props.showDialog])

    var rootHeight = 900;
    var buttonHeight = 0;
    var reqInfoBarHeight = 0;
    var gridHt = 0;

    /*console.log('refs ', ref_divButtons, ref_divReqInfoBar, ref_divGrid);*/

    if (ref_divButtons !== undefined) buttonHeight = Number(ref_divButtons.current?.clientHeight);
    if (ref_divReqInfoBar !== undefined) reqInfoBarHeight = Number(ref_divReqInfoBar.current?.clientHeight);
    //console.log('buttonHeight', buttonHeight);
    //console.log('reqInfoBarHeight', reqInfoBarHeight);
    gridHt = rootHeight - (buttonHeight + reqInfoBarHeight + 140);

    return (
        <>
            <div>
                {visible && (
                    <div className="container-fluid" style={{ overflowX: 'hidden', width: '97%', marginLeft: 10, marginRight: 1 }}>
                        <div ref={ref_divButtons} className="row">
                            <div className="col" style={{ textAlign: "right" }}>
                                <Button disabled={disableValidationButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    ValidateClick()
                                }}>{mui(userRights, "common", "cmdValidate", "Validate")}</Button>&nbsp;
                                <Button disabled={disableSaveButton} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                    SaveClick("S")
                                }}>{mui(userRights, "common", "cmdSave", "Save")}</Button>&nbsp;
                                {props.dayWorkSpace === false ?
                                    <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                        CancelClick()
                                    }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                    :
                                    ""
                                }
                            </div>
                        </div>
                        <div className="row" style={{ height: 1 }}></div>
                        <div ref={ref_divReqInfoBar} className="row">
                            <RequestInfoBar reqid={props.reqid} valtype={props.valtype} showmatdata={true} setSelectedReqInfo={callbackReqInfo}></RequestInfoBar>
                        </div>
                        <div className="row" style={{ paddingBottom: 10 }}>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child"><Label editorId={'profileid'}>{mui(userRights, "common", "ProfileID", "ProfileID")}</Label></div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="vertical-wrapper">
                                    <div className="vertical-wrapper-child">
                                        <tr>
                                            {
                                                profileData !== null && profileData.map((obj: ProfileDropdownData) => (
                                                    <td>
                                                        <Button type={"button"} style={{ width: '150px', marginRight: 10 }} themeColor={obj.color} onClick={() => {
                                                            ProfileClick(obj.code)
                                                        }}>{obj.code}</Button>
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                        {/*<DropDownList*/}
                                        {/*    data={profileData}*/}
                                        {/*    textField="code"*/}
                                        {/*    id="code"*/}
                                        {/*    value={selProfile}*/}
                                        {/*    onChange={SetSelProfile}                                                */}
                                        {/*/>&nbsp;                                            */}
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>
                        <div ref={ref_divGrid} className="row">
                            <div className="col">
                                {isLoading ? <ProgressBar message=""></ProgressBar> :
                                <GridContext.Provider
                                    value={{ setResultData, resultData, resultMainData }}
                                >
                                    
                                    <Grid
                                        style={{
                                            height: gridHt, width: '100%', marginLeft: 2, marginRight: 0
                                        }}
                                        data={resultData}
                                        dataItemKey={"sGridKey"}
                                        editField="inEdit"
                                    >
                                        <GridColumn field="" title={mui(userRights, "UrineSticksResultAndValidation", "col_btnReRun", "")} width="50px" cell={CustomCellEditReRun} />
                                        <GridColumn field="sParamDesc" title={mui(userRights, "UrineSticksResultAndValidation", "col_ParamDesc", "Param")} width="120px" cell={CustomCellEditParam} />
                                        <GridColumn field="sPreVal1" title={mui(userRights, "UrineSticksResultAndValidation", "col_PreVal1", "PreVal1")} cell={CustomCellPreVal1} />
                                        <GridColumn field="sPreVal2" title={mui(userRights, "UrineSticksResultAndValidation", "col_PreVal2", "PreVal2")} cell={CustomCellPreVal2} />
                                        <GridColumn field="sPreVal3" title={mui(userRights, "UrineSticksResultAndValidation", "col_PreVal3", "PreVal3")} cell={CustomCellPreVal3} />
                                        <GridColumn field="sRawResult" title={mui(userRights, "UrineSticksResultAndValidation", "col_Result", "Result")} width="125px" cell={GridDataCellNumResult} />
                                        <GridColumn field="" title={mui(userRights, "UrineSticksResultAndValidation", "col_ResultSel", " ")} width="50px" cell={CustomCellEditResultSel} />
                                        <GridColumn field="sInterpretationDesc" title={mui(userRights, "UrineSticksResultAndValidation", "col_Interpretation", "Interpretation")} width="200px" cell={CustomCellEditInterp} />
                                        {/*<GridColumn field="sResultColColor" title={mui(userRights, "UrineSticksResultAndValidation", "col_ResColor", " ")} width="50px" cell={CustomCellEditResultColor} />*/}
                                        <GridColumn field="sResultComm" title={mui(userRights, "UrineSticksResultAndValidation", "col_ResultComm", "Result Comm")} width="100px" cell={GridDataCellResultComm} />
                                        <GridColumn field="" title={mui(userRights, "UrineSticksResultAndValidation", "col_ResultCommSel", " ")} width="50px" cell={CustomCellEditResultComm} />
                                        <GridColumn field="sRefValue" title={mui(userRights, "UrineSticksResultAndValidation", "col_RefValue", "Ref Value")} width="90px" />
                                        <GridColumn field="sUnit" title={mui(userRights, "UrineSticksResultAndValidation", "col_Unit", "Unit")} />
                                        );
                                    </Grid>
                                    </GridContext.Provider>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <Suspense fallback={<Loader />}>
                <ResultSelDialog selType={resultSelDialogData.selType} objRE={resultSelDialogData.objRE} selqualresult={resultSelDialogData.selqualresult} showDialog={resultSelDialogData.showDialog}
                    CodeSelected={resultSelDialogData.CodeSelected}></ResultSelDialog>
                <RequestDialog key={'R'} data={requestDialogData} callbackRequestDialogClosed={callbackRequestDialogClosed}></RequestDialog>
            </Suspense>
        </>
    );
}
export default UrineSticksResAndValComponent;