import { useLocation } from "react-router-dom";
import { CacheOrderProvider, extendedLog, performLogin, saveToStorage } from "../../Helper";
import ErrorPage from "./ErrorPage";
import IHValidation from "../ResultAndValidation/IHValidation";
import { CUserRightsAppl, SystemUser } from "../../types";
import Splash from "../Misc/Splash";
import UserSession from "../UserSession";
import { useEffect, useState} from "react";
import IHValidationComponent from "../ResultAndValidation/IHValidationComponent";
import DayWorkspace from "../Dashboard/DayWorkspace";
import ComponentMove from "../Dashboard/ComponentMove";

const Invoke = (props: any) => {
    const [loading, setLoading] = useState(true);
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'login' === ura.sFormName;
        }) : null;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    var page: string | null = searchParams.get('page');
    var mandantid: string | null = searchParams.get('mandantid');
    var reqid: string | null = searchParams.get('reqid');
    var pid: string | null = searchParams.get('pid');
    var fid: string | null = searchParams.get('fid');
    var paramid: string | null = searchParams.get('paramid');
    var reqDateTime: string | null = searchParams.get('reqdatetime');
    var reqProvider: string | null = searchParams.get('reqprovider');
    var valType: string | null = searchParams.get('valtype');
    var addedParams: string | null = searchParams.get('addedparams');
    var pregnancyWeek: string | null = searchParams.get('pregnancyweek');
    var mbYN: string | null = searchParams.get('mbyn');
    var userid: string | null = searchParams.get('userid');

    extendedLog('calling page ' + page + ', ' + mandantid + ', ' + reqid + ', ' + pid + ', ' + fid + ', ' + paramid + ', ' + reqDateTime + ', ' + reqProvider + ', ' + valType + ', ' + addedParams + ', ' + pregnancyWeek + ', ' + mbYN + ', ' + userid);

    useEffect(() => {
        // Perform login and set session objects
        const doLogin = async () => {
            const user: SystemUser = await performLogin("", "", userid !== null?userid:"");
            if (user.errinfo.success) {
                user.CurrentMandant = user.DefMandant;
                user.CurrentMandantDesc = user.DefMandantDesc;
                UserSession.setLoggedInUser(user);
                if (user.Language.toLowerCase() === "de") {
                    globalThis.locale = "de-DE";
                }
                else if (user.Language.toLowerCase() === "it") {
                    globalThis.locale = "it-IT";
                }
                else if (user.Language.toLowerCase() === "fr") {
                    globalThis.locale = "fr-FR";
                }
                else
                    globalThis.locale = "en-GB";
                await CacheOrderProvider();
            }
            else {
                await saveToStorage("orderProvider", "");
            }
            setLoading(false);
        };
        doLogin();
    }, [])

    
    return (
        <>
            {loading === false && page === null && <ErrorPage page={"<empty"}></ErrorPage>}
            {loading === false && page === 'ihvalidation' && <div style={{ margin: 10 }}>
                <DayWorkspace loadfor='' mandantChanged={mandantid !== null ? Number(mandantid) : 0}></DayWorkspace>
            </div>}  
            {loading === false && page === 'compmoveloc' && <div style={{ margin: 10 }}>
                <ComponentMove loadfor='invoke' mandantChanged={mandantid !== null ? Number(mandantid) : 0}></ComponentMove>
            </div>}
        </>
    );
}

export default Invoke;