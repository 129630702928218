import React, { useEffect, useState } from 'react'
import axios from 'axios'
import UserSession from '../UserSession';
import { ctDataType } from '../../types';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { ListBox, ListBoxItemClickEvent } from '@progress/kendo-react-listbox';

interface CodeTableSelProps {
    codeTableID: string;
    selCode: string;
    mandantChanged: number;
    setSelectedCT(ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string, dblClick: boolean): any;
    width: string;
    id: string;
    supplParam1: string;
    supplParam2: string;
    disabled?: boolean;
    allowResults: string;
}

export const CodeTableSelList = (props: CodeTableSelProps) => {    
    // Default empty selected CT state
    var ctData = {
        sCodeTableID: "",
        sMandantID: "",
        sCode: "",
        sAbbrDevelopment: "",
        sDescDevelopment: "",
        sMemoDevelopment: "",
        sLanguage: "",
        sSuppl1: "",
        sSuppl2: "",
        sSuppl3: "",
        sUserGroup: ""
    };

    const [selected, setSelected] = useState(ctData);

    // Array of CT data for the specified CT
    const [CodeTableData, setCodeTableData] = useState<ctDataType[]>([]);

    async function GetCodeTableData() {

        const GETREQUESTINFOBAR_URL = UserSession.getSystemSettings().API_URL + 'codetablesel/getcodetabledata';

        const response = await axios.post(GETREQUESTINFOBAR_URL,
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "codeTableID": props.codeTableID, allowResults: props.allowResults, }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: ctDataType[] = [];
        //binddata.push({ DisplayString: "", sCodeTableID: "", sMandantID: "", sCode: "", sAbbrDevelopment: "", sDescDevelopment: "", sMemoDevelopment: "", sLanguage: "", sSuppl1: "", sSuppl2: "", sSuppl3: "", sUserGroup: "", selected: false });
        if (response?.data !== null)
            response?.data.forEach((o: ctDataType) => {
                binddata.push({
                    DisplayString: o.sCode + ' ' + o.sDescDevelopment, sCodeTableID: o.sCodeTableID,
                    sMandantID: o.sMandantID, sCode: o.sCode, sAbbrDevelopment: o.sAbbrDevelopment,
                    sDescDevelopment: o.sDescDevelopment === "" ? o.sAbbrDevelopment : o.sDescDevelopment,
                    sMemoDevelopment: o.sMemoDevelopment, sLanguage: o.sLanguage, sSuppl1: o.sSuppl1, sSuppl2: o.sSuppl2, sSuppl3: o.sSuppl3, sUserGroup: o.sUserGroup, selected: o.selected
                });
            });
        if (props.selCode !== "") {
            binddata.forEach((o: ctDataType) => {
                if (o.sCode === props.selCode) {
                    o.selected = true
                    setSelected(o)
                }
            });
        }

        setCodeTableData(binddata);
      
    }

    useEffect(() => {
        // console.log('CodeTableSel UE: props.mandantChanged');
        GetCodeTableData();
        //console.log("ue", props)
    }, [props.mandantChanged])

    useEffect(() => {
        if (CodeTableData === null || CodeTableData === undefined) return;
        let selCode: ctDataType | undefined = CodeTableData.find((item: ctDataType) => item.sCode === props.selCode)
        if (selCode !== undefined) {
            setSelected(selCode);
        }
    }, [props.selCode])

    const SELECTED_FIELD = "selected";
    const lastSelectedIndex = React.useRef(0);
    const [filter, setFilter] = React.useState<FilterDescriptor>();
    const [selectedFields, setSelectedFields] = useState<ctDataType[]>([]);  
    const clickTimeout = React.useRef<NodeJS.Timeout | null>(null);

    const handleItemClick = (event: any, dataItem: any, allData: ctDataType[], selData: ctDataType[]) => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
        }
        clickTimeout.current = setTimeout(() => {

            //console.log("handleItemClick", event, allData, selData)
            const newData: ctDataType[] = allData.map((item: ctDataType) => {
                if (item.sCode === dataItem.sCode) {
                    item[SELECTED_FIELD] = !item[SELECTED_FIELD];
                } else if (!event.nativeEvent.ctrlKey) {
                    item[SELECTED_FIELD] = false;
                }
                return item;
            });
            if (selData.length > 0) {
                const chosenData: ctDataType[] = selData.map((item: ctDataType) => {
                    //console.log ("log chosen data", item,dataItem)
                    if (item.sCode === dataItem.sCode) {
                        item[SELECTED_FIELD] = !item[SELECTED_FIELD];
                    } else if (!event.nativeEvent.ctrlKey) {
                        item[SELECTED_FIELD] = false;
                    }
                    return item;
                });

                chosenData.forEach((item) => {
                    if (item.sCode === dataItem.sCode) {

                    } else {
                        item.selected = false
                    }
                    return item;
                });

                setCodeTableData(chosenData);

            }
            
            //const current = newData.findIndex(
            //    (item) => item === dataItem
            //);
            //lastSelectedIndex.current = current;


            //console.log("set data", props.codeTableID, props.id, dataItem, props.supplParam1, props.supplParam2)
            props.setSelectedCT(props.codeTableID, props.id, dataItem, props.supplParam1, props.supplParam2, false);
            setSelected(dataItem);

        })

    };

    const handleDoubleClick = (event: any, dataItem: any, allData: ctDataType[], selData: ctDataType[]) => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
        }

        const newData = allData.filter(
            (item) => item.sCode !== dataItem.sCode
        );
        const chosenData = [...selData, dataItem];

        setCodeTableData(chosenData);

        //console.log(event.dataItem, chosenData, newData)
        props.setSelectedCT(props.codeTableID, props.id, dataItem, props.supplParam1, props.supplParam2, true);
        setSelected(dataItem);

    };

    const MyCustomItem = (props: any) => {
        let { dataItem, selected, ...others } = props;
        return (
            <li
                {...others} style={{ width: "100%" }}
                onClick={(e: any) =>
                    handleItemClick(e, dataItem, selectedFields, CodeTableData)
                }

                onDoubleClick={(e: any) =>
                    handleDoubleClick(e,
                        dataItem,
                        selectedFields, CodeTableData
                    )
                }
            >
                <div className="row" style={{ width: "100%" }}>
                    <div className="col-2">{props.dataItem.sCode}</div>
                    <div className="col-10">{props.dataItem.sDescDevelopment}</div>
                </div>

            </li>
        );
    };

    return (
        <>
            <ListBox
                style={{ height: 350, width: props.width }}
                data={filter ? filterBy(CodeTableData, filter) : CodeTableData}
                textField="DisplayString"
                selectedField={SELECTED_FIELD}
                key="sCode"
                item={MyCustomItem}
            /></>
    )

}