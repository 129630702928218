import { Suspense, useEffect, useState } from 'react';
import React from 'react';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './App.scss';
import './bootstrap-grid.min.css';
import './bootstrap-table.css';
import UserSession from './components/UserSession';
import { UserLoggedIn, extendedLog, saveToStorage } from './Helper'
import Login from './components/Login/Login';
import Leena from './components/Leena/Leena';
import Sachin from './components/Sachin/Sachin';
import Mahesh from './components/Mahesh/Mahesh';
import Splash from './components/Misc/Splash';
import SystemFunction from './components/Misc/SystemFunction';
import QCResultList from './components/QCResult/QCResultList';
import { enumSocketCallback, getWorkstationID } from './Twain';
import Invoke from './components/ExternalCall/Invoke';

const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));
const RequestMainParent = React.lazy(() => import('./components/RequestMainParent/RequestMainParent'));
const UserRightsManagement = React.lazy(() => import('./components/UserRightsManagement/UserRightsManagement'));
const CodeTableManagement = React.lazy(() => import('./components/CodeTableManagement/CodeTableManagement'));
const MatInLab = React.lazy(() => import('./components/Dashboard/MatInLab'));
const ResultCriteria = React.lazy(() => import('./components/Dashboard/ResultCriteria'));
const AddnReqParam = React.lazy(() => import('./components/Dashboard/AdditionalRequestParam'));
const MatInLabList = React.lazy(() => import('./components/Dashboard/MatInLabList'));
const ComponentOrderList = React.lazy(() => import('./components/Dashboard/ComponentOrderList'));
const SysParametersManagement = React.lazy(() => import('./components/SysParametersManagement/SysParametersManagement'));
const DayWorkspace = React.lazy(() => import('./components/Dashboard/DayWorkspace'));
const ComponentMove = React.lazy(() => import('./components/Dashboard/ComponentMove'));

function App() {
    const location = useLocation();
    let pageCalled: string = location.pathname;

    const navigate = useNavigate();
    const [initDone, setInitDone] = useState(false);
    let source: any = null;
    //const dlgEmptyState: resultEntryDialog = {
    //    mandantid: "", reqid: "", pid: "", fid: "", paramid: "", reqdatetime: "", reqprovider: "", valtype: "", addedparams: "", pregnancyweek: "", mbyn: "", userid: "", showDialog: false, ResultSaved: () => { }, selprofilecode:""
    //}
    //const [resultEntryDialogData, setResultEntryDialogData] = useState<resultEntryDialog>(dlgEmptyState);

    const loadUserRights = async () => {
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'userrights/getuserrights',
                JSON.stringify({customerid: '2023', formname: '', objectid: '', userid: ''}),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: false,
                    cancelToken: source.token
                }
            );
            globalThis.userRights = response?.data;
            globalThis.defaultLanguage = UserSession.getSystemSettings().websiteLanguage;
            if (globalThis.defaultLanguage === "de") {
                globalThis.locale = "de-DE";
            }
            else if (globalThis.defaultLanguage === "it") {
                globalThis.locale = "it-IT";
            }
            else if (globalThis.defaultLanguage === "fr") {
                globalThis.locale = "fr-FR";
            }
            else
                globalThis.locale = "en-GB";

        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
    }

    const getWorkstationIDCallback = (source: string, status: enumSocketCallback, data: string) => {
        console.log('getWorkstationIDCallback', source, status, data);
        if (status === "success") {
            saveToStorage("workstationid", data);
        }
        else if (status === "error" || status === "exception")
            saveToStorage("workstationid", '');
    }

    useEffect(() => {
        source = axios.CancelToken.source();

        (async () => {
            extendedLog('reading config file');
            await axios.get('./lisconfig.json?timestamp=' + new Date().getTime(),
                {
                    // query URL without using browser cache
                    headers: {
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    },
                }
            ).then((response: any) => {
                UserSession.setSystemSettings(response.data);
            });
            await loadUserRights();
            setInitDone(true);

            getWorkstationID(getWorkstationIDCallback);

            if (!UserLoggedIn() && pageCalled !== "/invoke") {
                navigate('/login', { replace: true })
            }
        })();

        return () => {
            console.log('Unmount: App');
            if (source) {
                source.cancel("Component got unmounted");
            }
        };

    }, [])

    return (
        <>
            {initDone ? <Suspense fallback={<Splash message=""></Splash>}>
                <Routes>
                    <Route path='/' element={<Login />}></Route>
                    <Route path='/invoke' element={<Invoke />}></Route>
                    <Route path='/requestmainparent' element={<RequestMainParent />}></Route>
                    <Route path='/dashboard' element={<Dashboard />}></Route>
                    <Route path='/login' element={<Login />}></Route>                    
                    <Route path='/leena' element={<Leena />}></Route>
                    <Route path='/sachin' element={<Sachin />}></Route>
                    <Route path='/mahesh' element={<Mahesh />}></Route>
                    <Route path='/matinlab' element={<MatInLab />}></Route>
                    <Route path='/codetablemanagement' element={<CodeTableManagement />}></Route>
                    <Route path='/resultcriteria' element={<ResultCriteria loadfor='' mandantChanged={1} />}></Route>
                    <Route path='/userrightsmanagement' element={<UserRightsManagement />}></Route>
                    <Route path='/systemfunction/:id' element={<SystemFunction />}></Route>
                    <Route path='/additionalrequestparam' element={<AddnReqParam loadfor='' mandantChanged={1} />}></Route>
                    <Route path='/matinlablist' element={<MatInLabList loadfor='' mandantChanged={1} />}></Route>
                    <Route path='/componentorderlist' element={<ComponentOrderList loadfor='' mandantChanged={1} />}></Route>
                    <Route path='/SysParametersManagement' element={<SysParametersManagement />}></Route>
                    <Route path='/componentmove' element={<ComponentMove loadfor='' mandantChanged={1} />}></Route>
                    <Route path='/QCResultList' element={<QCResultList loadFor={0} />}></Route>
                    <Route path='/QCResultListVal' element={<QCResultList loadFor={1} />}></Route>
                    <Route path='/dayworkspace' element={<DayWorkspace loadfor='' mandantChanged={1} />}></Route>
                </Routes>                
            </Suspense> : <Splash message="Eine Komplettlösung für eine effektiveund reibungslose Prozesskette zwischen allen Beteiligten"></Splash>
            }
            
        </>
    );
}
export default App;
