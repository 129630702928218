import { Loader } from "@progress/kendo-react-indicators";
import { useEffect } from "react";

interface ProgressBarParams {
    message: string;
}

const ProgressBar = (props: ProgressBarParams) => {
    useEffect(() => {

        return () => {
        };

    }, [])

    return (
        <div style={{ overflow: 'hidden' }}>
            <div style={{
                position: 'absolute', opacity: '0.6', margin: 'auto', backgroundColor: '#ffffff', border: '0px solid black', top: 0, bottom: 0, left: 0, right: 0, textAlign: 'center', width: '100%', height: '99%'
            }}>
                <div><Loader type="converging-spinner" size='large'></Loader></div>
               
            </div>
        </div>
    );
}

export default ProgressBar;