/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/jsx-no-undef */
import { ChangeEvent, Suspense, useEffect, useState } from "react";
import { formatDate, formatTime, mui } from "../../Helper";
import { CResultEntry, ctDataType, CUserRightsAppl, selCodeDataType, emptyREData, MULTI_SELECT_TABLES, CResultEntryOutput, emptyREO, resultSelDialog, MULTI_SELECT_SEP } from "../../types";
import axios from 'axios'
import { SelectCode } from "../SelectCode/SelectCode";

import CustomDate from "../Misc/CustomDate";
import CustomTime from "../Misc/CustomTime";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";

import MultiSelectList, { multiSelectProps } from "../ModalForms/MultiSelect/MultiSelectList";
import UserSession from "../UserSession";
import ResultSelDialog from "../ResultAndValidation/ResultSelDialog";
import React from "react";
interface resSelDialog {
    bDisableResultEntry: boolean;
    objRE: CResultEntry;
    selType: string;
}
const CustomResultEntry = (props: resSelDialog) => {

    const dlgEmptyState: resultSelDialog = {
        selType: "", objRE: emptyREData, selqualresult: false, showDialog: false, CodeSelected: () => { }
    }
    const [resultSelDialogData, setResultSelDialogData] = useState<resultSelDialog>(dlgEmptyState);

    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'resseldialog' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [resultData, setResultData] = useState<CResultEntry>(emptyREData);

    const [resDate, setResDate] = useState(formatDate(new Date().toDateString()));
    const [resTime, setResTime] = useState(formatTime(new Date().toLocaleString(), true));
    const [selCode, setSelCode] = useState<string | "">("");
    const [selDesc, setSelDesc] = useState<string | "">("");

    const [selDefCode, setSelDefCode] = useState<string | "">("");

      const dlgMSEmptyState: multiSelectProps = {
        multiSelectTable: "",
        selectedValues: "",
        showDialog: false,
        MultiSelectSaved: (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => { }
    }

    const MultiSelectSaved = (multiSelectTable: MULTI_SELECT_TABLES, savedCodes: string, cancel: boolean) => {

        if (cancel !== true) {
            //console.log("callbackMultiSel", savedCodes)

            if (props.objRE.ExistingResult.length > 0) {
                props.objRE.sRecStatus = "CHG"
            }
            else {
                props.objRE.sRecStatus = "UNS"
            }
            props.objRE.sRawResult = "AI"
            props.objRE.sResultText = savedCodes
            setSelCode(savedCodes);
            setSelDesc(savedCodes);
        }

        setMultiSelectData(dlgMSEmptyState);
    }

    const openMS = (multiSelectTable: MULTI_SELECT_TABLES) => {
        var selectedValues: string = "";
        selectedValues = props.objRE.sResultText;
        //console.log("openMS", props.objRE.sResultText)
        setMultiSelectData({
            multiSelectTable: multiSelectTable, showDialog: true,
            selectedValues: selectedValues, MultiSelectSaved: MultiSelectSaved
        });
    };

    const [multiSelectData, setMultiSelectData] = useState<multiSelectProps>(dlgMSEmptyState);

    //new ms

    const handleResChangeDate = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        
        props.objRE.sRawResult = value
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }

        setResDate(value)
        setSelCode(value);
        setSelDesc("");
        console.log('handleResChangeDate', value,props.objRE);
    };

    const handleResChangeTime = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        //console.log('event.value', value);
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        props.objRE.sRawResult = value
        setResTime(value)
        setSelCode(value);
        setSelDesc("");
        console.log('handleResChangeTime', value, props.objRE);
    };

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string) => {
        //console.log("callbackCTSelected", objCT)
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        if (objCT.sCode !== undefined && objCT.sCode !== null) {
            setSelCode(objCT.sCode);
            setSelDesc(objCT.sDescDevelopment);
        } else {
            setSelCode("");
            setSelDesc("");
        }
    };

    const callbackResultSel = async (tableid: string, controlID: string, obj: selCodeDataType, supplParam1: string, supplParam2: string) => {
        //console.log("callbackResultSel", obj)
        //if (props.objRE.ExistingResult.length > 0) {
        //    props.objRE.sRecStatus = "CHG"
        //}
        //else {
        //    props.objRE.sRecStatus = "UNS"
        //}
        //if (obj.code !== undefined && obj.code !== null) {
        //    setSelCode(obj.code);
        //    setSelDesc(obj.desc);

        //} else {
        //    setSelCode("");
        //    setSelDesc("");
        //}
    };

    useEffect(() => {
        setResultData(props.objRE);
        setSelCode("");
        setSelDesc("");
        setSelDefCode(props.objRE.sRawResult);

    }, [])

    const InputKeyUp = (e: any) => {
        console.log("InputKeyUp", e)

    }

    const InputKeyDown = (e: any) => {
        //console.log("InputKeyDown", e)
        if (e.nativeEvent.code === "F2") {

            if (props.selType === "RESULT") {
                if (props.objRE.sResSelTableName === "ResultCode" || props.objRE.sResSelTableName === "PhenoType" || props.objRE.sCodeTableID !== "") {
                    onResSelClick(props.objRE, "open")
                }
                else {
                    openMS("Antibody")
                }
            }

            else {
                onResCommSelClick(props.objRE, "open")
            }

        }
        else {
            console.log("InputKeyDown", e)
            var splitted: string[] = props.objRE.sAllowResults.split(MULTI_SELECT_SEP);
            var i: number = -1

            switch (e.nativeEvent.keyCode) {
                case 48: case 96: {
                    i = 0;
                    //console.log("InputKeyDown 0" )
                    break;
                }
                case 49: case 97: {
                    //console.log("InputKeyDown 1")
                    i = 1;
                    break;
                }
                case 50: case 98: {
                    //console.log("InputKeyDown 2")
                    i = 2;
                    break;
                }
                case 51: case 99: {
                    //console.log("InputKeyDown 3")
                    i = 3;
                    break;
                }
                case 52: case 100: {
                    //console.log("InputKeyDown 4")
                    i = 4;
                    break;
                }
                case 53: case 101: {
                    //console.log("InputKeyDown 5")
                    i = 5;
                    break;
                }
                case 54: case 102: {
                    //console.log("InputKeyDown 6")
                    i = 6;
                    break;
                }
                case 55: case 103: {
                    //console.log("InputKeyDown 7")
                    i = 7;
                    break;
                }
                case 56: case 104: {
                    //console.log("InputKeyDown 8")
                    i = 8;
                    break;
                }
                case 57: case 105: {
                    //console.log("InputKeyDown 9")
                    i = 9;
                    break;
                }
                default: {
                    //statements; 
                    break;
                }
            }
            console.log("InputKeyDown", i, props.objRE.sAllowResults)
            if ((i !== -1) && props.objRE.sAllowResults !== '') {
                console.log("ikd value", splitted[i])

                props.objRE.sRawResult = splitted[i];
                props.objRE.sRecStatus = "CHG"
                setSelDefCode(props.objRE.sRawResult);
                setSelCode(props.objRE.sRawResult);

                //e.nativeEvent.keyCode = 9
                e.preventDefault();
                return false;

            }

        }
    }
    const onResCommSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            if (Code !== "") {
                //console.log('objRE', objRE);
                objRE.sResultComm = Code;
                if (Code.toUpperCase() === "FT") {
                    objRE.sCommText = Desc;
                } else {
                    objRE.sCommText = "";
                };
                /*//ValidateResult(objRE, false);*/
            }
            setResultSelDialogData(dlgEmptyState);

            //document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Tab' })); 
        };
        setResultSelDialogData({ selType: "ResultComm", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const onResSelClick = (objRE: CResultEntry, action: string) => {
        const CodeSel = (Code: string, Desc: string) => {
            console.log('CodeSel', Code, Desc)
            if (Code !== "") {
                //console.log('objRE', objRE);
                objRE.sRawResult = Code;
                if (Code.toUpperCase() === "FT") {
                    objRE.sResultText = Desc;
                } else {
                    objRE.sResultText = "";
                };
                /*//ValidateResult(objRE, false);*/
            }
            setResultSelDialogData(dlgEmptyState);

            //document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Tab' })); 
        }

        setResultSelDialogData({ selType: "Result", objRE: objRE, selqualresult: false, showDialog: action === 'open' ? true : false, CodeSelected: CodeSel });
    };

    const onBlur = async (e: any) => {

        var resultMainData: CResultEntryOutput = emptyREO;
        var obj: CResultEntry = emptyREData;
        var Msg: string = "";

        if (props.selType === "RESULT") {

            //console.log("onBlur CRE  - ", e.target.value)
            setSelDefCode(e.target.value);

            if (props.objRE !== undefined) {
                obj = props.objRE
                obj.sRawResult = e.target.value

                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
            }
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
                JSON.stringify({
                    objREO: resultMainData, objResultEntry: obj, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: false
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            if (response?.data !== null) {
                console.log("onBlur CRE response - ", response?.data)
                var objRO = response?.data

                Msg = objRO.objREO.sErrorMsg;
                if (Msg === undefined) Msg = '';
                if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                if (objRO.objResultEntry.bInvalidCode === true) {
                    console.log("onBlur CRE InvalidCode - ")
                    alert(Msg);
                    setSelCode("");
                    setSelDesc("");
                    props.objRE.sRawResult = ""


                }
                else {
                    console.log("onBlur CRE validCode 1 - ")
                    if (props.objRE.sParamID === objRO.objResultEntry.sParamID && props.objRE.bDisableResultEntry === false) {
                        console.log("onBlur CRE validCode 2 - ", objRO.objResultEntry.sInterpretationCode)
                        setResultData(JSON.parse(JSON.stringify(objRO.objResultEntry)));
                        //setSelDefCode(objRO.objResultEntry.sRawResult);
                        setSelCode(objRO.objResultEntry.sInterpretationCode);
                        setSelDesc(objRO.objResultEntry.sInterpretationDesc);
                        props.objRE.sRawResult = objRO.objResultEntry.sInterpretationCode

                    };
                }


            };


        }
        else {

            //console.log("onBlur CRE  - ", e.target.value)
            setSelDefCode(e.target.value);

            if (props.objRE !== undefined) {
                obj = props.objRE
                obj.sResultComm = e.target.value;

                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
            }
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/validateresultcomm',
                JSON.stringify({
                    mandantid: obj.sMandantID, resultcomm: obj.sResultComm, desc: "", binvalidcode: false
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            if (response?.data !== null) {
                console.log("onBlur ResultComm CRE response - ", response?.data)
                var objRO = response?.data

                if (Msg === undefined) Msg = '';
                if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                if (objRO.binvalidcode === true) {
                    console.log("onBlur CRE InvalidCode - ")
                    alert(Msg);
                    setSelCode("");
                    setSelDesc("");
                    props.objRE.sResultComm = ""


                }
                else {
                    console.log("onBlur CRE validCode 1 - ")
                    console.log("onBlur CRE validCode 2 - ", objRO.resultcomm)
                    //setResultData(JSON.parse(JSON.stringify(objRO.objResultEntry)));
                    //setSelDefCode(objRO.objResultEntry.sRawResult);
                    setSelCode(objRO.resultcomm);
                    setSelDesc(objRO.desc);
                    props.objRE.sResultComm = objRO.resultcomm
                }


            };

        }


    };

    const handleChange = (e: any) => {
        setSelDefCode(e.target.value);
        setSelCode(e.target.value);
        props.selType === "RESULT" ? props.objRE.sRawResult = e.target.value : props.objRE.sResultComm = e.target.value
    }

    const handleResChangeTimeSecond = (value: string, source: string, supplParam1?: string, supplParam2?: string) => {
        //console.log('event.value', value);
        if (props.objRE.ExistingResult.length > 0) {
            props.objRE.sRecStatus = "CHG"
        }
        else {
            props.objRE.sRecStatus = "UNS"
        }
        props.objRE.sCompType = value
        setResTime(value)
        setSelCode(value);
        setSelDesc("");

    };

    const onBlurSecond = async (e: any) => {

        var resultMainData: CResultEntryOutput = emptyREO;
        var obj: CResultEntry = emptyREData;
        var Msg: string = "";

        if (props.selType === "RESULT") {

            //console.log("onBlur CRE  - ", e.target.value)
            setSelDefCode(e.target.value);

            if (props.objRE !== undefined) {
                obj = props.objRE
                obj.sCompType = e.target.value

                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }
            }
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
                JSON.stringify({
                    objREO: resultMainData, objResultEntry: obj, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: false
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );

            if (response?.data !== null) {
                console.log("onBlur CRE response - ", response?.data)
                var objRO = response?.data

                Msg = objRO.objREO.sErrorMsg;
                if (Msg === undefined) Msg = '';
                if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");

                if (objRO.objResultEntry.bInvalidCode === true) {
                    console.log("onBlur CRE InvalidCode - ")
                    alert(Msg);
                    setSelCode("");
                    setSelDesc("");
                    props.objRE.sCompType = ""


                }
                else {
                    console.log("onBlur CRE validCode 1 - ")
                    if (props.objRE.sParamID === objRO.objResultEntry.sParamID && props.objRE.bDisableResultEntry === false) {
                        console.log("onBlur CRE validCode 2 - ", objRO.objResultEntry.sInterpretationCode)
                        setResultData(JSON.parse(JSON.stringify(objRO.objResultEntry)));
                        //setSelDefCode(objRO.objResultEntry.sRawResult);
                        setSelCode(objRO.objResultEntry.sInterpretationCode);
                        setSelDesc(objRO.objResultEntry.sInterpretationDesc);
                        props.objRE.sCompType = objRO.objResultEntry.sInterpretationCode

                    };
                }


            };


        }

    };

    const handleChangeSecond = (e: any) => {
        setSelDefCode(e.target.value);
        setSelCode(e.target.value);
        props.objRE.sCompType = e.target.value
    }
    const elemRefs = [];
    const ref = React.createRef();
    elemRefs.push(ref);
    return (
        <>

            <div className="container-fluid" style={{ overflowX: 'hidden', width: '90%' }}>
                <div className="row">

                    <div className="col-12">
                        {

                            props.selType === "RESULT" ?

                                ((props.objRE.sResSelTableName === "Date") && (props.objRE.sResultType !== "RD")) ?
                                    (<CustomDate objRE={props.objRE} dateChanged={handleResChangeDate} onlyTextBox={true} width="100%" defaulValue={props.objRE.sRawResult} id={'txtReqDate'} source={'selDefCode'} label="" supplParam1={props.objRE.sParamID} supplParam2=""
                                        required={false} disabled={props.bDisableResultEntry}></CustomDate>)
                                    :
                                    props.objRE.sResSelTableName === "Time" ?
                                        (<CustomTime objRE={props.objRE} allowSecond={true} supplParam1={props.objRE.sParamID} supplParam2="" timeChanged={handleResChangeTime} width="100%" defaulValue={props.objRE.sRawResult} id={'ResTime'} source={'selDefCode'} label="" required={false} disabled={props.bDisableResultEntry}></CustomTime>)
                                        :
                                        props.objRE.sCodeTableID !== "" || props.objRE.sResSelTableName === "ResultCode" || props.objRE.sResSelTableName === "PhenoType" ?
                                            <Input
                                                disabled={props.bDisableResultEntry}
                                                value={props.objRE.sRawResult}
                                                onChange={handleChange}
                                                onBlurCapture={onBlur}
                                                style={{ textAlign: 'center' }}
                                                width="100%"
                                                onKeyDown={InputKeyDown}
                                                onKeyUp={InputKeyUp}
                                                className="block"
                                                ref={ref}
                                            />
                                            :
                                            (props.objRE.sResSelTableName === "Antibodies") ?
                                                <Input
                                                    disabled={props.bDisableResultEntry}
                                                    onKeyDown={InputKeyDown}
                                                    onKeyUp={InputKeyUp}
                                                    title={props.objRE.sResultText}
                                                    readOnly
                                                    defaultValue={props.objRE.sResultText} value={props.objRE.sResultText}
                                                    width="100%"
                                                    className="block"
                                                    ref={ref}
                                                />
                                                :
                                                (props.objRE.sResultType === "BC") ?
                                                    <>

                                                        <div className="row">

                                                            <div className="col-8">
                                                                <Input
                                                                    disabled={props.bDisableResultEntry}
                                                                    onBlur={onBlur}
                                                                    onChange={handleChange} defaultValue={props.objRE.sRawResult} value={props.objRE.sRawResult}
                                                                    width="100%"
                                                                    className="block"
                                                                    ref={ref}
                                                                />
                                                            </div>
                                                            <div className="col-4">
                                                                <Input
                                                                    disabled={props.bDisableResultEntry}
                                                                    onBlur={onBlurSecond}
                                                                    onChange={handleChangeSecond} defaultValue={props.objRE.sCompType} value={props.objRE.sCompType}
                                                                    width="100%"
                                                                    className="block"
                                                                    ref={ref}
                                                                />
                                                            </div>
                                                        </div>


                                                    </>
                                                    : (props.objRE.sResultType === "RD") ?
                                                        <>
                                                            <div className="row">

                                                                <div className="col-8">
                                                                    <CustomDate objRE={props.objRE} dateChanged={handleResChangeDate} onlyTextBox={true} width="100%" defaulValue={props.objRE.sRawResult} id={'txtReqDate'} source={'selDefCode'} label="" supplParam1={props.objRE.sParamID} supplParam2=""
                                                                        required={false} disabled={props.bDisableResultEntry}></CustomDate>
                                                                </div>
                                                                <div className="col-4">
                                                                    <CustomTime objRE={props.objRE} allowSecond={false} supplParam1={props.objRE.sParamID} supplParam2="2" timeChanged={handleResChangeTimeSecond} width="100%" defaulValue={props.objRE.sCompType} id={'ResTime'} source={'selDefCode'} label="" required={false} disabled={props.bDisableResultEntry}></CustomTime>
                                                                </div>
                                                            </div>


                                                        </>
                                                        :
                                                        <Input
                                                            disabled={props.bDisableResultEntry}
                                                            onBlur={onBlur}
                                                            onChange={handleChange} defaultValue={props.objRE.sRawResult} value={props.objRE.sRawResult}
                                                            style={{ textAlign: 'center' }}
                                                            width="100%"
                                                            onKeyUp={InputKeyUp}
                                                            onKeyDown={InputKeyDown}
                                                            className="block"
                                                            ref={ref}
                                                        />
                                :

                                <Input
                                    value={props.objRE.sResultComm}
                                    onChange={handleChange}
                                    onBlurCapture={onBlur}
                                    onKeyDown={InputKeyDown}
                                    onKeyUp={InputKeyUp}
                                    style={{ textAlign: 'center' }}
                                    width="100%"
                                />


                        }

                    </div>

                </div>
            </div>
            <Suspense fallback={<Loader />}>
                <ResultSelDialog selType={resultSelDialogData.selType} objRE={resultSelDialogData.objRE} selqualresult={resultSelDialogData.selqualresult} showDialog={resultSelDialogData.showDialog}
                    CodeSelected={resultSelDialogData.CodeSelected}></ResultSelDialog>

                <MultiSelectList multiSelectTable={multiSelectData.multiSelectTable} selectedValues={multiSelectData.selectedValues}
                    showDialog={multiSelectData.showDialog} MultiSelectSaved={multiSelectData.MultiSelectSaved}></MultiSelectList>

            </Suspense>
        </>
    );
}

export default CustomResultEntry;