import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import NavBar from "../NavBar/NavBar";
import { useEffect, useRef, useState } from "react";
import { CComponents, CUserRightsAppl, ctDataType } from "../../types";
import axios from 'axios';
import UserSession from "../UserSession";
import { ItemRenderProps, TreeView, TreeViewExpandChangeEvent, TreeViewItemClickEvent } from "@progress/kendo-react-treeview";
import ProgressBar from "../Misc/ProgressBar";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { mui, removeSpecialCharFromCompTypeBarcode, removeSpecialCharactersFromBarcode } from "../../Helper";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";

type CCompLocationTree = {
    text: string;
    expanded: boolean;
    items: CCompLocationBar[];
    locationid: string;
    selected: Boolean;    
};
type CCompLocationBar = {
    text: string;
    expanded: boolean;
    items: CCompLocationBarBG[];
    compbar: string;
    sellocid: string;
    selected: Boolean;    
};
type CCompLocationBarBG = {
    text: string;
    expanded: boolean;
    items: CCompLocationBarRH[];
    sellocid: string;
    selcompbar: string;
    bg: string;
    selected: Boolean;    
};
type CCompLocationBarRH = {
    text: string;
    expanded: boolean; 
    sellocid: string;
    rh: string;
    selected: Boolean;    
};
type CCompDetailsOutput = {
    msg: string;
    objComp: CComponents;
};

interface CompMoveLocProps {
    loadfor: string;
    mandantChanged: number;
}
const ComponentMove = (props: CompMoveLocProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'componentmove' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [isLoading, setIsLoading] = useState(false);   
    const [isGridDataLoading, setIsGridDataLoading] = useState(false);  
    const [selLocation, setSelLocation] = useState<string | "">("");   
    const [compLocationTree, setCompLocationTree] = useState<CCompLocationTree[]>([]);
    const [compDetails, setCompDetails] = useState<CComponents[]>([]);
    const [compMoveGridData, setCompMoveGridData] = useState<CComponents[]>([]);

    const [horizontalPanes, setHorizontalPanes] = useState<Array<any>>([
        { size: "500px", min: "400px", collapsible: true, keepMounted: true },
        { min: "500px", collapsible: false, keepMounted: true }
    ]);
    const [verticalPanes, setVerticalPanes] = useState([{}, {}]);
    const onHorizontalChange = (event: SplitterOnChangeEvent) => {
        setHorizontalPanes(event.newState);
    };

    const refCompID = useRef<HTMLDivElement>(null);
    const refCompBar = useRef<HTMLDivElement>(null);
    const [compID, setCompID] = useState<string>("");
    const [compBar, setCompBar] = useState<string>("");

    const callbackMandantChanged = async () => {
    };

    const CustomItem = (props: ItemRenderProps) => {
        // console.log(props);
        return (
            <>
                {
                    props.item.hasOwnProperty('customerid') ? <span data-code={"C:" + props.item.customerid} key="0" className="k-icon k-font-icon k-i-folder" />
                        : <span />
                }
                {props.item.text}
            </>
        );
    };

    const onExpandChange = (event: TreeViewExpandChangeEvent) => {
        if (event.item.items[0].text === null) {
            if (event.item.hasOwnProperty('locationid')) {
                //setSelLocation(event.item.locationid);
                getCompBarDataForLocation(event.item.locationid);
            } else if (event.item.hasOwnProperty('compbar')) {
                getCompBarDataPerBG(event.item.sellocid, event.item.compbar);
            } else if (event.item.hasOwnProperty('bg')) {
                getCompBarDataPerRH(event.item.sellocid, event.item.selcompbar, event.item.bg);
            } else {
                return;
            };
        } else {
            //if (event.item.hasOwnProperty('locationid')) {
            //    setSelLocation(event.item.locationid);            
            //};
            if (event.item.expanded) {
                event.item.expanded = false;
            } else {
                event.item.expanded = true;
            };
        };
    };
   
    const getCompBarDataForLocation = async (locationid: string) => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'component/getcompbardataforlocation',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, complocation: locationid },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var newCompLocationTree = compLocationTree.slice();
            newCompLocationTree.map((item, index) => {
                if (item.locationid === locationid) {
                    item.items = response.data;
                    item.expanded = true;
                };
            });
            setCompLocationTree(newCompLocationTree);
        };
        
        setIsLoading(false);
    };

    const getCompBarDataPerBG = async (locationid: string, compbar: string) => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'component/getcompbardataperbg',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, complocation: locationid, compbar: compbar },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var newCompLocationTree = compLocationTree.slice();
            newCompLocationTree.map((item, index) => {
                if (item.locationid === locationid) {
                    var objTreeItem: CCompLocationBar;
                    for (objTreeItem of item.items) {
                        if (objTreeItem.compbar === compbar) {
                            objTreeItem.items = response.data
                            objTreeItem.expanded = true;
                            break;
                        };
                    };
                };
            });
            setCompLocationTree(newCompLocationTree);
        };
        
        setIsLoading(false);
    };

    const getCompBarDataPerRH = async (locationid: string, compbar: string, bg:string) => {
        setIsLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'component/getcompbardataperrh',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, complocation: locationid, compbar: compbar, bg:bg },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var newCompLocationTree = compLocationTree.slice();
            newCompLocationTree.map((item, index) => {
                if (item.locationid === locationid) {
                    var objTreeItem: CCompLocationBar;
                    for (objTreeItem of item.items) {
                        if (objTreeItem.compbar === compbar) {
                            var objSubTreeItem: CCompLocationBarBG;
                            for (objSubTreeItem of objTreeItem.items) {
                                if (objSubTreeItem.bg === bg) {
                                    objSubTreeItem.items = response.data
                                    objSubTreeItem.expanded = true;
                                    break;
                                };
                            };
                        };
                    };
                };
            });
            setCompLocationTree(newCompLocationTree);
        };    
        
        setIsLoading(false);
    };

    const treeItemClick = (event: TreeViewItemClickEvent) => {
        //if (event.item.hasOwnProperty('locationid')) {
        //    setSelLocation(event.item.locationid);
        //} else {
        //    setSelLocation(event.item.sellocid);            
        //};
        if (event.item.hasOwnProperty('rh')) setCompDetails(event.item.items);
    };
  
    const fillLocationTree = async () => {
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'component/getcomponentlocations',
                { mandantid: UserSession.getLoggedInUser().CurrentMandant },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            if (response?.data !== null) {                
                setCompLocationTree(response?.data);
            };            
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
    };

    const handleCompIDChanged = (e: any) => {
        setCompID(e.target.value);
    };

    const handleCompBarChanged = (e: any) => {
        setCompBar(e.target.value);
    };

    const onCompIDBlur = (e: any) => {
        let compID = e.target.value;
        if (compID === "") {
            setCompID("");
            return;
        };

        let compIDToSet;
        compIDToSet = removeSpecialCharactersFromBarcode(compID);
        if (compIDToSet !== undefined) {
            setCompID(compIDToSet);
            addToGrid();
        };
    };

    const onCompBarBlur = (e: any) => {
        let compBar = e.target.value;
        if (compBar === "") {
            setCompBar("");
            return;
        };

        let compBarToSet;
        compBarToSet = removeSpecialCharFromCompTypeBarcode(compBar)
        if (compBarToSet !== undefined) {
            setCompBar(compBarToSet);
            addToGrid();
        };
    };
    const actionCell = (props: GridCellProps) => {
        return (
            <td style={{ textAlign: 'center', padding: '1px' }} >
                <Button className="buttons-container-button" icon="trash"
                    onClick={() => {
                        deleteCompFromGrid(props.dataItem);
                    }}
                >
                </Button>
            </td>
        );
    };

    const deleteCompFromGrid = async (obj: CComponents) => {
        let binddata: CComponents[] = compMoveGridData.filter(comp => {
            return obj.sCompID !== comp.sCompID;

        });       
        setCompMoveGridData(binddata);
    };

    const addToGrid = async () => {
        if (compID === "" || compBar === "") return;
        if (compMoveGridData.length > 0) {
            var obj: CComponents;
            for (obj of compMoveGridData) {
                if (obj.sCompID === compID && obj.sCompBar === compBar) {
                    alert(mui(userRights, "componentmove", "msgCompAddedInGrid", "Comp already added in the grid."))
                    setCompID("");
                    setCompBar("");
                    refCompID.current?.focus();
                    return;
                };
            };   
        };
        setIsGridDataLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'component/getcompdetailsforcompmove',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, complocation: selLocation, compid: compID, compbar: compBar },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            var binddata: CCompDetailsOutput;
            binddata = response?.data
            if (binddata.msg !== "" && binddata.msg !== undefined) {
                alert(binddata.msg);
                setCompID("");
                setCompBar("");
                setTimeout(() => {
                    if (refCompID.current)
                        refCompID.current.focus();
                }, 100);     
            } else {
                var gridData = compMoveGridData.slice();
                gridData.push(binddata.objComp)
                setCompMoveGridData(gridData);
                setCompID("");
                setCompBar("");
                refCompID.current?.focus();
            };            
        };
        setIsGridDataLoading(false);
    };

    const MoveLocationClick = async () => {
        var str: string = "";
        if (selLocation === "") {
            alert(mui(userRights, "componentmove", "msgSelMoveLoc", "Please select move location."));
            return
        };
        setIsGridDataLoading(true);
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'component/movecomponentlocation',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, complocation:selLocation, listcompmove:compMoveGridData, userid: UserSession.getLoggedInUser().UserID},
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );       
        setIsGridDataLoading(false);
        if (response?.data !== null) {
            str = response?.data;
            if (str !== "") {
                alert(str);
            } else {
                Initialise();
            };
        };
    };

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {
        setSelLocation(objCT.sCode);        
    };

    const Initialise = () => {
        setSelLocation("");
        setCompID("");
        setCompBar("");
        setCompDetails([]);
        setCompMoveGridData([]);
        fillLocationTree();   
        setTimeout(() => {
            if (refCompID.current)
                refCompID.current.focus();
        }, 100);          
    };

    useEffect(() => {
        Initialise();      
    }, [props.mandantChanged])       

    const location: string = props.loadfor;

    var formHeight: number = location === "modal" ? 720 : window.innerHeight;
    if (location !== "invoke") formHeight -= 140;
    var gridHeight: number = formHeight;
    const formWidth: number = location === "modal" ? 1400 : window.innerWidth;
    var contentWidth: number = 0;
    if (location === "modal") {
        gridHeight -= 20; // as dialog
        contentWidth = formWidth - 20;      
    } else {
        formHeight -= 50;
        gridHeight -= 100; // as dashboard
        contentWidth = formWidth - 130;
    }

    return (
        <>
            <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight, maxHeight: formHeight, width: contentWidth, minWidth: contentWidth}}>                
                <Splitter
                    panes={horizontalPanes}
                    onChange={onHorizontalChange}
                    style={{ height: formHeight}}
                >
                    <div className="row" style={{ height: formHeight-20, width: '98%', border: '1px solid lightblue', marginTop: 10, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0 }}>
                        <Splitter style={{
                            height: formHeight-20
                        }} panes={verticalPanes} orientation={'vertical'}>
                            {isLoading ? <ProgressBar message=""></ProgressBar> :
                                <TreeView
                                    className="scrollable-treeview preserve-white-space"
                                    item={CustomItem}
                                    expandIcons={true}
                                    onExpandChange={onExpandChange}
                                    onItemClick={treeItemClick}
                                    data={compLocationTree}
                                />
                            }
                            <Grid
                                style={{
                                    height: '99%', maxHeight: '100%', width: '99%', maxWidth: '99%', overflowX: "hidden"
                                }}
                                data={compDetails}
                                sortable={false}
                            >
                                <GridColumn width='150px' field="sCompID" title={mui(userRights, "componentmove", "col_CompID", "CompID")} />
                                <GridColumn width='110px' field="sCompBar" title={mui(userRights, "componentmove", "col_CompBar", "CompBar")} />
                                <GridColumn field="sExpiryDate" title={mui(userRights, "componentmove", "col_ExpiryDate", "Expiry Date")} />
                                );
                            </Grid>
                        </Splitter>          
                    </div>                             
                    <div className="pane-content" style={{ height: formHeight -20, padding: "5px", border: '0px solid red', width: '98%', overflowX: 'hidden' }}>
                        <div className="container-fluid" style={{ overflowX: "hidden", height: formHeight-20}}>                            
                            <div className="row" style={{ width: '98%', border: '1px solid lightblue', marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0}}>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col-2">
                                        <Label style={{ fontWeight: 'bold' }}>{mui(userRights, "componentmove", "lblMoveLoc", "Move Location")}</Label>
                                    </div>
                                    <div className="col">
                                        <CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} mandantChanged={props.mandantChanged} id="moveLoc"
                                            codeTableID='642' selCode={selLocation} supplParam1="" supplParam2="" width="300px"></CodeTableSel>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col-2">
                                        <Label style={{ fontWeight: 'bold' }}>{mui(userRights, "componentmove", "lblCompID", "CompID")}</Label>
                                    </div>
                                    <div className="col-3" >
                                        <Input disabled={selLocation === "" ? true : false} style={{ width: 200 }} ref={refCompID} autoComplete="off" id="compid" name="compid" type='text' value={compID} onChange={handleCompIDChanged} onBlur={onCompIDBlur} />
                                    </div>
                                    <div className="col-2">
                                        <Label style={{ fontWeight: 'bold' }}>{mui(userRights, "componentmove", "lblCompBar", "CompBar")}</Label>
                                    </div>
                                    <div className="col-3" >
                                        <Input disabled={selLocation === "" ? true : false} style={{ width: 200 }} ref={refCompBar} autoComplete="off" id="compbar" name="compbar" type='text' value={compBar} onChange={handleCompBarChanged} onBlur={onCompBarBlur} />
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                            </div>
                            {/*<div className="row" style={{ alignContent: 'center'}}>*/}
                            {/*    <div className="col" style={{ textAlign: 'center' }}>*/}
                            {/*        <Label className='primary-color'><h2>{selLocation === "" ? mui(userRights, "componentmove", "titleSelectLoc", "Select Location") : mui(userRights, "componentmove", "titleSelectedLoc", "Selected Location") + ' - ' + selLocation}</h2></Label>*/}
                            {/*    </div>                                */}
                            {/*</div>*/}                                                       

                            <div className="row" style={{ width: '98%', border: '1px solid lightblue', marginTop: 5, marginBottom: 5, marginLeft: 1, marginRight: 1, borderRadius: 0 }}>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col">
                                        {isGridDataLoading ? <ProgressBar message=""></ProgressBar> : <Grid
                                            style={{
                                                height: formHeight - 264, maxHeight: formHeight - 260, width: '99%', maxWidth: '99%', overflowX: "hidden"
                                            }}
                                            data={compMoveGridData}
                                            sortable={false}                                            
                                        >
                                            <GridColumn field="" title={""} width="50px" cell={actionCell} />
                                            <GridColumn width='150px' field="sCompID" title={mui(userRights, "componentmove", "col_CompID", "CompID")} />
                                            <GridColumn width='110px' field="sCompBar" title={mui(userRights, "componentmove", "col_CompBar", "CompBar")} />
                                            <GridColumn width='170px' field="sExpiryDate" title={mui(userRights, "componentmove", "col_ExpiryDate", "Expiry Date")} />
                                            <GridColumn width='50px' field="sBloodGroup" title={mui(userRights, "componentmove", "col_BG", "BG")} />
                                            <GridColumn width='100px' field="sRhesus" title={mui(userRights, "componentmove", "col_RH", "RH")} />
                                            <GridColumn width='100px' field="sPhenoType" title={mui(userRights, "componentmove", "col_PT", "PT")} />
                                            <GridColumn width='50px' field="sKell" title={mui(userRights, "componentmove", "col_Kell", "Kell")} />
                                            <GridColumn width='150px' field="sCompLocationDesc" title={mui(userRights, "componentmove", "col_Location", "Location")} />
                                            );
                                        </Grid>}
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col" style={{ textAlign: "right" }}>
                                        <Button disabled={compMoveGridData.length === 0 ? true : false} type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                            MoveLocationClick()
                                        }}>{mui(userRights, "compmove", "cmdMoveLocation", "Move Location")}</Button>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                            </div>                            
                        </div>
                    </div>
                </Splitter>
            </div>
        </>
    );
}
export default ComponentMove;