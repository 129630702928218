import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox, CheckboxChangeEvent } from "@progress/kendo-react-inputs";
import { Suspense, useEffect, useState } from "react";
import { CConfirmationMsg, CRequest, CResultEntry, CResultEntryOutput, CVerifyValidationData, confirmationDialog, dlgEmptyInputBoxState, emptyRequest, emptyVVD, enmInputBoxType, inputBoxDialog, resultEntryDialog, selCodeDataType } from "../../types";
import UserSession from "../UserSession";
import SerologyResultAndValidation from "./SerologyResultAndValidation";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import CustomResultEntryIH from "../Misc/CustomResultEntryIH";
import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import AddInProfileParams from "./AddInProfileParams";
import { mui } from "../../Helper";
import CustomResultEntry from "../Misc/CustomResultEntry";
import { InputBoxDialog } from "../InputBoxDialog/InputBoxDialog";

interface IHProfileBlockProps {
    obj: CResultEntryOutput;
    callbackhandleGroupCheckbox(e: CheckboxChangeEvent, sProfileID: string, sResultType: string): any;
    callbackRefreshData(obj: CResultEntryOutput, action: string): any;
}

const IHProfileBlock = (props: IHProfileBlockProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const [reqData, setReqData] = useState<CRequest>(emptyRequest);
    const [newCode, setnewCode] = useState(false)

    const [inputBoxDialogData, setInputBoxDialogData] = useState<inputBoxDialog>(dlgEmptyInputBoxState);

    const dlgEmptyConfirmationDialogState: confirmationDialog = {
        obj: emptyVVD, showDialog: false, SelectedOptions: () => { }
    };
    const [confirmationDialogData, setConfirmationDialogData] = useState<confirmationDialog>(dlgEmptyConfirmationDialogState);

    const [visibleWindow, setVisibleWindow] = useState<boolean>(false);

    const toggleWindow = () => {

        setVisibleWindow(!visibleWindow);
    };

    const OkClick = async () => {

        setVisibleWindow(false);
        props.callbackRefreshData(props.obj, '1');
    }

    let paramLen: number;
    let paramwithcomm: number;
    let adminLen: number;
    let admincommLen: number;
    let maxCols: number;
    let cellWidth: number = 150;
    paramLen = props.obj.listResEntry.length;
    paramwithcomm = 1// props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;
    adminLen = 0
    admincommLen = 1// props.obj.listResEntry.filter(objRE => objRE.sResultComm !== "").length;    

    maxCols = paramLen > adminLen ? paramLen : adminLen;

    if (props.obj.sProfileResultType.toUpperCase() === 'BC') {
        maxCols *= 2
    }

    if (maxCols === 1) {
        maxCols += 1.75
    }

    const EnableResultEdit = () => {
        props.obj.inEdit = true
        props.obj.disableEdit = false
        if (props.obj.bDisableProfileSel === false && props.obj.bProfileSel === true) {
            console.log("EnableResultEdit if")
            const setText = async (inputText: string) => {
                console.log("EnableResultEdit if 1")
                if (inputText === "") {
                    console.log("EnableResultEdit no text cancel clicked")
                }
                else {                  
                    props.obj.sResultEditReason = inputText;
                    console.log("EnableResultEdit text ", inputText)
                }
                setInputBoxDialogData(dlgEmptyInputBoxState);
                props.callbackRefreshData(props.obj, '2');
                
            };
            console.log("EnableResultEdit if 2")
            setInputBoxDialogData({ promptText: "Enter Result Edit Reason", title: "Result Edit  Reason", defText: "", maxLength: 255, enm: enmInputBoxType.ResultEditReason, setText: setText, showDialog: true })
        }
        else {
            console.log("EnableResultEdit else")
            props.callbackRefreshData(props.obj, '2');
        }
       
    };

    const DisableResultEdit = () => {
        props.obj.inEdit = false
        props.obj.disableEdit = false
        props.obj.sResultEditReason = "";
        props.callbackRefreshData(props.obj, '1');
    };

    const SaveResult = () => {
        var obj: CResultEntry;
        var bok: boolean = true;
        for (obj of props.obj.listResEntry) {

            if (props.obj.inEdit === true) {
                //console.log("SaveResult", obj.sRawResult, obj.sResultText, obj.sResSelTableName, obj.bDisableResultEntry)
                if ((obj.sRawResult === "" && obj.bDisableResultEntry === false && obj.sResSelTableName !== "Antibodies") &&
                    (obj.sResultText === "" && obj.bDisableResultEntry === false && obj.sResSelTableName === "Antibodies")) {

                    alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!") + obj.sParamDesc);
                    bok = false;
                    return;
                };
            }

        };
        if (bok === true) {
            VerifyData("S");

        }

    };

    const VerifyData = async (sMode: string) => {
        var bok: boolean = false;
        var objVVD: CVerifyValidationData = emptyVVD;
        objVVD.sMandantID = UserSession.getLoggedInUser().CurrentMandant;
        objVVD.objREO = props.obj;
        objVVD.sMode = "S"

        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/verifydata',
            JSON.stringify({
                sMandantID: objVVD.sMandantID, objREO: objVVD.objREO, sMode: objVVD.sMode,
                bValidateIncompatibleComp: objVVD.bValidateIncompatibleComp, bAddBldGrpRhesusToPatient: objVVD.bAddBldGrpRhesusToPatient,
                bAddPhenoTypeToPatient: objVVD.bAddPhenoTypeToPatient, bSetCompFreeAndProceedWithValidation: objVVD.bSetCompFreeAndProceedWithValidation,
                bResultEnteredRDParam: objVVD.bResultEnteredRDParam, bResultEnteredBCParam: objVVD.bResultEnteredBCParam,
                bBloodComponentExists: objVVD.bBloodComponentExists, bDataChanged: objVVD.bDataChanged, nMsgAntigenDoesNotMatch: objVVD.nMsgAntigenDoesNotMatch,
                nMsgBldGrpRhsOfPatAndCompDoesNotMatch: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch, nMsgBldGrpRhsOfPatAndCompDoesNotMatch1: objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1,
                nMsgBldGrpOfResultAndComponentDoesNotMatch: objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch, nMsgRhesusOfResultAndPatientDoesNotMatch: objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch,
                nMsgRhesusOfResultAndComponentDoesNotMatch: objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch, nMsgAcceptBldGrpAndRhesus: objVVD.nMsgAcceptBldGrpAndRhesus,
                nMsgAcceptPhenoTypeYN: objVVD.nMsgAcceptPhenoTypeYN, nGetReactionReason: objVVD.nGetReactionReason,
                sReactionReason: objVVD.sReactionReason, sConfirmationMsg: objVVD.sConfirmationMsg
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        if (response?.data !== null) {
            bok = true;
            objVVD = response?.data;
            //console.log("objVVD", objVVD);
            if (objVVD.ConfirmationMsg.length > 0 || objVVD.nGetReactionReason === 1) {
                const SelectedOpts = (obj: CVerifyValidationData) => {
                    if (obj !== undefined) {
                        //console.log("1", obj);
                        var objCM: CConfirmationMsg;
                        for (objCM of obj.ConfirmationMsg) {
                            if (objCM.msgid === "MsgAntigenDoesNotMatch") {
                                obj.nMsgAntigenDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpRhsOfPatAndCompDoesNotMatch1") {
                                objVVD.nMsgBldGrpRhsOfPatAndCompDoesNotMatch1 = Number(objCM.action);
                            } else if (objCM.msgid === "MsgBldGrpOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgBldGrpOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndPatientDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndPatientDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgRhesusOfResultAndComponentDoesNotMatch") {
                                objVVD.nMsgRhesusOfResultAndComponentDoesNotMatch = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptBldGrpAndRhesus") {
                                objVVD.nMsgAcceptBldGrpAndRhesus = Number(objCM.action);
                            } else if (objCM.msgid === "MsgAcceptPhenoTypeYN") {
                                objVVD.nMsgAcceptPhenoTypeYN = Number(objCM.action);
                            };
                            if (objCM.action === "7") {
                                bok = false;
                            };
                        };
                    };
                    if (bok === true) {
                        //console.log("2 SaveClick");
                        /*//setVVD(obj);*/
                        if (sMode === "V") {
                            /*ValidateClick(obj);*/
                        } else {
                            if (objVVD.bCancelClicked === true) {
                                //console.log("2.1  cancel clicked");
                            }
                            else {
                                //console.log("2.2  ok clicked");
                                SaveClick(sMode, obj);
                            }
                            
                        };
                    };
                    setConfirmationDialogData(dlgEmptyConfirmationDialogState);
                };
                //console.log("3", objVVD);
                setConfirmationDialogData({
                    obj: objVVD, showDialog: true, SelectedOptions: SelectedOpts
                });
            } else {
                if (bok === true) {

                    if (sMode === "V") {
                        //ValidateClick(objVVD);
                    } else {
                        if (objVVD.bCancelClicked === true) {
                            //console.log("4  cancel clicked");
                        }
                        else {
                            //console.log("5  ok clicked");
                            SaveClick(sMode, objVVD);
                        }
                        
                    };
                };
            };
        };

    };

    const SaveClick = async (sMode: string, objVVD: CVerifyValidationData) => {

        const binddata: CResultEntry[] = objVVD.objREO.listResEntry //[...resultData];
        var obj: CResultEntry;

        for (obj of binddata) {
            if ((obj.sRawResult === "" && obj.bDisableResultEntry === false && obj.sResSelTableName !== "Antibodies") &&
                (obj.sResultText === "" && obj.bDisableResultEntry === false && obj.sResSelTableName === "Antibodies")) {
                //console.log("error", obj)
                alert(mui(userRights, "common", "msgNoBlankResult", "Please enter all results!"));
                return;
            };
        };
        var sAddnURL: string;
        if (sMode === 'S') {
            sAddnURL = 'serology/saverequestresultdata';
        } else {
            sAddnURL = 'serology/rerunparam';
        };
        setIsLoading(true);
        if (objVVD.objREO !== undefined) {
            //resultMainData.listResEntry = resultData;
            objVVD.objREO.sUserID = UserSession.getLoggedInUser().UserID;
        };
        const response = await axios.post(UserSession.getSystemSettings().API_URL + sAddnURL,
            JSON.stringify({
                objREO: objVVD.objREO, objVVD: objVVD
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        var maindata: CResultEntryOutput | undefined;
        if (response?.data !== null) {
            maindata = response?.data.objREO;
            //setVVD(response?.data.objVVD);
        }
        if (maindata !== undefined) {
            if (maindata.sErrorMsg !== "") {
                setIsLoading(false);
                alert(maindata.sErrorMsg);
            } else {
                //setDisableSaveButton(true);
                //setDisableValidationButton(true);
                binddata.length = 0;
                maindata.inEdit = false
                maindata.disableEdit = false
                maindata.listResEntry.forEach((o: CResultEntry) => {
                    //if (o.sParamStatus === "1" || o.sParamStatus === "2") setDisableSaveButton(false);
                    //if (o.sParamStatus === "3") setDisableValidationButton(false);
                    binddata.push({ ...o });
                });
                //setResultData(binddata);
                //setResultSaved(true);
                props.obj.inEdit = false
                props.obj.disableEdit = false
                setIsLoading(false);
                props.callbackRefreshData(props.obj, '1');

            };
        };

    };

    const GetReqData = async () => {
        const response = await axios.post(UserSession.getSystemSettings().API_URL + 'general/getResulyEntryDialogData',
            { mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: props.obj.listResEntry[0].sReqID },
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );

        if (response?.data !== null) setReqData(response?.data);
    };

    const handleShowCommentButtonClick = () => {

        //console.log("handleShowCommentButtonClick")

        if (props.obj.sIcon === 'comment-remove')
            props.obj.sIcon = 'comment';
        else
            props.obj.sIcon = "comment-remove";

        props.callbackRefreshData(props.obj, '3');

    };

    const handleShowPreValButtonClick = () => {

        //console.log("handleShowPreValButtonClick")

        if (props.obj.sIconPreVal === 'align-remove')
            props.obj.sIconPreVal = 'align-justify';
        else
            props.obj.sIconPreVal = "align-remove";

        props.callbackRefreshData(props.obj, '3');

    };

    useEffect(() => {
        setConfirmationDialogData(dlgEmptyConfirmationDialogState)
        GetReqData();
        //console.log("IHProfileBlock" , props.obj)
    }, []);

    return (
        <>
            <table className="table-sm" style={{ width: cellWidth * maxCols }}>
                <tbody>
                    <tr key={uuidv4()}>
                        <td colSpan={maxCols} title={props.obj.sProfileToolTip} style={{ fontWeight: 'bold', backgroundColor: props.obj.sProfileBackColor }}>
                            <div className="vertical-wrapper">
                                <div className="row">
                                    <div className="col-6">
                                        <Checkbox style={{ backgroundColor: props.obj.bProfileValidated === true ? 'green' : '' }} disabled={props.obj.bDisableProfileSel} onChange={(event: CheckboxChangeEvent) => props.callbackhandleGroupCheckbox(event, props.obj.sProfileID, props.obj.sProfileResultType)}
                                            value={props.obj.bProfileSel}
                                        >
                                            <label
                                                style={{ display: "inline-block", textWrap: 'nowrap' }}
                                            >
                                                {props.obj.sProfileDesc}
                                            </label>
                                        </Checkbox>
                                    </div>
                                    <div className="col-6 button-right">

                                        <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                            onClick={() => { handleShowCommentButtonClick(); }} icon={props.obj.sIcon}></Button>
                                        <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                            onClick={() => { handleShowPreValButtonClick(); }} icon={props.obj.sIconPreVal}></Button>
                                        {props.obj.bProfileValidated === false ?
                                            (
                                                props.obj.inEdit === true ?
                                                    <>
                                                        <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                            onClick={() => { DisableResultEdit(); }} icon="reset"></Button>
                                                        <Button type={"button"} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                            onClick={() => { SaveResult(); }} icon="floppy"></Button>

                                                    </>
                                                    : <>
                                                        {props.obj.collParamUnderProfile.length > 0 ?

                                                            <Button type={"button"} disabled={props.obj.disableEdit} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                                onClick={toggleWindow} icon="plus"></Button>
                                                            : ""
                                                        }
                                                        <Button type={"button"} disabled={props.obj.disableEdit} style={{ height: '20px', width: '20px', float: 'right', marginRight: "5px" }} themeColor={'primary'}
                                                            onClick={() => { EnableResultEdit(); }} icon="pencil"></Button>
                                                    </>

                                            ) : ""
                                        }
                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>
  
                    <tr key={uuidv4()}>
                        {
                            props.obj.listResEntry !== null && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                            ))
                        }
                    </tr>

                    {
                        props.obj.listResEntry !== null && paramwithcomm > 0 && props.obj.listResEntry.length > 0 ?
                            <>
                                {props.obj.sIconPreVal === 'align-remove' ?
                                    <tr key={uuidv4()}>
                                        {props.obj.listResEntry !== null && paramwithcomm > 0 && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                            <td title={objRE.sPreVal3Tooltip !== "" ? objRE.sPreVal3Tooltip : ""} key={uuidv4()} style={{ width: cellWidth }}>
                                                {objRE.sPreVal3 === "" ? <div>&nbsp;</div> : objRE.sPreVal3}
                                            </td>
                                        ))}
                                    </tr>
                                    : ''
                                }

                            </>
                            :
                            ""
                    }

                    <tr key={uuidv4()} >
                        {
                            (props.obj.inEdit === true && newCode === true) ?
                                <CustomResultEntryIH selType="RESULT" obj={props.obj}></CustomResultEntryIH>
                                :

                            props.obj.listResEntry !== null && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                props.obj.inEdit === true ?
                                    <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        <CustomResultEntry selType="RESULT" objRE={objRE} bDisableResultEntry={props.obj.bDisableProfileSel === false && props.obj.bProfileSel === true && props.obj.sResultEditReason === "" ? true:false } ></CustomResultEntry>
                                    </td>
                                    :
                                    <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                        {objRE.sInterpretationDesc === "" ? <div>&nbsp;</div> : objRE.sInterpretationDesc}
                                    </td>

                            ))
                        }

                    </tr>

                    {
                        props.obj.listResEntry !== null && paramwithcomm > 0 && props.obj.listResEntry.length > 0 ?
                            <>
                                {props.obj.sIcon === 'comment-remove' ?
                                    <tr key={uuidv4()}>
                                        {
                                            (props.obj.inEdit === true && newCode === true) ?
                                                <CustomResultEntryIH selType="RESULT" obj={props.obj}></CustomResultEntryIH>
                                                :


                                            props.obj.listResEntry !== null && paramwithcomm > 0 && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                            props.obj.inEdit === true ?

                                                <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                                        <CustomResultEntry selType="RESULTCOMM" objRE={objRE} bDisableResultEntry={false } ></CustomResultEntry>
                                                </td>
                                                :
                                                <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                                    {objRE.sResultComm === "" ? <div>&nbsp;</div> : objRE.sResultComm}
                                                </td>
                                        ))}
                                    </tr>
                                    : ''
                                }

                            </>
                            :
                            ""
                    }


                    {adminLen > 0 &&
                        <>
                            <tr style={{ lineHeight: '1px' }} key={uuidv4()}>
                                <td colSpan={maxCols}>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr key={uuidv4()}>
                                {
                                    props.obj.listResEntry !== null && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                        <td key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>{objRE.sParamDesc}</td>
                                    ))
                                }
                            </tr>
                            <tr key={uuidv4()}>
                                {
                                    props.obj.listResEntry !== null && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                        <td title={objRE.sInterpretationDesc !== "" ? objRE.sInterpretationDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                            {objRE.sInterpretationDesc === "" ? <div>&nbsp;</div> : objRE.sInterpretationDesc}
                                        </td>
                                    ))
                                }
                            </tr>
                            <tr key={uuidv4()}>
                                {
                                    props.obj.listResEntry !== null && admincommLen > 0 && props.obj.listResEntry.map((objRE: CResultEntry, index) => (
                                        <td title={objRE.sResultCommDesc !== "" ? objRE.sResultCommDesc : ""} key={uuidv4()} style={{ backgroundColor: objRE.sBackColor, width: cellWidth }}>
                                            {objRE.sResultComm === "" ? <div>&nbsp;</div> : objRE.sResultComm}
                                        </td>
                                    ))
                                }
                            </tr>
                        </>
                    }
                </tbody>
            </table>
            <Suspense fallback={<Loader />}>

                <ConfirmationDialog obj={confirmationDialogData.obj} showDialog={confirmationDialogData.showDialog} SelectedOptions={confirmationDialogData.SelectedOptions}></ConfirmationDialog>
                <AddInProfileParams obj={props.obj} showDialog={visibleWindow} callbackCancel={toggleWindow} selprofilecode={props.obj.sProfileID}
                    callbackSave={OkClick}></AddInProfileParams>
                <InputBoxDialog promptText={inputBoxDialogData.promptText} title={inputBoxDialogData.title} defText={inputBoxDialogData.defText} maxLength={inputBoxDialogData.maxLength} enm={inputBoxDialogData.enm} setText={inputBoxDialogData.setText} showDialog={inputBoxDialogData.showDialog} ></InputBoxDialog>
            </Suspense>

        </>
    );
}

export default IHProfileBlock;