import { useEffect, useState } from "react";
import { Dialog, } from "@progress/kendo-react-dialogs";
import { dashboardDialog } from "../../types";
import { DialogTitleBar, mui } from "../../Helper";
import { Button } from "@progress/kendo-react-buttons";
import MatInLab from "./MatInLab";
import CobraMonitor from "../CobraMonitor/CobraMonitor";
import ResultCriteria from "./ResultCriteria";
import ValCriteria from "./ValCriteria";
import WorkList from "./WorkList";
import ReservedComponents from "./ReservedComponents";
import AdditionalRequestParam from "./AdditionalRequestParam";
import MatInLabList from "./MatInLabList";
import ComponentOrderList from "./ComponentOrderList";
import DayWorkspace from "./DayWorkspace";
import ComponentMove from "./ComponentMove";

const DashboardDialog = (props: dashboardDialog) => {
    const [showDlg, setShowDlg] = useState<boolean>(false);

    const cancelDialog = () => {
        setShowDlg(false);
    };

    useEffect(() => {
        setShowDlg(props.data.showDialog);
    }, [props.data])

    const [mandantChanged, setMandantChanged] = useState(1);

    var dlgTitle: string = props.data.dashboarditem === 1 ? mui(userRights, "navbar", "mnuCobraMonitor", "Cobra Monitor") :
        props.data.dashboarditem === 2 ? mui(userRights, "navbar", "mnuResultCriteria", "Result Criteria") :
            props.data.dashboarditem === 3 ? mui(userRights, "navbar", "mnuTechVal", "Technical Validation") :
                props.data.dashboarditem === 4 ? mui(userRights, "navbar", "mnuWorklist", "Worklist") :
                    props.data.dashboarditem === 5 ? mui(userRights, "navbar", "mnuResrvComps", "Reserved Components") :
                        props.data.dashboarditem === 6 ? mui(userRights, "navbar", "mnuMatInLab", "Mat In Lab") :
                            props.data.dashboarditem === 7 ? mui(userRights, "navbar", "mnuAddnReqParam", "Additional Request Param") :
                                props.data.dashboarditem === 8 ? mui(userRights, "navbar", "mnuMatInLabList", "Mat In Lab List") :
                                    props.data.dashboarditem === 9 ? mui(userRights, "navbar", "mnuComponentOrderList", "Component Order List") :
                                        props.data.dashboarditem === 10 ? mui(userRights, "navbar", "mnuComponentMoveLocation", "Component Move Location") :
                                            mui(userRights, "navbar", "mnuDayWorkspace", "Day Workspace");
                                            
                                                


    return (
        <>
            <div>
                {showDlg && (
                    <Dialog contentStyle={{ overflow: 'hidden' }} width={1400} height={720}
                        title={<DialogTitleBar title={dlgTitle}></DialogTitleBar>}
                        onClose={() => { }} closeIcon={false}>
                            <>
                                <div className="row">
                                    <div className="col">
                                        {
                                            props.data.dashboarditem === 1 ? <CobraMonitor loadfor='modal' mandantChanged={mandantChanged}></CobraMonitor> :
                                                props.data.dashboarditem === 2 ? <ResultCriteria loadfor='modal' mandantChanged={mandantChanged}></ResultCriteria> :
                                                    props.data.dashboarditem === 3 ? <ValCriteria loadfor='modal' mandantChanged={mandantChanged}></ValCriteria> :
                                                        props.data.dashboarditem === 4 ? <WorkList loadfor='modal' mandantChanged={mandantChanged}></WorkList> :
                                                            props.data.dashboarditem === 5 ? <ReservedComponents loadfor='modal' mandantChanged={mandantChanged}></ReservedComponents> :
                                                            props.data.dashboarditem === 6 ? <MatInLab loadfor='modal' mandantChanged={mandantChanged}></MatInLab> :
                                                                    props.data.dashboarditem === 7 ? <AdditionalRequestParam loadfor='modal' mandantChanged={mandantChanged}></AdditionalRequestParam> :
                                                                        props.data.dashboarditem === 8 ? <MatInLabList loadfor='modal' mandantChanged={mandantChanged}></MatInLabList> :
                                                                        props.data.dashboarditem === 9 ? <ComponentOrderList loadfor='modal' mandantChanged={mandantChanged}></ComponentOrderList> :
                                                                            props.data.dashboarditem === 10 ? <ComponentMove loadfor='modal' mandantChanged={mandantChanged}></ComponentMove> :
                                                                                <DayWorkspace loadfor='modal' mandantChanged={mandantChanged}></DayWorkspace>   
                                        }
                                    </div>
                                </div>
                                
                            </>
                            <div className="row">
                                <div className="col">
                                    <div className="button-right">
                                        <Button style={{ width: '120px', marginTop: 20, marginRight: 20 }} themeColor={'secondary'} onClick={cancelDialog}>Close</Button>
                                    </div>
                                </div>
                            </div>
                    </Dialog>
                )}
            </div>
        </>
    );
}

export default DashboardDialog;