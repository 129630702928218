import React, { useEffect, useState } from 'react'
import axios from 'axios'
import UserSession from '../UserSession';
import { CResultEntry, CResultEntryOutput, CUserRightsAppl, ctDataType, emptyREData, emptyREO } from '../../types';
import { AutoComplete, AutoCompleteBlurEvent, AutoCompleteChangeEvent, AutoCompleteCloseEvent, ComboBox, ComboBoxChangeEvent, ComboBoxFilterChangeEvent, MultiColumnComboBox } from '@progress/kendo-react-dropdowns';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { mui } from '../../Helper';

interface CodeTableSelProps {
    codeTableID: string;
    selCode: string;
    mandantChanged: number;
    setSelectedCT(ctid: string, controlID: string, objCT: ctDataType, supplParam1: string, supplParam2: string): any;
    width: string;
    id: string;
    supplParam1: string;
    supplParam2: string;
    disabled?: boolean;
    autocomplete: boolean;
    objRE?: CResultEntry;
}

export const CodeTableSel = (props: CodeTableSelProps) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'serologyResultAndValidation' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const columns = [
        { field: "sCode", header: "Code" },
        { field: "sDescDevelopment", header: "Description" },
    ];


    // Default empty selected CT state
    var ctData: ctDataType = {
        sCodeTableID: "",
        sMandantID: "",
        sCode: "",
        sAbbrDevelopment: "",
        sDescDevelopment: "",
        sMemoDevelopment: "",
        sLanguage: "",
        sSuppl1: "",
        sSuppl2: "",
        sSuppl3: "",
        sUserGroup: "",
        DisplayString: "",
        selected: false
    };

    const [selAuto, setSelAuto] = useState<string>("");

    const [selected, setSelected] = useState<ctDataType>(ctData);
    // Array of CT data for the specified CT
    const [CodeTableData, setCodeTableData] = useState<ctDataType[]>([]);

    async function GetCodeTableData() {

        const GETREQUESTINFOBAR_URL = UserSession.getSystemSettings().API_URL + 'codetablesel/getcodetabledata';

        const response = await axios.post(GETREQUESTINFOBAR_URL,
            JSON.stringify({ mandantid: UserSession.getLoggedInUser().CurrentMandant, "codeTableID": props.codeTableID }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                    'Content-Type': 'application/json'
                },
                withCredentials: false
            }
        );
        var binddata: ctDataType[] = [];
        binddata.push({ DisplayString: "", sCodeTableID: "", sMandantID: "", sCode: "", sAbbrDevelopment: "", sDescDevelopment: "", sMemoDevelopment: "", sLanguage: "", sSuppl1: "", sSuppl2: "", sSuppl3: "", sUserGroup: "", selected: false });
        if (response?.data !== null)
            response?.data.forEach((o: ctDataType) => {
                binddata.push({
                    DisplayString: o.sCode + ' : ' + o.sDescDevelopment, sCodeTableID: o.sCodeTableID,
                    sMandantID: o.sMandantID, sCode: o.sCode, sAbbrDevelopment: o.sAbbrDevelopment,
                    sDescDevelopment: o.sDescDevelopment === "" ? o.sAbbrDevelopment : o.sDescDevelopment,
                    sMemoDevelopment: o.sMemoDevelopment, sLanguage: o.sLanguage, sSuppl1: o.sSuppl1, sSuppl2: o.sSuppl2, sSuppl3: o.sSuppl3, sUserGroup: o.sUserGroup, selected: o.selected
                });
            });
        setCodeTableData(binddata);
        binddata.forEach((o: ctDataType) => {
            if (o.sCode === props.selCode) {
                setSelected(o)
                setSelAuto(o.sCode)
            }
        });
    }

    useEffect(() => {
        // console.log('CodeTableSel UE: props.mandantChanged');
        GetCodeTableData();
    }, [props.mandantChanged])

    useEffect(() => {
        if (CodeTableData === null || CodeTableData === undefined) return;
        let selCode: ctDataType | undefined = CodeTableData.find((item: ctDataType) => item.sCode === props.selCode)
        if (selCode !== undefined) {
            setSelected(selCode);
            setSelAuto(selCode.sCode)
        }
    }, [props.selCode])

    const onBlurAuto = async (event: AutoCompleteBlurEvent) => {

        //if (selected.sCode !== event.target.value) {
            var resultMainData: CResultEntryOutput = emptyREO;

            var obj: CResultEntry = emptyREData;

            if (props.objRE !== undefined) {
                obj = props.objRE
                obj.sRawResult = event.target.value;
                if (obj.ExistingResult.length > 0) {
                    obj.sRecStatus = "CHG"
                }
                else {
                    obj.sRecStatus = "UNS"
                }

            }

            //console.log("onBlurAuto CT - ", event, obj)

            if (event.target.value === '') {
                setSelected(selected);
                setSelAuto(selected.sCode)

            }
            else {
                const response = await axios.post(UserSession.getSystemSettings().API_URL + 'serology/validateresult',
                    JSON.stringify({
                        objREO: resultMainData, objResultEntry: obj, collRatioValLevel: resultMainData?.collRatioValLevel, validatequalresult: false
                    }),
                    {
                        headers: {
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: false
                    }
                );
                if (response?.data !== null) {
                    //console.log("onBlurAuto CT response - ", response?.data)
                    var objRO = response?.data

                    var Msg: string = objRO.objREO.sErrorMsg;
                    if (Msg === undefined) Msg = '';
                    if (Msg === '') Msg = mui(userRights, "common", "msgInvalidCode", "Invalid Code!");
                    
                    if (objRO.objResultEntry.bInvalidCode === true) {
                        alert(Msg);

                        setSelected(ctData);
                        setSelAuto('')
                    }
                    else {

                        let selCode: ctDataType | undefined = CodeTableData.find((item: ctDataType) => item.sCode === objRO.objResultEntry.sRawResult)
                        if (selCode !== undefined) {
                            //console.log("enter")
                            props.setSelectedCT(selCode.sCodeTableID, props.id, selCode, selCode.sSuppl1, selCode.sSuppl2);
                            setSelected(selCode);
                            setSelAuto(selCode.sCode)
                        }

                    }

                };      
            }


        //}

    };

    const onChangeAuto = (event: AutoCompleteChangeEvent) => {
        if (event.value === null) {
            return;
        }
        
        setSelAuto(event.value);

        let selCode: ctDataType | undefined = CodeTableData.find((item: ctDataType) => item.DisplayString === event.value.toString())
        if (selCode !== undefined) {
            //console.log("enter")
            props.setSelectedCT(selCode.sCodeTableID, props.id, selCode, selCode.sSuppl1, selCode.sSuppl2);
            setSelected(selCode);
            setSelAuto(selCode.sCode)
        }

        //console.log("onChangeAuto CT - ", event, selCode, selected, selAuto )
    };

    const onChange = (event: ComboBoxChangeEvent) => {
        if (event.value === null) {
            return;
        }
        props.setSelectedCT(props.codeTableID, props.id, event.value, props.supplParam1, props.supplParam2);
        setSelected(event.value);
    };

    const [filter, setFilter] = React.useState<FilterDescriptor>();

    const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
        if (event) {
            setFilter(event.filter);
        }
    };

    return (
        <>

            {
                props.autocomplete === true ?
                    <AutoComplete
                        style={{ width: '100%' }}
                        data={filter ? filterBy(CodeTableData, filter) : CodeTableData}
                        textField="DisplayString"
                        dataItemKey="sCode"
                        defaultValue={props.selCode}
                        value={selAuto}
                        onChange={onChangeAuto}
                        id={"autocomp"}
                        onBlur={onBlurAuto}
                    />
                :
                    <MultiColumnComboBox
                        columns={columns}
                        id={"combobox"}
                        style={{ width: props.width }}
                        data={filter ? filterBy(CodeTableData, filter) : CodeTableData}
                        textField="DisplayString"
                        dataItemKey="sCode"
                        value={selected}
                        onChange={onChange}
                        clearButton={false}
                        suggest={true}
                        filterable={true}
                        onFilterChange={handleFilterChange}
                        disabled={props.disabled}
                    />


            }
       
        </>
    )

}