import { Dialog } from "@progress/kendo-react-dialogs";
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import React from "react";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../../Helper";
import ProgressBar from "../../Misc/ProgressBar";
import { Grid, GridColumn, GridSelectionChangeEvent, getSelectedState, GridCellProps, GridHeaderCellProps } from "@progress/kendo-react-grid";
import { CSeroRackListData, emptySeroRackListData, CSeroRackGridCell, CSeroRackDetails, CSeroRackGridCellInfo } from "../../../types";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import UserSession from "../../UserSession";
import axios from 'axios';
import { Label } from "@progress/kendo-react-labels";
import { v4 as uuidv4 } from 'uuid';
import { List } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

const initialSort: Array<SortDescriptor> = [  

];

interface SeroRackListProps {
    reqid: string;
    rackid: string;
    callbackDialogClosed(): any;
    showDialog: boolean;
}

const SeroRackList = (props: SeroRackListProps) => {
    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [isLoading, setIsLoading] = useState(false);

    const [selectedState, setSelectedState] = useState<{
        [id: string]: boolean | number[];
    }>({});

    const [sort, setSort] = useState(initialSort);
    const [seroRackListData, setSeroRackListData] = useState<CSeroRackListData[]>([]); 

    const emptySeroRackCellInfo: CSeroRackGridCellInfo = {
        sRackID: "",
        sRackName: "",
        sRackLocation: "",
        sRackLocationName: "",
        sSeroParamLocation: "",
        sSeroParamLocationName: "",
        sReqID: "",
        sReqDate: "",
        sReqTime: "",
        sName: "",
        sFirstName: "",
        sBirthdate: "",
        sGender: "",
        sGroupID: "",
        sGroupName: "",
        sMaterial: "",
        sExtDate: "",
        sExtTime: "",
    }

    const emptySeroRackDetailsData: CSeroRackDetails = {
        SeroRackDetailData: [], selCellInfo: emptySeroRackCellInfo
    }
    const [seroRackDetailsData, setSeroRackDetailsData] = useState<CSeroRackDetails>(emptySeroRackDetailsData); 
    
    const [seroRackCellInfo, setSeroRackCellInfo] = useState<CSeroRackGridCellInfo>(emptySeroRackCellInfo); 
    //const [seroRackDetailsData, setSeroRackDetailsData] = useState<CSeroRackGridCell[]>([]); 
    
    const idGetter = getter('sGroup');

    const [horizontalPanes, setHorizontalPanes] = React.useState<Array<any>>([
        { size: "450px", min: "450px", collapsible: true, keepMounted: true },
        { min: "450px", collapsible: false, keepMounted: true },
        { collapsible: false, keepMounted: true }
    ]);

    const onHorizontalChange = (event: SplitterOnChangeEvent) => {
        setHorizontalPanes(event.newState);
    };
    
    const CancelClick = () => {
        props.callbackDialogClosed();
        setVisible(false);
    };

    const GetSeroRackDetailsData = async (obj: CSeroRackListData) => {
        //setIsLoading(true);
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'SerothekPatients/getSeroRackDetailsData',
                JSON.stringify({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: obj.sReqID, rackid: obj.sRackID, materialid: obj.sMaterialID,
                    archivegroup: obj.sGroup, formstate:0
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            if (response?.data !== null) {         
                var binddata: CSeroRackDetails;
                binddata = response?.data;
                setSeroRackDetailsData(binddata);
                if (binddata.selCellInfo !== undefined) {
                    if (binddata.selCellInfo.sRackID!==null) setSeroRackCellInfo(binddata.selCellInfo)
                };
            };
            //setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        //setIsLoading(false);
    };

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        var obj: CSeroRackListData = event.dataItems[0];      

        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: 'sGroup',
        });        
        //for (const property in newSelectedState) {
        //    if (newSelectedState[property]) {
        //        sGroup = property.toString();
        //    };
        //};
        setIsLoading(true);
        setSelectedState(newSelectedState);
        GetSeroRackDetailsData(obj);
        setIsLoading(false);
    };

    const getSeroRackListData = async () => {
        var binddata: CSeroRackListData[] = [];
        setIsLoading(true);
        try {
            const response = await axios.post(UserSession.getSystemSettings().API_URL + 'SerothekPatients/getSeroRackListData',
                JSON.stringify({
                    mandantid: UserSession.getLoggedInUser().CurrentMandant, reqid: props.reqid, rackid: props.rackid
                }),
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: false
                }
            );
            if (response?.data !== null) {
                binddata = response?.data;
                if (binddata.length > 0) {
                    let sel: any = {};
                    sel[binddata[0].sGroup] = true;
                    setSelectedState(sel);   
                    GetSeroRackDetailsData(binddata[0]);
                }
                setSeroRackListData(binddata);
            };
            setIsLoading(false);
            return;
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        }
        setIsLoading(false);
    };

    const CustomHeaderCell = (props: GridHeaderCellProps) => {
        return (
            <div className="div-hidden"></div>
        )
    };

    const seroRackDetailsCustomCell = (props: GridCellProps) => {
        const value = props.dataItem['sValue'];
        const header = props.dataItem['bHeader'];
        var backcolor = props.dataItem['sBackColor'];
        var forecolor = props.dataItem['sForColor'];
        return (
            <div style={{ textAlign: 'center' }}>
                {header === true && (<button style={{ height: 15, width: 15, color: forecolor, backgroundColor: backcolor }} className="round k-icon k-font-icon">{value}</button>)}
                {header !== true && (<Label style={{ height: 15, width: 15, color: forecolor, backgroundColor: backcolor }} className="round k-icon k-font-icon">{value}</Label>)}
            </div>
        );
    };

    const refreshDetails = (obj: CSeroRackGridCell) => {
        try {
            setSeroRackCellInfo(obj.objInfo);
        } catch (err: any) {
            console.log(err);
            if (!err?.response) {
            } else if (err.response?.status === 401) {
            } else {
            }
        };        
    };
   
    useEffect(() => {
        getSeroRackListData();
        setSeroRackDetailsData(emptySeroRackDetailsData);
        setSelectedState({});
        setSeroRackCellInfo(emptySeroRackCellInfo);
        setVisible(props.showDialog);
    }, [props.showDialog])    

    var rootHeight = window.innerHeight - 100;
    var rootWidth = window.innerWidth - 100;
    let maxCols: number = 8;
    let cellWidth: number = 50;

    return (
        <>
            {visible && (
                <Dialog style={{ overflowX: 'hidden' }} title={<DialogTitleBar title={mui(userRights, "SeroRackList", "textTitle", "Serothek Rack List")} />} onClose={CancelClick} height={rootHeight} width={rootWidth} >
                    <div style={{ paddingTop:5, paddingLeft: 5, width: rootWidth }} className="row">
                    <div className="col">
                        <Splitter                        
                            panes={horizontalPanes}
                            onChange={onHorizontalChange}
                        >
                            <div className="pane-content" style={{
                                    height: rootHeight-84, overflowX: 'hidden', padding: "5px",
                                    border: '0px solid red'
                                }}>
                                    {isLoading ? <ProgressBar message=""></ProgressBar> :
                                        <Grid
                                            data={orderBy(seroRackListData, sort).map((item) => ({
                                                ...item,
                                                selected: selectedState[idGetter(item)],
                                            }))}
                                            style={{
                                                height: rootHeight - 84, width: 440
                                            }}
                                            selectable={{
                                                enabled: true,
                                                drag: false,
                                                cell: false,
                                                mode: "single",
                                            }}
                                            onSelectionChange={onSelectionChange}
                                            selectedField={'selected'}
                                            dataItemKey={'sGroup'}
                                        >
                                            <GridColumn field="sGroup" title={mui(userRights, "SeroRackList", "col_Group", "Group")} width="150px" />
                                            <GridColumn field="sRackID" title={mui(userRights, "SeroRackList", "col_RackID", "RackID")} width="150px" />                                            
                                        </Grid>
                                    }
                            </div>
                            <div className="pane-content" style={{
                                    height: rootHeight - 84, overflowX: 'hidden', padding: "5px",
                                    border: '0px solid red'
                                }}>
                                    <div className="row" style={{ border: '1px solid lightblue', backgroundColor: "#e0f0ff", borderRadius: 0 }}>
                                        <div className="row">
                                            <div className="col-2">
                                                <Label style={{ fontWeight:'bold'}}>Rack ID:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sRackID}</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Rack Name:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sRackName}</Label>
                                            </div>
                                        </div>               
                                        <div className="row">
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Rack Location:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sRackLocation + ' ' + seroRackCellInfo.sRackLocationName}</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Sero Param Location:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sSeroParamLocation + ' ' + seroRackCellInfo.sSeroParamLocationName}</Label>
                                            </div>
                                        </div>                                                       
                                        <div className="row">
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>ReqID:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sReqID}</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>ReqDate:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sReqDate + ' ' + seroRackCellInfo.sReqTime}</Label>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Name:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sName}</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>FirstName:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sFirstName}</Label>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Birthdate:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sBirthdate}</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Gender:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sGender}</Label>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>GroupID:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sGroupID}</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>GroupName:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sGroupName}</Label>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Material:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sMaterial}</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label style={{ fontWeight: 'bold' }}>Extraction Date:</Label>
                                            </div>
                                            <div className="col-2">
                                                <Label>{seroRackCellInfo.sExtDate + ' ' + seroRackCellInfo.sExtTime}</Label>
                                            </div>
                                        </div> 
                                    </div>
                                    <div className="col-12">&nbsp;</div>
                                    {seroRackDetailsData?.SeroRackDetailData.length > 0 && < table className="table-sm table-bordered" style={{ borderColor:'blue', width: cellWidth * maxCols }}>
                                        {
                                            seroRackDetailsData.SeroRackDetailData !== null && seroRackDetailsData.SeroRackDetailData.map((objlst: any, index) => (
                                                <tr key={uuidv4()}>
                                                    {
                                                        objlst.map((obj: CSeroRackGridCell) => (
                                                            obj.bHeader === true ? < td style={{ borderColor: 'blue', textAlign: 'center', color: obj.sForeColor, backgroundColor: obj.sBackColor, width: cellWidth }}>{obj.sValue}</td> :
                                                                < td title={obj.sComment} style={{ borderColor: 'blue', backgroundColor: obj.sBackColor !== "" ? obj.sBackColor : "", textAlign: 'center', width: cellWidth }}>
                                                                    <Button style={{ height: 15, width: 15, backgroundColor: obj.sForeColor !== "" ? obj.sForeColor : "orange" }} className="round k-icon k-font-icon" onClick={() => refreshDetails(obj)}></Button></td>
                                                        ))   
                                                    }                                                                                                    
                                                </tr>
                                            ))
                                        }                                                                                                            
                                    </table>}
                            </div>
                        </Splitter>
                    </div>
                </div>
            </Dialog>
            )}
        </>
    )
}
export default SeroRackList;







