import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { TextArea } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { DialogTitleBar, mui } from "../../Helper";
import { ctDataType, CUserRightsAppl, enmInputBoxType, inputBoxDialog } from "../../types";
import { CodeTableSel } from "../CodeTableSel/CodeTableSel";
import UserSession from "../UserSession";

export const InputBoxDialog = (props: inputBoxDialog) => {
    var userRights: CUserRightsAppl[] | null = globalThis.userRights !== null ?
        globalThis.userRights.filter(ura => {
            return 'InputBoxDialog' === ura.sFormName || 'common' === ura.sFormName;
        }) : null;

    const [visible, setVisible] = useState<boolean>(props.showDialog);
    const [inputText, setInputText] = useState<string | "">("");
    const [CodeTableData, setCodeTableData] = useState<ctDataType | null>(null);
    const [selectedCode, setSelectedCode] = useState("");

    const callbackCTSelected = async (ctid: string, controlID: string, objCT: ctDataType) => {
        setSelectedCode(objCT.sCode);
        setCodeTableData(objCT);
    };

    const inputTextChange = (e: any) => {       
        setInputText(e.target.value);
    };
    const addReason = async () => {
        var str: string = inputText;       
        if (CodeTableData !== null) {
            if (str !== '') {
                str = str + ', ' + CodeTableData?.sDescDevelopment;
            } else {
                str = CodeTableData?.sDescDevelopment;
            };          
            setInputText(str);
            setSelectedCode('');
            setCodeTableData(null);
        };
    };
    const OkClick = async () => {        
        if (inputText === "") {
            alert('Please enter text!');
        } else {
            props.setText(inputText);
            setVisible(false);
        };
    };

    const CancelClick = async () => {
        props.setText("");
        setVisible(false);
    };

    useEffect(() => {
        setInputText(props.defText);
        setSelectedCode('');
        setCodeTableData(null);
        setVisible(props.showDialog);
        //console.log("inputbox ", props.showDialog, visible, props.title)
    }, [props.showDialog]) 

    var textAreaHT: string = props.enm === enmInputBoxType.RejectionReason ? '240px' : '280px'

    return (
        <>
            <div>
                {visible && (
                    <Dialog title={<DialogTitleBar title={props.title} />} onClose={CancelClick} height={450} width={560} style={{ overflow: 'hidden' }} >
                        <div className="container-fluid" style={{ overflowX: 'hidden', width: 520, maxWidth: 520 }}>
                            <div className="row" style={{
                                border: '1px solid lightblue'
                            }}>
                                <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <div style={{ fontWeight: 'bold'}}><Label>{props.promptText}</Label></div>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row">
                                    <div className="col">
                                        {props.enm === enmInputBoxType.RejectionReason && (<div><Label style={{ verticalAlign: 'middle' }}>Reason</Label></div>)}
                                    </div>
                                    <div className="col">
                                        {props.enm === enmInputBoxType.RejectionReason && (<CodeTableSel autocomplete={false} setSelectedCT={callbackCTSelected} mandantChanged={Number(UserSession.getLoggedInUser().CurrentMandant)} id="reason"
                                            codeTableID='624' selCode={selectedCode} supplParam1="" supplParam2="" width="300px"></CodeTableSel>)}
                                    </div>   
                                    <div className="col">
                                        {props.enm === enmInputBoxType.RejectionReason && (<div><Button type={"button"} style={{ width: '100px' }} themeColor={'primary'} onClick={() => { addReason() }}>Add</Button></div>)}
                                    </div>
                                </div>
                                {props.enm === enmInputBoxType.RejectionReason && (<div className="row">&nbsp;</div>)}
                                <div className="row">
                                    <div className="col">
                                        <div>
                                            <TextArea autoComplete="off" id="it" name={"inputText"} style={{ height: textAreaHT, width: '100%' }}
                                                onChange={inputTextChange} value={inputText} maxLength={props.maxLength} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">&nbsp;</div>
                                <div className="row pb10">
                                    <div className="col">
                                        <div className="button-right">
                                            <Button type={"button"} style={{ width: '150px' }} themeColor={'primary'} onClick={() => {
                                                OkClick()
                                            }}>{mui(userRights, "common", "cmdOk", "Ok")}</Button>&nbsp;
                                            <Button type={"button"} style={{ width: '150px' }} themeColor={'secondary'} onClick={() => {
                                                CancelClick()
                                            }}>{mui(userRights, "common", "cmdCancel", "Cancel")}</Button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </Dialog>
                )}
            </div>
        </>
    )
}